import questionnaireApiService from '@/modules/questionnaire/services/questionnaire.api'

const modal = (context)  => {
  return new Promise((resolve, reject) => {
    questionnaireApiService.modal()
    .then( response => {
    	/* response has a questionnaire */
      if (response.data.id) {
        context.commit('SET_CURRENT_QUESTIONNAIRE', response.data)
      }
      resolve(response)
    }).catch( error => {
      reject(error.response)
    })
  })
}

const retrieve = (context, questionnaireId)  => {
  return new Promise((resolve, reject) => {
    questionnaireApiService.get(questionnaireId)
    .then( response => {
      /* response has a questionnaire */
      if (response.data.id) {
        context.commit('SET_CURRENT_QUESTIONNAIRE', response.data)
      }
      resolve(response)
    }).catch( error => {
      reject('The questionnaire not longer available.')
      context.commit('SET_CURRENT_QUESTIONNAIRE', null)
    })
  })
}

const answer = (context, options) => {
  return new Promise((resolve, reject) => {
    questionnaireApiService.answer(options.questionnaireId, options.questionId, options.data)
      .then( response => {
        if (response.data.id) {
          context.commit('SET_CURRENT_QUESTIONNAIRE', response.data)
        } else {
          context.commit('SET_CURRENT_QUESTIONNAIRE', null)
        }
        context.commit('RESET_CURRENT_QUESTION_ANSWER')
        resolve(response)
      }).catch( error => {
        reject(error.response)
      })
  })
}

const skip = (context, options) => {
  return new Promise((resolve, reject) => {
    questionnaireApiService.skip(options.questionnaireId, options.questionId, options.data)
      .then( response => {
        if (response.data.id) {
          context.commit('SET_CURRENT_QUESTIONNAIRE', response.data)
        } else {
          context.commit('SET_CURRENT_QUESTIONNAIRE', null)
        }
        context.commit('RESET_CURRENT_QUESTION_ANSWER')
        resolve(response)
      }).catch( error => {
        reject(error.response)
      })
  })
}

export default {
	modal,
  retrieve,
  answer,
  skip,
}