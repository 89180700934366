import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  currentQuestionnaire: null,
  currentQuestionAnswer: {
    answer_text: '',
    answer_option_ids: [],
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
