const SET_JWT = (state, jwt) => {
  state.currentJWT = jwt
  state.user.token = jwt
}

const SET_USER = (state, user) => {
  
  state.user = user

  if (user) {

    state.role = state.user.role.slug

    if (state.role != 'keyworker') {
      
      state.activeNursery = state.user.nurseries.data.length ? state.user.nurseries.data[0] : null
      state.activeNurseryBranch = state.activeNursery && state.activeNursery.branches.data.length ? state.activeNursery.branches.data[0] : null

      // localStorage.setItem('activeNurseryId', state.activeNursery.id)
      // localStorage.setItem('activeBranchId', state.activeNurseryBranch.id)
    } else {
      state.activeNursery = null
      state.activeNurseryBranch = null
    }
  }
}

const SET_AUTH_IS_READY = (state, authIsReady) => {
  state.authIsReady = authIsReady
}

const SET_USER_HAS_SUSPENDED_NURSERY = (state, status) => {
  state.userHasSuspendedNursery = status
}


const SET_ACTIVE_NURSERY = (state, nursery) => {
  state.activeNursery = nursery
  
  if (nursery) {
    localStorage.setItem('activeNurseryId', nursery.id)
  } else {
    localStorage.removeItem('activeNurseryId')
  }
}

const SET_ACTIVE_NURSERY_BRANCH = (state, nurseryBranch) => {
  state.activeNurseryBranch = nurseryBranch
  
  if(nurseryBranch) {
    localStorage.setItem('activeBranchId', nurseryBranch.id);
  } else {
    localStorage.removeItem('activeBranchId');
  }
}

const SET_OPENING_TIMES = (state, openingTimes) => {
  state.openingTimes = openingTimes
}

const SET_ROLE = (state , role) => {
  state.role = role
}

const SET_CHILD_NUMBER = (state, number) => {
  state.childNumber = number
}

const SET_JUMP = (state, jump) => {
  state.jumpStepBack = jump
}

const SET_USER_HAS_COMPLETED_MIGRATION = (state, status) => {
  state.userHasCompletedMigration = status
}

const SET_NOTIFICATIONS = (state, notification) => {
  state.checkNotifications = notification
}

const SET_ENABLE_MARKETPLACE = (state, status) => {
  state.userHasEnabledMarketplace = status
}

const SET_UPGRADED = (state, status) => {
  state.upgraded = status
}

const SET_USER_HAS_UPDATED_CHILDREN = (state, status) => {
  state.userHasUpdatedChildren = status
}

const SET_KEYWORKER_CURRENT_CHILD_AND_INDEX = (state, {child, index}) => {
  state.currentKeyworkerChild = child
  state.currentKeyworkerChildId = child.id
  state.currentKeyworkerChildIndex = index
}

const SET_REDIRECT_FROM_SIGNUP = (state, redirect) => {
  state.redirectFromSignup = redirect
}

const ADD_USER_PAYMENT_METHOD = (state, paymentMethod) => {
  state.user.keyworkerProfile.payment_methods.data.push(paymentMethod)
}

export default {
  SET_JWT,
  SET_USER,
  SET_AUTH_IS_READY,
  SET_ACTIVE_NURSERY,
  SET_ACTIVE_NURSERY_BRANCH,
  SET_OPENING_TIMES,
  SET_ROLE,
  SET_CHILD_NUMBER,
  SET_JUMP,
  SET_USER_HAS_COMPLETED_MIGRATION,
  SET_NOTIFICATIONS,
  SET_ENABLE_MARKETPLACE,
  SET_UPGRADED,
  SET_USER_HAS_SUSPENDED_NURSERY,
  SET_USER_HAS_UPDATED_CHILDREN,
  SET_KEYWORKER_CURRENT_CHILD_AND_INDEX,
  SET_REDIRECT_FROM_SIGNUP,
  ADD_USER_PAYMENT_METHOD,
}
