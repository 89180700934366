<template>
  <div>
    <li class="settings-link d-flex align-items-center py-3">
      <router-link :to="{ name: 'staff-settings-profile-employee-details', params: { profileId: activeNursery.staffProfile.id }}">
        Employment Details
      </router-link>
    </li>      
    <li class="settings-link d-flex align-items-center py-3">
      <router-link :to="{ name: 'staff-settings-profile-qualifications', params: { profileId: activeNursery.staffProfile.id }}">
        Qualifications/Skills
      </router-link>
    </li>
    <li class="settings-link d-flex align-items-center py-3">
      <router-link :to="{ name: 'staff-settings-profile-holidays', params: { profileId: activeNursery.staffProfile.id }}">
        Holidays/Absences
      </router-link>
    </li>
  </div>
</template>

<script>
import SharedLinks from '@/modules/settings/components/user-links/SharedLinks'
import { AcademicCapIcon, BriefcaseIcon, SunIcon } from '@vue-hero-icons/outline'
import { mapGetters } from 'vuex'

export default {
  name: 'NurseryStaff',

  components: {
    SharedLinks,
    AcademicCapIcon,
    BriefcaseIcon,
    SunIcon
  },

  computed: {
    ...mapGetters('auth', ['activeNursery'])
  }
}
</script>
