<template>
	<b-modal
	  id="errorModal"
	  header-class="hidden"
    body-class="p-3 text-center"
    content-class="p-4"
    footer-class="hidden"
	  @hidden="$emit('close')"
	>
  	
  	<XCircleIcon />
  	
  	<h3 class="mt-3 mb-3">{{ title }}</h3>

  	<p v-if="message" class="mb-3">{{ message }}</p>

  	<b-button class="w-50" variant="primary" @click="$bvModal.hide('errorModal')">{{ buttonText }}</b-button>

	</b-modal>
</template>
<script>
import { XCircleIcon } from '@vue-hero-icons/outline'

const DEFAULT_DATA = {
	title: 'Error',
	message: '',
	buttonText: 'Close',
}

export default {
	name: 'ErrorModal',

	data() {
		return DEFAULT_DATA
	},

	components: {
		XCircleIcon,
	},

	methods: {

		handleEvent(data) {
			this.title = data.title ? data.title : DEFAULT_DATA.title
			this.message = data.message ? data.message : DEFAULT_DATA.message
			this.buttonText = data.buttonText ? data.buttonText : DEFAULT_DATA.buttonText

			this.$bvModal.show('errorModal')
		}
	},

	created() {
		this.$bus.on('app::modal::error', this.handleEvent)
	},
}
</script>
<style lang="scss" scoped>
	svg {
		width: 51px;
    height: 51px;
    color: $error;
	}
</style>