import http from '@/core/services/drivers/app-http-driver'

export default {
	basket: {
		fetchInstance(serviceId, sessionId, instanceId, params = {}) {
      return http({ url: `/nursery/marketplace/nurseryBranch/${serviceId}/sessions/${sessionId}/instances/${instanceId}`, method: 'get', params: params })
    },
    checkout(data = {}) {
    	return http({ url: '/booking/order/create', method: 'post', data: data })
    },
    checkoutSummary(data = {}) {
      return http({ url: '/booking/order/summary', method: 'post', data: data })
    },
    add(data = {}) {
      return http({ url: '/booking/basket/add', method: 'post', data: data })
    },
    remove(basketItemID) {
      return http({ url: `/booking/basket/${basketItemID}/remove`, method: 'delete' })
    },
    addChildToBasketItem(basketItemID, data = {}) {
      return http({ url: `/booking/basket/${basketItemID}/add-child`, method: 'post', data: data })
    },
    addPaymentToBasketItem(basketItemID, data = {}) {
      return http({ url: `/booking/basket/${basketItemID}/add-payment`, method: 'post', data: data })
    },
    getWaitingListInfo(data = {}) {
      return http({ url: 'booking/waiting-list/info', method: 'post', data: data })
    },
    submitWaitingList(data = {}) {
      return http({ url: 'booking/waiting-list', method: 'post', data: data })
    },
    removeWaitingList(waitingListItemId, data = {}) {
      return http({ url: `booking/waiting-list/${waitingListItemId}`, method: 'delete', data: data })
    }
	}
}