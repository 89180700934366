import lazyLoading from '@/core/router/lazy-loading'
import GlobalLayout from '@/core/layouts/global-layout/GlobalLayout'

export const messagesRoutes = [
  {
    path: '/messages',
    component: GlobalLayout,
    redirect: '/messages',
    children: [
      {
        name: 'messages',
        path: '',
        component: lazyLoading('messages', 'Messages'),
        meta: {
          title: 'Messages'
        },
      },
    ]
  }
]
