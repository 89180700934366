import childrenApiService from '@/modules/children/services/children.api'

export default {
  loadChild({ commit }, { child_id, nursery_branch_id }) {
    return new Promise((resolve, reject) => {
      childrenApiService.child.show(child_id, { 
        nursery_branch_id: nursery_branch_id, 
        includes: 'primary_carer,carers.children' 
      })
      .then(({ data }) => {
        commit('SET_SELECTED_CHILD', data)
        resolve()
      })
      .catch( error => {
        reject(error)
      })
    })
  },
}
