import lazyLoading from '@/core/router/lazy-loading'
import GlobalLayout from '@/core/layouts/global-layout/GlobalLayout'

export const teamRoutes = [
  {
    path: '/team',
    component: GlobalLayout,
    children: [
      {
        path: '',
        name: 'team',
        component: lazyLoading('team', 'layouts/TeamLayout'),
        redirect: { name: 'team-day' },
        children: [
          {
            name: 'team-day',
            path: 'day',
            component: lazyLoading('team', 'team/Day'),
            meta: {
              title: 'Daily Rota'
            },
          },
          {
            name: 'team-month',
            path: 'month',
            component: lazyLoading('team', 'team/Month'),
            meta: {
              title: 'Monthly Rota'
            },
          },
          {
            name: 'team-week',
            path: 'week',
            component: lazyLoading('team', 'team/Week'),
            meta: {
              title: 'Weekly Rota'
            },
          },
          {
            name: 'team-schedule',
            path: 'schedule',
            component: lazyLoading('team', 'team/Schedule'),
            meta: {
              title: 'My Schedule'
            }
          },
        ],
      },
      {
        path: 'rotas',
        name: 'templates',
        component: lazyLoading('team', 'layouts/RotaLayout'),
        redirect: { name: 'templates-list' },
        children: [
          {
            name: 'rotas-preview',
            path: ':rota_id/preview',
            component: lazyLoading('team', 'rota/Rota'),
          },
          {
            name: 'templates-list',
            path: 'templates',
            component: lazyLoading('team', 'templates/Templates'),
            meta: {
              title: 'Templates',
            }
          },
          {
            name: 'templates-preview',
            path: 'templates/:template_id/preview',
            component: lazyLoading('team', 'templates/Preview'),
          },
          {
            name: 'templates-create',
            path: 'templates-create/:template_id',
            component: lazyLoading('team', 'templates/Create'),
            meta: {
              title: 'Templates',
            },
          },
          {
            name: 'templates-import',
            path: 'templates-import/:template_id/:start_date/:end_date',
            component: lazyLoading('team', 'templates/Import'),
            meta: {
              title: 'Import Template',
            },
          },
          {
            name: 'rotas-edit',
            path: ':rota_id/edit',
            component: lazyLoading('team', 'rota/RotaEdit'),
          },
        ],
      }
    ]
  }
]