import lazyLoading from '@/core/router/lazy-loading'
import GlobalLayout from '@/core/layouts/global-layout/GlobalLayout'

export const notificationsRoutes = [
  {
    path: '/notifications',
    component: GlobalLayout,
    redirect: '/notifications',
    children: [
      {
        name: 'notifications',
        path: '',
        component: lazyLoading('notifications', 'Notifications'),
        meta: {
          title: 'Notifications'
        },
      },
    ]
  }
]
