import Vue from 'vue'
import Router from 'vue-router'
import guards from '@/core/router/guards'
import { authRoutes } from '@/modules/auth/routes/auth-routes'
import { childrenRoutes } from '@/modules/children/routes/children-routes'
import { messagesRoutes } from '@/modules/messages/routes/messages-routes'
import { notificationsRoutes } from '@/modules/notifications/routes/notifications-routes'
import { resultsRoutes } from '@/modules/results/routes/results-routes'
import { settingsRoutes } from '@/modules/settings/routes/settings-routes'
import { bookingsRoutes } from '@/modules/bookings/routes/bookings-routes'
import { basketRoutes } from '@/modules/basket/routes/basket-routes'
import { staffRoutes } from '@/modules/staff/routes/staff-routes'
import { teamRoutes } from '@/modules/team/routes/team-routes'
import { dashboardRoutes } from '@/modules/dashboard/routes/dashboard-routes'
import { performanceRoutes } from '@/modules/performance/routes/performance-routes'

const router = new Router({
  mode: 'history',
  
  routes: [
    ...authRoutes,
    ...messagesRoutes,
    ...notificationsRoutes,
    ...resultsRoutes,
    ...settingsRoutes,
    ...bookingsRoutes,
    ...basketRoutes,
    ...childrenRoutes,
    ...staffRoutes,
    ...teamRoutes,
    ...dashboardRoutes,
    ...performanceRoutes,
  ]
})

router.beforeEach(guards.serviceOnboarding)
router.beforeEach(guards.titleSetter)
router.beforeEach(guards.handleNotLoggedInUsers)
router.beforeEach(guards.handleLoggedInUsers)

Vue.router = router
Vue.use(Router)

export default router
