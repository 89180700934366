import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  user: null,
  currentJWT: '',
  authIsReady: false,
  activeNursery: null,
  activeNurseryBranch: null,
  role: null,
  openingTimes: null,
  childNumber: null,
  jumpStepBack: false,
  userHasCompletedMigration: false,
  checkNotifications: false,
  updateChildren: false,
  userHasEnabledMarketplace: false,
  upgraded: false,
  userHasSuspendedNursery: false,
  userHasUpdatedChildren: false,
  currentKeyworkerChild: null,
  currentKeyworkerChildId: false,
  currentKeyworkerChildIndex: 0,
  redirectFromSignup: null
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
