<template>
  <SharedNavigation>
    <b-nav-item
      :to="{ name: 'dashboard' }"
      class="d-none d-lg-flex"
    >
      <span>Dashboard</span>
    </b-nav-item>
    
    <b-nav-item
      :to="{ name: 'planning' }"
      class="d-none d-lg-flex"
      :active="isBookingsRoute()"
    >
      <span>Planning</span>
    </b-nav-item>

    <b-nav-item-dropdown
      class="d-none d-md-flex"
      text="Profiles"
    >
      <b-dropdown-item
        :to="{ name: 'children' }"
        class=""
      >
        Children
      </b-dropdown-item>

      <b-dropdown-item
        :to="{ name: 'children-reports' }"
        class=""
      >
        Reports
      </b-dropdown-item>

      <b-dropdown-item
        :to="{ name: 'children-requests' }"
        class=""
      >
        Requests
      </b-dropdown-item>

      <b-dropdown-item
        :to="{ name: 'children-carers' }"
        class=""
      >
        Carers
      </b-dropdown-item>
    </b-nav-item-dropdown>

    <b-nav-item-dropdown
      class="d-none d-md-flex"
      text="Performance"
    >
      <b-dropdown-item
        :to="{ name: 'service-insights-customers' }"
        class=""
      >
        Insights
      </b-dropdown-item>

      <b-dropdown-item
        :to="{ name: 'service-performance-service' }"
        class=""
      >
        Service Performance
      </b-dropdown-item>

      <b-dropdown-item
        :to="{ name: 'development-diary' }"
        class=""
      >
        Development Diary
      </b-dropdown-item>
    </b-nav-item-dropdown>
  
    <b-nav-item-dropdown
      class="d-none d-md-flex"
      text="Team"
    >
      <b-dropdown-item
        :to="{ name: 'staff-list' }"
        class=""
      >
        Staff
      </b-dropdown-item>

      <b-dropdown-item
        :to="{ name: 'team' }"
        class=""
      >
        Rotas
      </b-dropdown-item>

      <b-dropdown-item
        :to="{ name: 'templates' }"
        class=""
      >
        Rota Templates
      </b-dropdown-item>
    </b-nav-item-dropdown>


    <div />
  </SharedNavigation>
</template>
<script>
import SharedNavigation from '@/core/layouts/global-layout/components/user-navigation/SharedNavigation'
import { isBookingsRoute } from '@/core/router/utils'

export default {
  components: {
    SharedNavigation
  },

  methods: {
    isBookingsRoute() {
      return isBookingsRoute(this.$route.name)
    },
  }
}

</script>