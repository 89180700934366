<template>
	<b-form no-validate>
		<validated-b-form-group
		  name="service_types" 
		  label="Services provided" 
		  class="app-input select-multiple" 
		  :server-errors="serverErrors.service_types"
		>
		  <b-form-checkbox-group
		    v-model="form.service_types"
		    :options="serviceTypes"
		    value-field="id"
		    text-field="name"
		    name="service_types"
		    stacked
		    :disabled="formLock"
		  />
		</validated-b-form-group>

		<validated-b-form-group 
		  name="activity_categories" 
		  label="Which of these activity categories do you provide?" 
		  class="app-input select-multiple" 
		  :server-errors="serverErrors.activity_categories"
		>
		  <b-form-checkbox-group
		    v-model="form.activity_categories"
		    :options="activityCategories"
		    value-field="id"
		    text-field="name"
		    name="activity_categories"
		    stacked
		    :disabled="formLock"
		  />
		</validated-b-form-group>

		<validated-b-form-group 
		  name="meals" 
		  label="Do you offer any meals?" 
		  class="app-input select-multiple" 
		  :server-errors="serverErrors.meals"
		>
		  <b-form-checkbox-group
		    v-model="form.meals"
		    :options="meals"
		    value-field="id"
		    text-field="name"
		    name="meals"
		    stacked
		    :disabled="formLock"
		  />
		</validated-b-form-group>

		<validated-b-form-group 
		  name="provisions" 
		  label="Do you offer any of the following provisions?" 
		  class="app-input select-multiple" 
		  :server-errors="serverErrors.provisions"
		>
		  <b-form-checkbox-group
		    v-model="form.provisions"
		    :options="provisions"
		    value-field="id"
		    text-field="name"
		    name="provisions"
		    stacked
		    :disabled="formLock"
		  />
		</validated-b-form-group>

		<validated-b-form-group 
		  label="Provision offered" 
		  class="app-input" 
			v-if="form.provisions.length"
		  :server-errors="serverErrors.provision_term"
		>
		  <b-form-radio-group 
				v-model="form.provision_term" 
				:options="provisionTerm" 
				text-field="name"
				value-field="id"
				stacked
				name="provision_term"
			/>
		</validated-b-form-group>

		<validated-b-form-group 
		  label="Does your Service specialise in providing activities or care for children with Additional Support Needs (ASNs)?" 
		  class="app-input" 
		>
		  <b-form-radio v-model="form.provides_additional_support" :value="1" :disabled="formLock">Yes</b-form-radio>
		  <b-form-radio v-model="form.provides_additional_support" :value="0" :disabled="formLock">No</b-form-radio>
		</validated-b-form-group>

		<validated-b-form-group 
		  label="Please enter any further information about how your Service can help children with specific needs. This will appear in your Service profile for Parents to see." 
		  class="app-input" 
		  :server-errors="serverErrors.asn_info"
		>
			<div class="d-flex flex-column flex-gap-3">
				<span>For example, is your location wheelchair accessible? Or do your staff have specialised skills that can help a child, for example, with a certain disability or where English is not their first language?</span>
				<b-form-textarea
					v-model="form.asn_info" 
					:disabled="formLock"
					placeholder="Type here"
					rows="4"
				/>
			</div>
		</validated-b-form-group>
	</b-form>
</template>

<script>
import { mapGetters } from 'vuex'
import FieldMixin from '@/core/mixins/field-mixin.js'

export default {
  name: 'ServiceFormStepTwo',

  mixins: [FieldMixin],

  computed: {
    ...mapGetters('core', [
      'serviceTypes',
      'activityCategories',
      'meals',
      'provisions',
			'provisionTerm',
      'supportOptions',
    ]),

    ...mapGetters('auth', [
      'isServiceOnboarding',
    ]),
  },
}
</script>