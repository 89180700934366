const SET_CURRENT_QUESTIONNAIRE = (state, questionnaire = null) => {
	state.currentQuestionnaire = questionnaire
}

const RESET_CURRENT_QUESTION_ANSWER = state => {
	state.currentQuestionAnswer = {
    answer_text: '',
    answer_option_ids: [],
  }
}

export default {
	SET_CURRENT_QUESTIONNAIRE,
	RESET_CURRENT_QUESTION_ANSWER,
}