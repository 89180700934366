export default {
	methods: {
		validateFile(file) {
			if (file.size > 10 * 1024 * 1024) {
			  this.contactSupport( 'File size cannot exceed 10MB.' )
			  return false
			}

			const validImageTypes = ['image/gif', 'image/jpg', 'image/png', 'image/jpeg']

			if ( !validImageTypes.includes(file.type) ) {
			  this.contactSupport( 'Only these file types are allowed: jpg, gif, png.' )
			  return false
			}

			return true
		}
	},
}