import store from '@/core/store'

const notSecureRoutes = [
  null,
  'home',
  'search-results',
  'home-registered-provider-details',
  'home-unregistered-provider-details',
  'home-view-marketplace-session',
  'login',
  'confirm-email',
  'complete-account',
  'register',
  'download-app',
  'forgot-password',
  'reset-password',
  'terms',
  'privacy',
  'cookie',
  'legal',
  'basket-checkout'
]

const secureRoutes = [
  'checkout',
  'checkout-complete',
  'checkout-waiting-list',
  'checkout-waiting-list-complete',
  'service-onboarding-step-one',
  'service-onboarding-step-two',
  'user-profile',
  'keyworker-child-profile',
  'keyworker-child-services',
  'keyworker-child-development-diary',
  'keyworker-connections',
  'keyworker-settings',
  'keyworker-invoices',
  'keyworker-payment-methods',
  'keyworker-bookings',
  'keyworker-calendar',
  'keyworker-transactions',
  'keyworker-waiting-list',
  'messages',
  'notifications',
  'accounts-children-feed',
  'accounts-children-next-steps',
  'children-journal',
  'journal-step-1',
  'journal-step-2',
  'journal-step-3',
  'journal-step-4',
  'journal-step-5',
  'learning-journal-insights',
  'learning-journal-workers-insights',
  'learning-journal-parents-insights',
  'learning-journal-children-insights',
  'account-settings',
  'service-provider-information',
  'service-details',
  'service-payment-and-cancellation',
  'service-opening-hours',
  'service-photo-gallery',
  'service-subscriptions',
  'service-monthly-usage-statements',
  'service-payment-collection-details',
  'service-reviews',
  'bookings',
  'service-sessions',
  'service-sessions-archive',
  'service-bookings',
  'service-orders',
  'service-locations',
  'service-calendar',
  'view-service-session',
  'edit-service-session',
  'duplicate-service-session',
  'create-service-session',
  'view-service-session-calendar',
  'view-service-session-bookings',
  'view-service-session-register',
  'view-service-session-waiting-list',
  'staff-list',
  'staff-hours',
  'staff-holidays',
  'staff-absences',
  'staff-invite',
  'staff-profile',
  'staff-profile-employee-details',
  'staff-profile-personal-information',
  'staff-profile-work-schedule',
  'staff-profile-holidays',
  'staff-profile-qualifications',
  'staff-profile-branches',
  'service-session-new-booking',
  'children',
  'children-active',
  'children-reports',
  'children-requests',
  'children-waiting-list',
  'children-carers',

  'children-details',
  'children-overview',
  'children-pickups',
  'children-funding-status',
  'children-booking-calendar',
  'children-development-diary',
  'children-diary-feed',
  'children-next-steps',
  'children-journal',
  'journal-step-1',
  'journal-step-2',
  'journal-step-3',
  'journal-step-4',
  'journal-step-5',

  'team',
  'team-day',
  'team-week',
  'team-month',
  'team-schedule',

  'templates',
  'templates-list',
  'templates-preview',
  'templates-create',
  'templates-import',

  'rotas-edit',
  'rotas-preview',

  'staff-profile',
  'personal-info',
  'holidays-absences',
  'qualifications-skills',

  'children-create',
  'children-step-1',
  'children-step-2',
  'children-step-3',
  'dashboard',

  'performance',

  'development-diary',
  'development-diary-workers',
  'development-diary-parents',
  'development-diary-children',

  'service-performance',
  'service-performance-service',
  'service-insights-customers',
]

const nurseryAdminNotSecureRoutes = [
  'home',
  'search-results',
  'home-registered-provider-details',
  'home-unregistered-provider-details',
  'home-view-marketplace-session',
  'reset-password',
]

const allowedRoutes = {
  'nursery-admin': [
    ...secureRoutes,
    ...nurseryAdminNotSecureRoutes,
  ],
  'nursery-super-admin': [
    ...secureRoutes,
    ...nurseryAdminNotSecureRoutes,
  ],
  'nursery-staff': [
    'dashboard',
    'messages',
    'notifications',
    'reset-password',
    'user-profile',
    'team-schedule',
    'children-reports',
    'account-settings',
    'rotas-preview',
    'staff-settings-profile-qualifications',
    'staff-settings-profile-employee-details',
    'staff-settings-profile-employee-details',
    'staff-settings-profile-holidays',
    'staff-availability',
    'children',
    'children-active',
    'children-reports',
    'children-requests',
    'children-waiting-list',
    'children-carers',
  
    'children-details',
    'children-overview',
    'children-pickups',
    'children-funding-status',
    'children-booking-calendar',
    'children-development-diary',
    'children-diary-feed',
    'children-next-steps',
    'children-journal',
    'children-create',
    'children-step-1',
    'children-step-2',
    'children-step-3',
    'journal-step-1',
    'journal-step-2',
    'journal-step-3',
    'journal-step-4',
    'journal-step-5',

    'planning',
    'service-locations',
    'bookings',
    'service-sessions',
    'service-bookings',
    'service-orders',
    'view-service-session',
    'view-service-session-calendar',
    'view-service-session-bookings',
    'view-service-session-register',
    'view-service-session-waiting-list',
    'service-session-new-booking'
  ],
  'keyworker': [
    'checkout',
    'checkout-complete',
    'complete-profile',
    'confirm-children',
    'checkout-waiting-list',
    'checkout-waiting-list-complete',
    'home',
    'search-results',
    'home-registered-provider-details',
    'home-unregistered-provider-details',
    'home-view-marketplace-session',
    'reset-password',
    'user-profile',
    'account-settings',
    'keyworker-child-profile',
    'keyworker-child-services',
    'keyworker-child-development-diary',
    'keyworker-connections',
    'keyworker-settings',
    'keyworker-invoices',
    'keyworker-payment-methods',
    'bookings',
    'keyworker-bookings',
    'keyworker-calendar',
    'keyworker-transactions',
    'keyworker-waiting-list',
    'notifications',
    'legal',
    'terms',
  ],
  'carer': [
    'download-app',
  ],
}

const profileRoutes = [
  'user-profile',
  'account-settings',
  'keyworker-child-profile',
  'keyworker-child-services',
  'keyworker-child-development-diary',
  'keyworker-connections',
  'keyworker-settings',
  'keyworker-invoices',
  'keyworker-payment-methods',
  'service-provider-information',
  'service-details',
  'service-payment-and-cancellation',
  'service-opening-hours',
  'service-photo-gallery',
  'service-subscriptions',
  'service-monthly-usage-statements',
  'service-payment-collection-details',
  'service-reviews',
]

const bookingsRoutes = [
  'bookings',
  'service-sessions',
  'service-orders',
  'service-bookings',
  'service-locations',
  'service-calendar',
  'view-service-session',
  'keyworker-bookings',
  'keyworker-calendar',
  'keyworker-transactions',
  'edit-service-session',
  'duplicate-service-session',
  'create-service-session',
  'view-service-session-calendar',
  'view-service-session-bookings',
  'view-service-session-register',
  'view-service-session-waiting-list',
  'service-session-new-booking',
]

export const isNotSecureRoute = (route) => {
  return notSecureRoutes.includes(route)
}

export const isSecureRoute = (route) => {
  return secureRoutes.includes(route)
}

export const isProfileRoute = (route) => {
  return profileRoutes.includes(route)
}

export const isBookingsRoute = (route) => {
  return bookingsRoutes.includes(route)
}

export const allows = (route) => {
  const user = store.getters['auth/user']
  const role = store.getters['auth/role']
  return user && allowedRoutes[role].includes(route)
}
