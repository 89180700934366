<template>
  <img
    v-if="show"
    :src="avatar"
    @error="onError($event)"
    :alt="alt"
    class="rounded-circle"
    :style="`width: ${size}; height: ${size};`" />
</template>

<script>
export default {
  name: 'avatar',
  props: {
    alt: {
      type: String,
      default: 'Caerus Avatar Icon'
    },
    avatar: {
      type: String,
      default: ''
    },
    default: {
      type: String,
      default: '/default-avatar.svg'
    },
    size: {
      type: String,
      default: '40px'
    }
  },
  data() {
    return {
      fallback: false,
      show: true
    }
  },
  methods: {
    onError (e) {
      if(!this.fallback) {
        e.target.src = this.default
        this.fallback = true
      } else {
        this.show = false
      }
    }
  }
}
</script>
