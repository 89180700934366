<template>
	<b-modal
	  :id="id"
	  header-class="brand-center p-3"
	  body-class="p-0"
	  dialog-class="full-screen-modal"
	  content-class="p-0 modal-content"
	  @hidden="initForm"
	  footer-class="hidden"
	>
	  <template #modal-header>
	  	<b-navbar-brand>
        <svg-icon
		  aria-label="Caerus logo, click to go to home page"
          name="caerus-logo-navbar-light"
          width="122"
		  role="presentation"
        />
      </b-navbar-brand>
	  </template>

	  <div class="nursery-onboarding-page auth-page" id="auth-page">
	    <b-row>
	      <b-col md="8" offset-md="2" lg="5" offset-lg="3">
	        <div class="auth-header">
	          <h4 class="title">{{ stepOne ? 'New service' : 'Service information' }}</h4>
	        </div>
	        <div class="auth-box">
	  				<ServiceFormStepOne v-if="stepOne" :form="form" :formLock="formLock" :serverErrors="serverErrors" />
	  				<ServiceFormStepTwo v-else :form="form" :formLock="formLock" :serverErrors="serverErrors" />
	        </div>
	      </b-col>
	    </b-row>
	    <b-row>
	      <b-col md="8" offset-md="2" lg="5" offset-lg="3">
	        <div class="align-items-center">
	        	<b-button variant="primary" block @click="onSubmit">{{ stepOne ? 'Continue' : 'Finish' }}</b-button>
	        	<b-button v-if="!stepOne" @click="stepOne = true" variant="outline-primary" block>Back</b-button>
	        	<b-button @click="$bvModal.hide(id)" variant="outline-primary" block>Cancel</b-button>
	        </div>
	      </b-col>
	    </b-row>
	  </div>

	</b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import FormMixin from '@/core/mixins/form-mixin.js'
import ServiceFormStepOne from '@/modules/nursery/components/ServiceFormStepOne'
import ServiceFormStepTwo from '@/modules/nursery/components/ServiceFormStepTwo'
import nurseryApiService from '@/modules/nursery/services/nursery.api'

export default {
  name: 'NewServiceModal',

  mixins: [FormMixin],

  props: {
  	id: {
  		default: 'newServiceModal',
  		type: String,
  	},
  },

  components: {
    ServiceFormStepOne,
    ServiceFormStepTwo,
  },

  data () {
    return {
    	stepOne: true,
      form: {},
    }
  },

  computed: {
  	...mapGetters('auth', [
  	  'activeNursery'
  	]),
  },

  watch: {
  	stepOne() {
  		/* scroll to the top of the modal body */
			document.getElementById('auth-page').parentElement.scroll(0,0)
  	},
  },

  methods: {

  	initForm() {
  		this.form = {
        service_name: '',
        is_ci_registered: true,
        cs_number: '',
        postcode: '',
        address_line_1: '',
        address_line_2: '',
        county: '',
        city: '',
        contact_phone: '',
        contact_email: '',
      	activity_categories: [],
      	meals: [],
      	provisions: [],
      	support_options: [],
      	provides_additional_support: false,
      	asn_info: '',
      }
      this.stepOne = true
      this.serverErrors = {}
  	},

  	onSubmit() {
  		if (this.stepOne) {
  			this.stepOne = false
  			return
  		}

  		this.formState = 'loading'
  		this.serverErrors = {}

  		nurseryApiService.branch.create(this.activeNursery.id, this.form)
  			.then( response => {
  				this.$bus.emit('app::alert::success', 'New service successfully added')
  				this.$store.dispatch('auth/user')
  				this.$bvModal.hide(this.id)
  				this.$router.push({ name: 'service-details', params: { serviceId: response.data.id }})
  				this.formState = 'default'
  			})
  			.catch( error => {
  				this.stepOne = true
          this.formState = 'error'
          if (error && error.response.data.errors) {
            this.serverErrors = error.response.data.errors
          } else {
            this.contactSupport(error)
          }
        })
        .finally( () => {
          this.loading = false
        })
  	},
  },

	mounted() {
		this.initForm()

	},
}
</script>