import app from '@/main'
import axios from 'axios'

let instance = axios.create({
  baseURL: 'https://api.ideal-postcodes.co.uk/v1',
  timeout: 15000
})

instance.interceptors.request.use(config => {
  app.$Progress.start()
  return config
})

instance.interceptors.response.use(response => {
  app.$Progress.finish()
  return response
}, error => {
  app.$Progress.finish()
  return Promise.reject(error)
})

export default {
  postcodeLookup (postcode) {
    return instance({ url: `/postcodes/${postcode}?api_key=${process.env.VUE_APP_IDEAL_POSTCODES_API_KEY}&filter=line_1,line_2,line_3,county,post_town`, method: 'get' })
  },
}
