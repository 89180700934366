<template>
	<div>
	  <b-form-row>
	    <b-col cols="8">
	      <validated-b-form-group 
	        name="postcode" 
	        label="Postcode" 
		    	label-for="postcode"
	        class="app-input required"
	        :server-errors="serverErrors.postcode || hasNotSearched"
	      >
	        <b-form-input
	          maxlength="8"
	          v-model.trim.lazy="form.postcode"
	          :disabled="formLock"
			  		aria-label="postcode"
	          @input="disablePostcodeLookup = false"
			  		aria-required="true"
	          required 
					/>
	      </validated-b-form-group>
	    </b-col>
	    <b-col cols="4">
	      <span class="input-group-btn">
	        <b-button
	          variant="outline-primary"
	          class="btn mt-4 w-100"
	          :disabled="formLock || disablePostcodeLookup || !this.form.postcode"
	          @click="postcodeLookup($event)"
	        >
	          Find Address
	        </b-button>
	      </span>
	    </b-col>
	  </b-form-row>
	  <validated-b-form-group 
	    v-if="hasAddresses"
	    name="addresses" 
	    label="Addresses" 
	    label-for="addresses"
	    class="app-input" 
	  >
	    <b-form-select 
	      v-model="selectedAddressIndex" 
	      :options="addressOptions" 
	      :disabled="formLock" 
	      class="mt-2"
		  	aria-label="address option"
	      @change="onAddressChange($event)" 
	    >
	      <template v-slot:first>
	        <option 
	          :value="null" 
	          disabled
	        >
	          -- Please select an address --
	        </option>
	      </template>
	    </b-form-select>
	  </validated-b-form-group>
	  <p v-if="noAddressesFound">Postcode not found. Please type in your address.</p>
	  <div v-if="hasAddresses || noAddressesFound || showAddressForm">
	    <validated-b-form-group name="address_line_1" label="Address Line 1" label-for="address line 1" class="app-input required" :server-errors="serverErrors.address_line_1">
	      <b-form-input
		    	aria-label="address line 1"
	        v-model.trim.lazy="form.address_line_1"
	        :disabled="formLock"
					aria-required="true"
	        required />
	    </validated-b-form-group>
	    <validated-b-form-group label-for="address line 2" name="address_line_2" label="Address Line 2" class="app-input optional" :server-errors="serverErrors.address_line_2">
	      <b-form-input
		    	aria-label="address line 2"
	        v-model.trim.lazy="form.address_line_2"
	        :disabled="formLock"
					aria-required="true"
	        required />
	    </validated-b-form-group>
	    <validated-b-form-group label-for="city" name="city" label="City" class="app-input required" :server-errors="serverErrors.city">
	      <b-form-input
		    	aria-label="city"
	        v-model.trim.lazy="form.city"
	        :disabled="formLock"
					aria-required="true"
	        required />
	    </validated-b-form-group>
	    <validated-b-form-group  name="county" label="County" label-for="county" class="app-input required"  :server-errors="serverErrors.county">
	      <b-form-input
		    	aria-label="county"
	        v-model.trim.lazy="form.county"
	        :disabled="formLock"
					aria-required="true"
	        required />
	    </validated-b-form-group>
		</div>
	</div>
</template>

<script>
import idealPostcodesApiService from '@/core/services/ideal-postcodes.api'
import idealPostcodesAddressFormatterMixin from '@/core/mixins/ideal-postcodes-address-formatter-mixin'
import FieldMixin from '@/core/mixins/field-mixin'


export default {
  name: 'AddressLookup',

  mixins: [idealPostcodesAddressFormatterMixin, FieldMixin],

  watch: {
    "form.address_line_1"(val) {
      if (val) {
        this.showAddressForm = true
      }
    },
  },

	computed: {
		hasNotSearched() {
			const keys = [				
				'address_line_1',
				'city',
				'county',
			];
						
			const hasError = Object.keys(this.serverErrors).filter((serverErrorKey) => {
				return keys.includes(serverErrorKey)
			}).length === keys.length

			return hasError? ['Please search for an address by using the "Find Address button"']: []
		}
	},

  methods: {
    postcodeLookup () {
      this.form.address_line_1 = ''
      this.form.address_line_2 = ''
      this.form.city = ''
      this.form.county = ''
      this.selectedAddressIndex = null

      idealPostcodesApiService.postcodeLookup(this.form.postcode)
      .then(response => {
        if (response.data.code === 2000 && response.data.result.length > 0) {
          this.noAddressesFound = false
          this.disablePostcodeLookup = true
          this.addresses = response.data.result
        } else {
          this.addresses = []
          this.noAddressesFound = true
        }
      }).catch(error => {
        this.addresses = []
        this.noAddressesFound = true
      })
    },
    onAddressChange ($event) {
      if (this.addresses[this.selectedAddressIndex]) {
        let formattedAddress = this.formatAddress(this.addresses[this.selectedAddressIndex])

        this.form.address_line_1 = formattedAddress.line_1
        this.form.address_line_2 = formattedAddress.line_2
        this.form.city = formattedAddress.post_town
        this.form.county = formattedAddress.county
      }
    },
  },

  mounted() {
    if (this.form.address_line_1) {
    	this.showAddressForm = true
    }
  },
}
</script>

<style lang="scss" scoped>
	.btn {
		position: relative;
		top: 9px;
	}
</style>