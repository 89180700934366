<template>
  <div
    id="app"
    class="global-layout nav-global "
  >
    <BetaBanner/>
    <Navbar />
    <!-- https://github.com/mbj36/vue-burger-menu -->
    <div id="page-wrap">
      <router-view />
    </div>
  </div>
</template>

<script>
import Navbar from '@/core/layouts/global-layout/components/Navbar'
import BetaBanner from '@/core/layouts/global-layout/components/BetaBanner'

export default {

  components: {
    Navbar,
    BetaBanner
  },

}
</script>
<style>
@media only screen and (max-width: 576px) {
  .nav-global {
    width: fit-content;
  }
}
</style>
