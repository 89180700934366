<template>
  <vue-multi-select
    :value="value"
    @input="$emit('input', $event)"
    :custom-label="setCustomLabel"
    :options="setOptions()"
    :disabled="disabled"
    :searchable="searchable"
    :close-on-select="closeOnSelect"
    :show-labels="showLabels"
    :show-pointer="showPointer"
    :allow-empty="allowEmpty"
    :placeholder="placeholder"
    :open-direction="openDirection"
    :optionHeight="optionHeight"
    :whiteCaret="whiteCaret"
    :blackCaretSize="blackCaretSize"
    :multiple="multiple"
  >
    <svg-icon
      v-if="!whiteCaret && blackCaretSize <= '10'"
      name="vue-multiselect-caret"
      class="vue-multiselect-caret"
      :width="blackCaretSize"
      :height="blackCaretSize"
      slot="caret"
      role="none"
    />

    <svg-icon
      v-if="!whiteCaret && blackCaretSize > '10'"
      name="vue-multiselect-caret"
      class="vue-multiselect-caret mt-n1"
      :width="blackCaretSize"
      :height="blackCaretSize"
      slot="caret"
      role="none"
    />

    <svg-icon
      v-if="whiteCaret"
      name="vue-multiselect-caret-white"
      class="vue-multiselect-caret-white"
      width="16"
      slot="caret"
      role="none"
    />

    <template slot="singleLabel" slot-scope="props">
      <slot name="selected-option" :value="props.option"></slot>
    </template>

    <template slot="option" slot-scope="props">
      <slot name="list-option" :value="props.option"></slot>
    </template>
  </vue-multi-select>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Boolean, Array],
    },
    allowEmpty: {
      type: Boolean,
      default: false
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    optionId: {
      type: String,
      default: 'id'
    },
    optionValue: {
      type: String,
      default: 'name'
    },
    placeholder: {
      type: String,
      default: '--'
    },
    searchable: {
      type: Boolean,
      default: false
    },
    showLabels: {
      type: Boolean,
      default: false
    },
    showPointer: {
      type: Boolean,
      default: false
    },
    optionHeight: {
      type: Number,
      default: 40
    },
    whiteCaret: {
      type: Boolean,
      default: false
    },
    blackCaretSize: {
      type: String,
      default: '10',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    openDirection: {
      type: String,
      default: () => null
    }
  },
  methods: {
    setCustomLabel (opt) {
      const selected = this.options.find(option => option.id == opt)
      return selected ? selected[this.optionValue] : null
    },
    setOptions () {
      return this.options.map( option => option[ this.optionId ] )
    },
  }
}
</script>
