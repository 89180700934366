<template>
  <div class="beta-banner">
    <b-container class="d-flex justify-content-between align-items-center h-100">
      <div class="d-flex flex-gap-3 align-items-center">
        <span class="beta d-flex align-items-center justify-content-center">BETA</span>
        <span>This is a new service - your <a href="https://support.caerus.scot/portal/en-gb/newticket">feedback</a> will help us to improve it</span>
      </div>
      <span>Need help? <a href="https://support.caerus.scot/portal/en-gb/newticket">Get in touch</a></span>
    </b-container>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  .beta-banner {
    background: $grey;
    height: 54px;
  }

 @media (max-width: 576px) {
    .beta-banner {
      height: 100px;
        }
  }
  .beta {
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    background: #1C65AC;
    height: 28px;
    width: 66px;
    display: inline-block;
  }

  a {
    color: #004C94;
    text-decoration: underline;
  }
</style>