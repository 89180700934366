const configIsReady = state => state.configIsReady
const ageGroups = state => state.ageGroups
const carerAgeGroups = state => state.carerAgeGroups
const providerTypes = state => state.providerTypes
const closingDayTypes = state => state.closingDayTypes
const daysOfWeek = state => state.daysOfWeek
const defaultRatios = state => state.defaultRatios
const employmentTypes = state => state.employmentTypes
const genders = state => state.genders
const inviteStaffRolesTypes = state => state.inviteStaffRolesTypes
const ratios = state => state.ratios
const wageTypes = state => state.wageTypes
const paymentTypes = state => state.paymentTypes
const sessionSpaceTypes = state => state.sessionSpaceTypes
const serviceTypes = state => state.serviceTypes
const provisions = state => state.provisions
const provisionTerm = state => state.provisionTerm
const meals = state => state.meals
const absenceTypes = state => state.absenceTypes
const childVoices = state => state.childVoices
const sleepingCounts = state => state.sleepingCounts
const eatingCounts = state => state.eatingCounts
const drinkingCounts = state => state.drinkingCounts
const pathwayAndOutcomeAnswers = state => state.pathwayAndOutcomeAnswers
const nextStepStatuses = state => state.nextStepStatuses
const spacesTableBlueprint = state => state.spacesTableBlueprint
const activityCategories = state => state.activityCategories
const supportOptions = state => state.supportOptions
const bookingOptions = state => state.bookingOptions
const availabilities = state => state.availabilities
const ratings = state => state.ratings
const monthsInYear = state => state.monthsInYear
const monthsInYearLabels = state => state.monthsInYear.map ( i => ({
	key: i,
	class: 'text-center'
}))
const fundingHoursModels = state => state.fundingHoursModels
const sessionBookingTypes = state => state.sessionBookingTypes
/* payment type helpers */
const fundedHoursPaymentId = state => state.paymentTypes.find( i => i.name == 'Funded Hours').id
const creditCardPaymentId = state => state.paymentTypes.find( i => i.name == 'Credit/Debit Card').id
const invoicePaymentId = state => state.paymentTypes.find( i => i.name == 'Invoice').id
const childcareVoucherPaymentId = state => state.paymentTypes.find( i => i.name == 'Childcare vouchers').id

export default {
  configIsReady,
	ageGroups,
	carerAgeGroups,
	providerTypes,
	closingDayTypes,
	daysOfWeek,
	defaultRatios,
	employmentTypes,
	genders,
	inviteStaffRolesTypes,
	ratios,
	wageTypes,
	paymentTypes,
	sessionSpaceTypes,
	serviceTypes,
	provisions,
	provisionTerm,
	meals,
	absenceTypes,
	childVoices,
	sleepingCounts,
	eatingCounts,
	drinkingCounts,
	pathwayAndOutcomeAnswers,
	nextStepStatuses,
	spacesTableBlueprint,
	activityCategories,
	supportOptions,
	bookingOptions,
	availabilities,
	ratings,
	monthsInYear,
	monthsInYearLabels,
	fundingHoursModels,
	sessionBookingTypes,
	fundedHoursPaymentId,
	creditCardPaymentId,
	invoicePaymentId,
	childcareVoucherPaymentId,
}
