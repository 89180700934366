import store from '@/core/store'
import { isSecureRoute, isNotSecureRoute, allows } from '@/core/router/utils'

let initialFullPath = null

const serviceOnboarding = (to, from, next) => {
  if (store.getters['auth/isServiceOnboardingStepOne'] && 
      to.name !== 'service-onboarding-step-one'
    ) {  
    return next({ name: 'service-onboarding-step-one' })
  } else if (
      store.getters['auth/isServiceOnboardingStepTwo'] &&
      to.name !== 'service-onboarding-step-two'
    ) {
    return next({ name: 'service-onboarding-step-two' })
  }
  next()
}

const titleSetter = (to, from, next) => {
  if (to && to.meta && to.meta.title) {
    document.title = `${to.meta.title} - Caerus`
  } else {
    document.title = `Caerus`
  }

  next()
}

const handleNotLoggedInUsers = (to, from, next) => {
  if (!store.getters['auth/userIsLoggedIn']) {
    if (isSecureRoute(to.name) || !isNotSecureRoute(to.name)) {
      return next({ name: 'login' })
    }
    return next()
  }
  next()
}

const handleLoggedInUsers = (to, from, next) => {
  if (store.getters['auth/userIsLoggedIn']) {
    if (!allows(to.name)) {
      if (store.getters['auth/role'] == 'nursery-super-admin') {
        return next({ name: 'bookings' })
      }
      if (store.getters['auth/role'] == 'nursery-admin') {
        return next({ name: 'dashboard' })
      }
      if (store.getters['auth/role'] == 'nursery-staff') {
        return next({ name: 'dashboard' })
      }
      return next({ name: 'home' })
    }
    return next()
  }
  next()
}

export default {
  serviceOnboarding,
  titleSetter,
  handleNotLoggedInUsers,
  handleLoggedInUsers,
}
