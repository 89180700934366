import http from '@/core/services/drivers/app-http-driver'

export default {
  login (data = {}) {
    return http({ url: '/auth/login', method: 'post', data: data })
  },
  refresh () {
    return http({ url: '/auth/refresh', method: 'get' })
  },
  logout () {
    return http({ url: '/auth/logout', method: 'get' })
  },
  register (data = {}) {
    return http({ url: '/auth/register', method: 'post', data: data })
  },
  codeCheck (data = {}) {
    return http({ url: '/auth/code-check', method: 'post', data: data })
  },
  resend (data = {}) {
    return http({ url: '/auth/resend-verification', method: 'post', data: data })
  },
  forgot (email = '') {
    return http({ url: '/auth/forgotten-password', method: 'post', data: { email: email } })
  },
  reset (data = {}) {
    return http({ url: '/auth/reset-password', method: 'post', data: data })
  },
  user () {
    return http({ url: '/auth/user', method: 'get' })
  },
  checkExistingEmail(data) {
    return http({ url: '/auth/check-existing', method: 'post', data: data }) 
  },
  checkExistingEmailForBranch(branchId, data) {
    return http({ url: `/auth/check-existing-for-branch/${branchId}`, method: 'post', data: data }) 
  },
  activateAccount(data) {
    return http({ url: '/auth/activate-account', method: 'post', data: data }) 
  },
  completeAccount(data) {
    return http({ url: '/auth/complete-account', method: 'post', data: data }) 
  },
  updateProfile(data) {
    return http({ url: '/users/profile', method: 'post', data: data, headers: { 'Content-Type': 'multipart/form-data' } }) 
  },
  toggleServiceAccount() {
    return http({ url: '/auth/service-account', method: 'post' })
  },
  toggleCarerAccount() {
    return http({ url: '/auth/carer-account', method: 'post' })
  },
  toggleUserSubscriptionToServiceEmailUpdates() {
    return http({ url: '/users/toggle-subscription', method: 'post' })
  },
}
