import lazyLoading from '@/core/router/lazy-loading'
import GlobalLayout from '@/core/layouts/global-layout/GlobalLayout'
import store from '@/core/store'

export const bookingsRoutes = [
  {
    path: '/planning',
    name: 'planning',
    redirect: { name: 'bookings' },
    component: GlobalLayout,
    children: [
      {
        path: '',
        name: 'bookings',
        component: lazyLoading('bookings', 'BookingsLayout'),
        redirect: store.getters['auth/userIsKeyWorker'] ? {name: 'keyworker-bookings'} : {name: 'service-sessions'},
        children: [
          /* Shared */
          {
            name: 'service-sessions',
            path: 'sessions',
            component: lazyLoading('bookings', 'service/Sessions'),
          },
          {
            name: 'service-sessions-archive',
            path: 'sessions-archive',
            component: lazyLoading('bookings', 'service/Archive'),
          },
          {
            name: 'keyworker-bookings',
            path: 'index',
            component: lazyLoading('bookings', 'keyworker/Index'),
          },
          {
            name: 'keyworker-calendar',
            path: 'calendar',
            component: lazyLoading('bookings', 'keyworker/Calendar'),
          },
          {
            name: 'keyworker-transactions',
            path: 'transactions',
            component: lazyLoading('bookings', 'keyworker/Transactions'),
          },
          {
            name: 'keyworker-waiting-list',
            path: 'waiting-list',
            component: lazyLoading('bookings', 'keyworker/WaitingList'),
          },
          {
            name: 'service-orders',
            path: 'orders',
            component: lazyLoading('bookings', 'service/Orders'),
          },
          {
            name: 'service-bookings',
            path: 'bookings',
            component: lazyLoading('bookings', 'service/Bookings'),
          },
          {
            name: 'service-locations',
            path: 'locations',
            component: lazyLoading('bookings', 'service/Locations'),
          },
          {
            name: 'service-calendar',
            path: 'service-calendar',
            component: lazyLoading('bookings', 'service/Calendar'),
          },
        ],
      },
      {
        name: 'create-service-session',
        path: 'sessions/create',
        component: lazyLoading('bookings', 'service/CreateSession'),
      },
      {
        name: 'view-service-session',
        path: 'sessions/:sessionId',
        component: lazyLoading('bookings', 'service/ViewSession'),
        props: true,
        redirect: { name: 'view-service-session-bookings' },
        children: [
          {
            name: 'view-service-session-calendar',
            path: 'calendar',
            component: lazyLoading('bookings', 'service/ViewSessionCalendar'),
          },
          {
            name: 'view-service-session-bookings',
            path: 'bookings',
            component: lazyLoading('bookings', 'service/ViewSessionBookings'),
          },
          {
            name: 'view-service-session-register',
            path: 'register',
            component: lazyLoading('bookings', 'service/ViewSessionRegister'),
          },
          {
            name: 'view-service-session-waiting-list',
            path: 'waiting-list',
            component: lazyLoading('bookings', 'service/ViewSessionWaitingList'),
          },
        ],
      },
      {
        name: 'edit-service-session',
        path: 'sessions/:sessionId/edit',
        component: lazyLoading('bookings', 'service/EditSession'),
        props: true,
      },
      {
        name: 'service-session-new-booking',
        path: 'new',
        component: lazyLoading('bookings', 'service/NewBooking'),
        props: true,
      },
      {
        name: 'duplicate-service-session',
        path: 'sessions/:sessionId/duplicate',
        component: lazyLoading('bookings', 'service/CreateSession'),
        props: true,
      },
    ],
  }
]