import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
	configIsReady: false,
  ageGroups: [],
  providerTypes: [],
  closingDayTypes: [],
  daysOfWeek: [],
  defaultRatios: [],
  employmentTypes: [],
  genders: [],
  inviteStaffRolesTypes: [],
  ratios: [],
  wageTypes: [],
  paymentTypes: [],
  sessionSpaceTypes: [],
  serviceTypes: [],
  provisions: [],
  provisionTerm: [],
  meals: [],
  absenceTypes: [],
  childVoices: [],
  sleepingCounts: [],
  eatingCounts: [],
  drinkingCounts: [],
  pathwayAndOutcomeAnswers: [],
  nextStepStatuses: [],
  spacesTableBlueprint: [],
  activityCategories: [],
  supportOptions: [],
  bookingOptions: [],
  availabilities: [],
  ratings: [],
  monthsInYear: [ 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec' ],
  fundingHoursModels: [],
  sessionBookingTypes: [],
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
