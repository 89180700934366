const SET_CONFIG = (state, config) => {
  state.ageGroups = config.age_groups
  state.carerAgeGroups = config.carer_age_groups
  state.providerTypes = config.provider_types
  state.closingDayTypes = config.closing_day_types
  state.daysOfWeek = config.days_of_week
  state.defaultRatios = config.default_ratios
  state.employmentTypes = config.employment_types
  state.genders = config.genders
  state.inviteStaffRolesTypes = config.account_types
  state.ratios = config.ratios
  state.wageTypes = config.wage_types
  state.paymentTypes = config.payment_types
  state.sessionSpaceTypes = config.session_space_types
  state.configIsReady = true
  state.serviceTypes = config.service_types
  state.provisions = config.provisions
  state.provisionTerm = config.provision_term
  state.meals = config.meals
  state.absenceTypes = config.absence_types
  state.childVoices = config.child_voices
  state.diaryActivityAnswers = config.diary_activity_answers
  state.sleepingCounts = config.sleeping_counts
  state.eatingCounts = config.eating_counts
  state.drinkingCounts = config.drinking_counts
  state.pathwayAndOutcomeAnswers = config.pathway_and_outcome_answers
  state.nextStepStatuses = config.next_step_status
  state.spacesTableBlueprint =  config.spaces
  state.activityCategories = config.activity_categories
  state.supportOptions = config.support_options
  state.bookingOptions = config.booking_options
  state.availabilities = config.availabilities
  state.ratings = config.ratings
  state.fundingHoursModels = config.funding_hours_models
  state.sessionBookingTypes = config.session_booking_types
}

export default {
  SET_CONFIG
}
