import planningApiService from '@/modules/bookings/services/planning.api'

const fetchSessionsForNurseryBranch = (context, options)  => {
  return new Promise((resolve, reject) => {
    planningApiService.session.index(options.nurseryId, options.nurseryBranchId)
      .then( response => {
        context.commit('SET_SESSIONS_FOR_NURSERY_BRANCH', {data: response.data.data, nurseryBranchId: options.nurseryBranchId})
        resolve(response)
      })
      .catch( error => {
        reject(error.response)
      })
  })
}

const fetchArchivedSessionsForNurseryBranch = (context, options)  => {
  return new Promise((resolve, reject) => {
    planningApiService.session.archive(options.nurseryId, options.nurseryBranchId)
      .then( response => {
        context.commit('SET_SESSIONS_FOR_NURSERY_BRANCH', {data: response.data.data, nurseryBranchId: options.nurseryBranchId})
        resolve(response)
      })
      .catch( error => {
        reject(error.response)
      })
  })
}

export default {
  fetchSessionsForNurseryBranch,
  fetchArchivedSessionsForNurseryBranch
}