<template>
  <b-nav-item
    class="nav-item-icon position-relative"
    aria-label="navigate to messages"
    :to="{ name: 'messages' }"
  >
    <span class="sr-only">Messages</span>
    <span
      v-if="user.conversations && user.conversations.unread_conversations.length"
      class="message-badge"
    />

    <component
      :is="icon"
      class="md-heroicon text-primary my-auto"
    />
  </b-nav-item>
</template>

<script>
import { ChatIcon } from '@vue-hero-icons/outline'
import { ChatIcon as ChatSolidIcon } from '@vue-hero-icons/solid'
import { mapGetters } from 'vuex'

export default {
  name: 'MessageNavItem',

  components: {
    ChatIcon,
    ChatSolidIcon
  },

  computed: {
    ...mapGetters('auth', [
      'user'
    ]),

    icon() {
      return `${this.$route.name == 'messages' ? 'chat-solid' : 'chat'}-icon`
    }
  },
}
</script>

<style lang="scss" scoped>
  .message-badge {
    position: absolute;
    top: 19px;
    right: -2px;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background: $secondary;
    border: 2px solid $white;
  }
</style>
