import http from '@/core/services/drivers/app-http-driver'

export default {
  index() {
    return http.get('/user-notifications')
  },
  delete(notificationId) {
    return http({ url: `/user-notifications/${notificationId}`, method: 'delete' })
  },
  markAsRead(notificationId, data = {}) {
    return http.post(`/user-notifications/mark-as-read/${notificationId}`, data)
  },
  markAllAsRead(nurseryId, branchId, data = {}) {
    return http.post('/user-notifications/mark-all-as-read', data)
  },
}
