const hasCurrentQuestionnaire = state => {
  return state.currentQuestionnaire ? true : false
}

const currentQuestionnaire = state => {
  return state.currentQuestionnaire
}
const currentQuestionAnswer = state => {
	return state.currentQuestionAnswer
}

export default {
	hasCurrentQuestionnaire,
	currentQuestionnaire,
	currentQuestionAnswer,
}