<template>
	<validated-b-form-group 
	  :label="question.question" 
	  class="app-input bordered"
	>
	  <b-form-radio-group
	    v-model="currentQuestionAnswer.answer_option_ids"
	    :options="question.options.data"
	    value-field="id"
	    text-field="option"
	    class="is-checked"
	    stacked
	  ></b-form-radio-group>
	</validated-b-form-group>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
	name: 'MulitpleOption',
	
	props: {
		question: {
			required: true,
			type: Object,
		},
	},

	computed: {
		...mapGetters('questionnaire', [
		  'currentQuestionAnswer',
		]),
	},
}
</script>