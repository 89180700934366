<template>
  <SharedNavigation>
    <b-nav-item
      :to="{ name: 'dashboard' }"
      class="d-none d-lg-flex"
    >
      <span>Dashboard</span>
    </b-nav-item>

    <b-nav-item-dropdown 
      text="Profiles"
      class="d-none d-lg-flex"
    >

      <b-dropdown-item
        :to="{ name: 'children' }"
      >
        Children
      </b-dropdown-item>

    </b-nav-item-dropdown>

    <b-nav-item
      :to="{ name: 'team-schedule' }"
      class="d-none d-lg-flex"
    >
      <span>My schedule</span>
    </b-nav-item>

  </SharedNavigation>
</template>

<script>
import { mapGetters } from 'vuex'
import SharedNavigation from '@/core/layouts/global-layout/components/user-navigation/SharedNavigation'
import { isBookingsRoute } from '@/core/router/utils'

export default {
  components: {
    SharedNavigation
  },

  computed: {
    ...mapGetters('auth', ['userIsSessionalStaff']),
  },

  methods: {
    isBookingsRoute() {
      return isBookingsRoute(this.$route.name)
    },
  }
}
</script>
