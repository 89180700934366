<template>
  <!-- <SharedNavigation> -->
  <div class="d-block">
    <div class="">
      <li class="settings-link d-flex align-items-center collapse-link py-3 ">
        <router-link :to="{ name: 'dashboard' }">
          Dashboard
        </router-link>
      </li>

      <div class="">
        <SearchFilterSection
          id="profiles"
          class="collapse-link py-3"
        >
          <template #title>
            <span class="sr-only"> Profiles </span>
            <span class="heading-black">Profiles</span>
          </template>
          <template #content>
            <b-col>
              <li class="settings-link d-flex  collapse-link ">
                <router-link :to="{ name: 'children' }">
                  Children
                </router-link>
              </li>
            </b-col>
          </template>
        </SearchFilterSection>

        <li class="settings-link d-flex align-items-center collapse-link  py-3">
          <router-link :to="{ name: 'team-schedule' }">
            My schedule
          </router-link>
        </li>
      </div>
    <!--</SharedNavigation> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import SharedNavigation from '@/core/layouts/global-layout/components/user-navigation/SharedNavigation'
import SearchFilterSection from '@/modules/results/components/SearchFilterSection'

export default {
  components: {
    // SharedNavigation
    SearchFilterSection,
  },

  computed: {
    ...mapGetters('auth', ['userIsSessionalStaff']),
  }
}
</script>
