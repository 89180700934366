<template>
  <component :is="getNavigationComponent" />
</template>

<script>
import { mapGetters } from 'vuex'
import GuestNavigationMobile from '@/core/layouts/global-layout/components/user-navigation/GuestNavigationMobile'
import AdminNavigationMobile from '@/core/layouts/global-layout/components/user-navigation/AdminNavigationMobile'
import CarerNavigationMobile from '@/core/layouts/global-layout/components/user-navigation/CarerNavigationMobile'
import StaffNavigationMobile from '@/core/layouts/global-layout/components/user-navigation/StaffNavigationMobile'

export default {

  computed: {
    ...mapGetters('auth', ['role']),
    
    getNavigationComponent() {
      if(this.role === null) {
        return GuestNavigationMobile
      }

      const roles = {
        'nursery-super-admin': AdminNavigationMobile,
        'nursery-admin': AdminNavigationMobile,
        'nursery-staff': StaffNavigationMobile,
        'keyworker': CarerNavigationMobile,
        'carer': CarerNavigationMobile,
      }

      return roles[this.role]
    }
  }
}
</script>
