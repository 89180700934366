const user = state => state.user

const userIsLoggedIn = state => {
  if (state.currentJWT && state.user) {
    return true
  }

  return false
}

const userIsActivated = state => {
  if (state.user && state.user.activated) {
    return true
  }

  return false
}

const userHasNoNursery = state => {
  if (state.user && state.user.nurseries.data.length === 0) {
    return true
  }

  return false
}

const userHasNoBranch = state => {
  if (!state.user || state.user.nurseries.data.length === 0 || !state.user.nurseries.data[0].branches) {
    return true
  }

  return false
}

const userHasNoStaffCategory = state => {
  if (!state.user || state.user.nurseries.data.length === 0 || !state.user.nurseries.data[0].branches.data.find(branch => branch.staff_category)) {
    return true
  }

  return false
}

const userHasCompletedMigration = state => {
  if (state.activeNursery && state.activeNurseryBranch && state.activeNursery.provider_type && state.activeNurseryBranch.service_types.data.length != 0 && state.activeNurseryBranch.hourly_rate && state.activeNurseryBranch.payment_types.data.length != 0 && state.activeNurseryBranch.local_authority_id) {

    state.userHasCompletedMigration = true
  }
  return state.userHasCompletedMigration
}

const userHasViewedIntro = state => {
  if (state.user && state.user.intro_viewed) {
    return true
  }

  return false
}

const activeNurseryBranchSetupIsComplete = state => {
  if ((state.role === 'nursery-super-admin' || state.role === 'nursery-admin') && state.activeNurseryBranch && state.activeNurseryBranch.branch_details_complete && state.activeNurseryBranch.planning_complete && state.activeNurseryBranch.accounts_complete /*&& state.activeNurseryBranch.staff_complete*/) {
    return true
  } else if (state.role === 'nursery-staff' && state.user.activity_per_branch[state.activeNurseryBranch.id]) {
    return true
  }

  return false
}

const userHasChildren = state => {
  if (state.user && state.user.keyworkerProfile && state.user.keyworkerProfile.children.data.length > 0) {
    return true
  } else {
    return false
  }
}

const userChildren = (state, getters) => getters.userHasChildren ? state.user.keyworkerProfile.children.data : []

const observerChildren = (state, getters) => {
  return state.user.allCarerProfiles?.data.map((profile) => {
    return profile.children.data
  }).flat()
}

const getUserChildById = (state, getters) => id => {
  return getters.userChildren.find( child => child.id == id)
}

const userIsKeyWorker = state => {
  if (state.user && state.role === 'keyworker') {
    return true
  }

  return false
}

const userIsStaff = state => {
  return state.activeNursery && state.activeNursery.staffProfile
}

const userIsSessionalStaff = state => {
  return userIsStaff && state.activeNursery.staffProfile.employment_type == 2
}

const userIsFullTimeStaff = state => {
  return userIsStaff && state.activeNursery.staffProfile.employment_type == 0
}

const userIsPartTimeStaff = state => {
  return userIsStaff && state.activeNursery.staffProfile.employment_type == 1
}

const jumpStepBack = state => state.jumpStepBack

const jwt = state => state.currentJWT
const jwtData = (state, getters) => state.currentJWT ? JSON.parse(atob(getters.jwt.split('.')[1])) : null
const jwtSubject = (state, getters) => getters.jwtData ? getters.jwtData.sub : null
const jwtIssuer = (state, getters) => getters.jwtData ? getters.jwtData.iss : null

const authIsReady = state => state.authIsReady

const activeNursery = state => state.activeNursery
const activeNurseryBranch = state => state.activeNurseryBranch
const hideActiveNurseryBranchSetupFooter = state => state.hideActiveNurseryBranchSetupFooter
const openingTimes = state => state.openingTimes
const role = state => state.user? (state.activeNursery && state.activeNursery.staffProfile? ( state.role = state.activeNursery.staffProfile.role.slug): state.user.role.slug) : null

const userIsNurseryAdmin = state => {
  if (state.user && state.role === 'nursery-super-admin') {
    return true
  }
  return false
}

const userIsNurseryStaff = state => {
  if (state.user && state.role === 'nursery-staff') {
    return true
  }
  return false
}

const userHasStartMonth = state => {
  if(state.activeNursery && state.activeNursery.start_month && state.activeNursery.start_month !== '0') {
    return true
  }
  return false
}

const childNumber = state => state.childNumber

const triggerDashboardModal = state => {

  if (state.activeNursery && state.activeNurseryBranch && (!state.activeNursery.provider_type || !state.activeNurseryBranch.service_types || !state.activeNurseryBranch.hourly_rate || !state.activeNurseryBranch.payment_types || !state.activeNurseryBranch.local_authority_id)) {
    return true
  } else {
    return false
  }
}
const checkNotifications = state => state.checkNotifications

const updateChildren = state => state.updateChildren
const userHasEnabledMarketplace = state => state.userHasEnabledMarketplace
const upgraded = state => state.upgraded
const userHasSuspendedNursery = state => state.userHasSuspendedNursery
const userHasUpdatedChildren = state => state.userHasUpdatedChildren

const userActiveNurseryEmploymentType = state => {
  if (state.activeNursery && state.activeNursery.staffProfile) {
    return state.activeNursery.staffProfile.employment_type
  }
  return null
}

const redirectFromSignup = state => state.redirectFromSignup

const isServiceOnboarding = (state, getters) => {
  return getters.isServiceOnboardingStepOne || getters.isServiceOnboardingStepTwo
}

const isServiceOnboardingStepOne = (state, getters) => {
  return getters.userIsLoggedIn && getters.role == 'nursery-super-admin' && getters.userHasNoNursery && getters.userHasNoBranch
}

const isServiceOnboardingStepTwo = (state, getters) => {
  return getters.userIsLoggedIn && getters.role == 'nursery-super-admin' && !getters.userHasNoNursery && !getters.userHasNoBranch && !getters.isActiveNurseryBranchDetailsComplete  
}

const isActiveNurseryBranchDetailsComplete = state => {
  return state.activeNurseryBranch && state.activeNurseryBranch.branch_details_complete
}

const getServiceById = state => id => {
  return state.activeNursery.branches.data.find( service => service.id == id)
}

const userHasBasicInfo = state => state.user.first_name && state.user.last_name && state.user.telephone_number

const userHasAddress = state => (state.user.address_line_1 && state.user.postcode) ? true : false

const userPaymentMethods = state => {
  if (state.user.keyworkerProfile && state.user.keyworkerProfile.payment_methods.data.length > 0) {
    return state.user.keyworkerProfile.payment_methods.data
  } else {
    return []
  }
}

const userHasNoPaymentMethod = ( state, getters ) => getters.userPaymentMethods.length ? false : true

const serviceHasStripeConnectedAccount = ( state, getters ) => state.activeNurseryBranch.has_stripe_connected_account

const userHasKeyworkerRole = state => state.user && state.user.roles.data.find( i => i.slug == 'keyworker') ? true : false

const userHasNurseryRole = state => state.user && state.user.roles.data.find( i => i.slug == 'nursery-super-admin'
    || i.slug == 'nursery-admin'
    || i.slug == 'nursery-staff'
  ) ? true : false

const serviceAcceptedPaymentMethods = state => state.activeNurseryBranch ? state.activeNurseryBranch.payment_types.data : []

const userIsEligibleForEnquiry = ( state, getters ) => getters.userIsLoggedIn && getters.userHasAddress && getters.userHasChildren

export default {
  user,
  userHasViewedIntro,
  userIsLoggedIn,
  userIsActivated,
  userHasNoNursery,
  userHasNoBranch,
  userHasNoStaffCategory,
  userIsNurseryAdmin,
  userIsNurseryStaff,
  userHasStartMonth,
  childNumber,
  jwt,
  jwtData,
  jwtSubject,
  jwtIssuer,
  authIsReady,
  activeNursery,
  activeNurseryBranch,
  activeNurseryBranchSetupIsComplete,
  hideActiveNurseryBranchSetupFooter,
  openingTimes,
  role,
  triggerDashboardModal,
  userHasChildren,
  userChildren,
  observerChildren,
  getUserChildById,
  getServiceById,
  userIsKeyWorker,
  jumpStepBack,
  userHasCompletedMigration,
  checkNotifications,
  updateChildren,
  userHasEnabledMarketplace,
  upgraded,
  userHasSuspendedNursery,
  userHasUpdatedChildren,
  userIsStaff,
  userIsSessionalStaff,
  userIsFullTimeStaff,
  userIsPartTimeStaff,
  userActiveNurseryEmploymentType,
  redirectFromSignup,
  isServiceOnboarding,
  isServiceOnboardingStepOne,
  isServiceOnboardingStepTwo,
  isActiveNurseryBranchDetailsComplete,
  userHasBasicInfo,
  userHasAddress,
  userPaymentMethods,
  userHasNoPaymentMethod,
  serviceHasStripeConnectedAccount,
  userHasKeyworkerRole,
  userHasNurseryRole,
  serviceAcceptedPaymentMethods,
  userIsEligibleForEnquiry,
}
