<template>
  <div id="fcs-app">
    <app-alert />
    <router-view v-if="authIsReady && configIsReady" :key="routerViewKey"/>
    <cookie-law theme="fcs" buttonClass="btn btn-outline-secondary" position="top">
      <div slot="message">
        This website uses cookies to ensure you get the best experience, for
        more information
        <a
          href="https://www.caerus.scot/legal/cookies"
          target="_blank"
          style="font-weight: bold; color: white"
        >
          click here
        </a>
      </div>
    </cookie-law>
    <vue-progress-bar></vue-progress-bar>
    <portal-target name="modals" />
    <ModalQuestionnaire v-if="hasCurrentQuestionnaire" />
    <SuccessModal />
    <ErrorModal />
    <NurseryBranchPicker @switch="handleNurseryBranchSwitch"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CookieLaw from 'vue-cookie-law'
import AppAlert from '@/core/components/AppAlert'
import NurseryBranchPicker from '@/core/components/NurseryBranchPicker'

import Pusher from 'pusher-js'
window.Pusher = Pusher

import Echo from 'laravel-echo'

import ModalQuestionnaire from '@/modules/questionnaire/components/ModalQuestionnaire'
import SuccessModal from '@/core/components/SuccessModal'
import ErrorModal from '@/core/components/ErrorModal'

export default {
  name: 'fcs-app',

  components: {
    AppAlert,
    CookieLaw,
    ModalQuestionnaire,
    SuccessModal,
    ErrorModal,
    NurseryBranchPicker,
  },

  created() {
    this.$store.dispatch('auth/authCheck').catch((error) => {
      this.contactSupport(error)
    })

    this.$store.dispatch('core/getConfig').catch((error) => {
      this.contactSupport(error)
    })

    if (this.userIsLoggedIn && this.userIsKeyWorker) {
      this.$store.dispatch('questionnaire/modal')
    }

  },

  data: () => {
    return {
      websocketChannel: null,
      echo: null,
      routerViewKey: 0,
    }
  },

  watch: {
    user(val) {
      if (val) {
        if (this.echo) {
          this.echo.reload(val.token)
          this.joinChannels()
        } else {
          this.initWebsockets()
        }
      } else {
        this.echo.disconnect()
        this.echo = null
      }
    },

    userIsLoggedIn(val) {
      if (val) {
        this.$store.dispatch('questionnaire/modal')
      }
    },
  },

  computed: {
    ...mapGetters('auth', [
      'userIsLoggedIn',
      'userIsKeyWorker',
      'authIsReady',
      'user',
      'activeNurseryBranch',
      'activeNursery',
      'role',
    ]),
    ...mapGetters('core', ['configIsReady']),
    ...mapGetters('questionnaire', ['hasCurrentQuestionnaire']),
  },

  methods: {
    initWebsockets() {
      this.echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.VUE_APP_PUSHER_KEY,
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        encrypted: true,
        auth: {
          headers: {
            ApiKey: process.env.VUE_APP_BASE_API_KEY,
            Authorization: `Bearer ${this.user.token}`,
            Accept: 'application/json',
          },
        },
        authEndpoint: process.env.VUE_APP_BASE_API_URL + '/auth/broadcasting',
      })

      this.echo.reload = function (token) {
        this.disconnect()
        this.options.auth = {
          headers: {
            ApiKey: process.env.VUE_APP_BASE_API_KEY,
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        }
        this.connect()
      }

      // Join Channels
      this.joinChannels()
    },

    /**
     * Subscribe to WebSocket channels
     */
    joinChannels() {
      if (!this.user) {
        if (this.websocketChannel) {
          this.echo.leaveChannel(this.websocketChannel)
          this.websocketChannel = null
        }
        return
      }
      this.websocketChannel = `users.${this.user.id}`

      this.echo
        .private(this.websocketChannel)
        .listen(
          '.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
          (event) => {
            if (event.type == 'broadcast.message') {
              let unreadConversations =
                this.user.conversations.unread_conversations

              if (!unreadConversations.includes(event.sender.id)) {
                unreadConversations.push(event.sender.id)

                let updatedUser = Object.assign({}, this.user, {
                  conversations: {
                    unread_conversations: unreadConversations,
                  },
                })

                this.$store.commit('auth/SET_USER', updatedUser)
              }

              this.$bus.$emit('messages::new', event)
            }
          }
        )
    },

    handleNurseryBranchSwitch(branch) {
      if (branch) {
        this.routerViewKey = branch.id;
      }
    },
  },
}
</script>
