<template>

  <div>
    <div v-b-toggle="id">
    	<strong><slot name="title"></slot></strong>
    	<span class="float-right">
    		<ChevronUpIcon  v-if="visible" :aria-label="'Close ' + id + ' section '"/>
    		<ChevronDownIcon v-else :aria-label="'Open ' + id + ' section'"/>
       
    	</span>
    </div>
    <b-collapse :id="id" class="mt-3" v-model="visible">
      <slot name="content"></slot>
    </b-collapse>
    <hr />
  </div>

</template>

<script>
import { ChevronDownIcon, ChevronUpIcon } from '@vue-hero-icons/solid'

export default {

  name: 'SearchFilterSection',

  props: {
  	
  	id: { required: true, type: String },
  	show: { type: Boolean, default: false },

  },

  components: {

  	ChevronUpIcon,
  	ChevronDownIcon,

  },

  data() {

    return {
    	visible: this.show,
    }
  },
}
</script>

<style lang="scss" scoped>
  
</style>