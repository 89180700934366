<template>
  <SharedNavigation>
    <template #icon-items>
      <HeaderSearch
        @search="search"
      />

      <!-- Basket -->
      <BasketNavItem class="" />
      <!-- Favourites -->
      <FavouriteNavItem />
    </template>
  </SharedNavigation>
</template>
<script>
import SharedNavigation from '@/core/layouts/global-layout/components/user-navigation/SharedNavigation'
import BasketNavItem from '@/modules/basket/components/BasketNavItem'
import FavouriteNavItem from '@/core/layouts/global-layout/components/nav-items/FavouriteNavItem'
import HeaderSearch from '@/core/layouts/global-layout/components//nav-items/HeaderSearch'

export default {
  components: {
    SharedNavigation,
    BasketNavItem,
    FavouriteNavItem,
    HeaderSearch,
  },

  methods: {
    search(event) {
      this.$emit('search', event)
    },
  }
}
</script>