import basketApiService from '@/modules/basket/services/basket.api'

const addToBasket = (context, options)  => {
  return new Promise((resolve, reject) => {
    basketApiService.basket.add(options)
    .then(response => {
      context.commit('ADD_TO_BASKET', response.data)
      resolve(response)
    }).catch(error => {
      reject(error.response)
    })
  })
}

const removeFromBasket = (context, basketItem)  => {
  return new Promise((resolve, reject) => {
    basketApiService.basket.remove(basketItem.id)
    .then(response => {
      context.commit('REMOVE_FROM_BASKET', basketItem.id)
      resolve(response)
    }).catch(error => {
      reject(error.response)
    })
  })
}

const addChildToBasketItem = (context, options) => {
  return new Promise((resolve, reject) => {
    basketApiService.basket.addChildToBasketItem(options.basket_item_id, {
      child_id: options.child_id
    })
    .then(response => {
      context.commit('UPDATE_BASKET_ITEM', response.data)
      resolve(response)
    }).catch(error => {
      reject(error.response)
    })
  })
}

const addPaymentToBasketItem = (context, options) => {
  return new Promise((resolve, reject) => {
    basketApiService.basket.addPaymentToBasketItem(options.basket_item_id, {
      payment_type_id: options.payment_type_id,
      payment_method_id: options.payment_method_id,
    })
    .then(response => {
      context.commit('UPDATE_BASKET_ITEM', response.data)
      resolve(response)
    }).catch(error => {
      reject(error.response)
    })
  })
}

const addToWaitingList = (context, options) => {
  return new Promise( (resolve, reject) => {
    basketApiService.basket.getWaitingListInfo(options)
    .then(response => {
      context.commit('ADD_WAITING_LIST_ITEM', response.data)
      resolve(response)
    }).catch(error => {
      reject(error.response)
    })
  })
}

const submitWaitingList = (context, options) => {
  return new Promise( (resolve, reject) => {
    basketApiService.basket.submitWaitingList(options)
    .then(response => {
      context.commit('RESET_WAITING_LIST_ITEM')
      resolve(response)
    }).catch(error => {
      reject(error.response)
    })
  })
}

export default {
  addToBasket,
  removeFromBasket,
  addChildToBasketItem,
  addPaymentToBasketItem,
  addToWaitingList,
  submitWaitingList,
}