

<template>
  <div>
    <b-nav-item
      aria-label="Navigate to basket"
      class="nav-item-icon"
      @click="showBasket = !showBasket"
      @keyup.enter="showBasket = !showBasket"
    >
      <component
        :is="showBasket ? 'ShoppingBagIconSolid' : 'ShoppingBagIcon'"
        id="basket-nav-item"
        class="md-heroicon text-primary my-auto"
      />
      <span
        v-if="basketCount"
        class="badge"
      >{{ basketCount }}</span>
      <span class="sr-only"> {{ basketCount }}items in basket</span>
    </b-nav-item>

    <BasketPopover
      aria-label="displaying items in basket"
        
      target="basket-nav-item"
      :show="showBasket"
      @close="showBasket = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ShoppingBagIcon } from '@vue-hero-icons/outline'
import { ShoppingBagIcon as ShoppingBagIconSolid } from '@vue-hero-icons/solid'
import BasketPopover from '@/modules/basket/components/BasketPopover'
export default {

  name: 'BasketNavItem',

  components: {
  	ShoppingBagIcon,
  	ShoppingBagIconSolid,
  	BasketPopover,
  },

  data() {

    return {
    	showBasket: false,
    }
  },

  computed: {
  	...mapGetters('basket', ['basketCount'])
  },
}
</script>

<style lang="scss" scoped>
	.nav-item-icon {
		position: relative;
	  .badge {
	    position: absolute;
	    display: block;
	    top: 35px;
	    right: -5px;
	    border-radius: 50%;
	    width: 16px;
	    height: 16px;
	    background: $primary;
	    color: white;
	  }

	  #basket-nav-item:focus {
	  	border: none !important;
	  }
	}
	
</style>