<template>
  <ul class="items fw-300 flex-column">
    <li class="settings-link d-flex align-items-center collapse-link  py-3">
      <router-link :to="{ name: 'user-profile' }">
        Your Profile
      </router-link>
    </li>

    <!-- @NOTE: Removed as it causes more issues than it solves problems -->

    <!-- <b-collapse id="profile-list">

      <li v-if="!userIsKeyWorker" class="settings-link d-flex align-items-center ml-4">
        <router-link :to="{ name: 'staff-profile'}">
          Staff Profile
        </router-link>
      </li>
      
      <li class="settings-link d-flex align-items-center ml-4">
        <router-link :to="{ name: 'user-profile'}">
          Personal Info
        </router-link>
      </li>
      
      <li v-if="!userIsKeyWorker" class="settings-link d-flex align-items-center ml-4">
        <router-link :to="{ name: 'holidays-absences'}">
          Holidays/Absences
        </router-link>
      </li>
      
      <li v-if="!userIsKeyWorker" class="settings-link d-flex align-items-center ml-4">
        <router-link :to="{ name: 'qualifications-skills'}">
          Qualifications/Skills
        </router-link>
      </li>

      <slot name="profile-extra"></slot>
      
    </b-collapse> -->

    <!-- User role specific links go here -->
    <slot name="main-extra" />

    <li class="settings-link d-flex align-items-center  py-3">
      <router-link :to="{ name: 'account-settings' }">
        Settings
      </router-link>
    </li>

    <li
      v-if="showSwitchAccount"
      class="settings-link d-flex align-items-center  py-3"
    >
      <a
        href="#"
        @click.prevent="switchAccounts"
      >
        Switch to {{ userIsKeyWorker ? 'service' : 'parent/carer' }} account
      </a>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  LogoutIcon,
  CogIcon,
  SwitchHorizontalIcon,
  UserCircleIcon
} from '@vue-hero-icons/outline'

export default {
  components: {
    CogIcon,
    LogoutIcon,
    SwitchHorizontalIcon,
    UserCircleIcon,
  },

  computed: {
    ...mapGetters(
      'auth', [
        'userIsKeyWorker',
        'userIsNurseryAdmin',
        'userHasNurseryRole',
        'userHasKeyworkerRole',
        'userIsNurseryStaff',
      ]
    ),

    showSwitchAccount() {
      return (this.userIsKeyWorker && this.userHasNurseryRole)
        || ( (this.userIsNurseryAdmin || this.userIsNurseryStaff) && this.userHasKeyworkerRole)
    },
  },

  methods: {
    switchAccounts() {
      if (this.userIsKeyWorker) {
        this.$store.dispatch('auth/switchToServiceAccount')
        .then( () => this.$bus.emit('app::alert::success', 'Successfully switched to Service account.') )
      } else {
        this.$store.dispatch('auth/switchToCarerAccount')
        .then( () => this.$bus.emit('app::alert::success', 'Successfully switched to Parent/Carer account.') )
      }
    },

    logout () {
      this.$store.dispatch('auth/logout')
      .then(() => {
        this.$router.push({ name: 'home' })
      })
    },
  }
}
</script>

<style>

</style>