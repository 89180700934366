<template>
  <div>
    <div class="support-footer-1">
      <b-container>
        <div class="title">Need support or advice? Get in touch</div>
        <a :href="'tel:0800 861 1759'">
          <div class="info">0800 861 1759 (Mon - Fri 8:30am to 5:30pm)</div>
        </a>
      </b-container>
    </div>

    <div class="support-footer-2">
      <b-container>
        <div class="fw-600 fs-14">
          <b-row>
            <b-col cols="4">
              <ul>
                <li><a style="color: #304445;" href="https://www.caerus.scot/legal/terms" target="_blank" ><span class="sr-only">Link to open Terms and conditions in new tab</span> Terms &amp; Conditions</a></li>
                <li><a style="color: #304445;" href="https://www.caerus.scot/legal/privacy" target="_blank" ><span class="sr-only">Link to open Privacy Policy in new tab</span>Privacy</a></li>
              </ul>
            </b-col>
            <b-col cols="4">
              <ul>
                <li><a style="color: #304445;" href="https://www.caerus.scot/legal/gdpr" target="_blank" ><span class="sr-only">Link to open GDPR in new tab</span> GDPR</a></li>
                <li><a style="color: #304445;" href="https://www.caerus.scot/legal/cookies" target="_blank" ><span class="sr-only">Link to open Cookie Policy in new tab</span>Cookie Policy</a></li>
              </ul>
            </b-col>
            <b-col cols="4">
              <ul>
                <li><a style="color: #304445;" href="https://www.caerus.scot/legal/data" target="_blank" ><span class="sr-only">Link to open Data Processing in new tab</span>Data Procesing</a></li>
                <li><a style="color: #304445;" href="https://www.caerus.scot/legal/security" target="_blank" ><span class="sr-only">Link to open Security page in new tab</span>Security</a></li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: 'support-footer'
}
</script>