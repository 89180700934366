const setChildrenIds = (context) => {
  context.commit("SET_CHILDREN_IDS", localStorage.getItem("childrenIds"));
};
const setNurserySearchId = ({ commit }, id) => {
  commit("SET_NURSERY_SEARCH_ID", id);
};
export default {
  setChildrenIds,
  setNurserySearchId,
};
