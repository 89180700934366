
export default {
  SET_SELECTED_CHILD(state, child) {
    state.child = child
  },
  
  SET_MONTH(state, month) {
    state.month = month
  }, 
}
