<template>
  <div
    v-if="canShow"
    class="container d-flex flex-row-reverse"
  >
    <div
      v-click-outside="close"
      class="nbp__container"
    >
      <div
        class="nbp__selected-container d-flex align-items-center c-pointer"
        @click="toggle"
      >
        <span class="nbp__selected-text text-white fw-600 fs-14">{{ selected }}</span>
        <ChevronUpIcon
          class="nbp__selected-icon md-heroicon text-white mt-1 mr-1 ml-auto"
          :class="{ 'fcs-svg-down': isOpen }"
        />
      </div>
      <ul
        v-show="isOpen"
        class="nbp__list-items"
      >
        <li
          v-for="branch in branches"
          :key="branch.id"
          class="nbp__list-item d-flex align-items-center c-pointer"
          @click="switchToBranch(branch)"
        >
          <span
            class="nbp__list-item-text text-white fs-14 mr-3"
            :class="{ 'fw-600': branch.id == activeNurseryBranch.id }"
          >
            {{ branch.name }}
          </span>
          <CheckCircleIcon
            v-if="branch.id == activeNurseryBranch.id"
            class="nbp__list-item-icon md-heroicon text-white ml-auto"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CheckCircleIcon } from '@vue-hero-icons/outline'
import { ChevronUpIcon } from '@vue-hero-icons/solid'

export default {
  name: 'NurseryBranchPicker',

  components: {
    CheckCircleIcon,
    ChevronUpIcon,
  },

  data: () => ({
    branches: [],
    selected: null,
    isOpen: false
  }),

  computed: {
    ...mapGetters(
      'auth', [
        'user',
        'activeNursery',
        'activeNurseryBranch',
        'userIsLoggedIn',
        'userIsActivated',
        'userIsKeyWorker'
      ]
    ),

    canShow() {
      return this.userIsLoggedIn &&
        this.userIsActivated &&
        !this.userIsKeyWorker &&
        this.branches.length > 1
    },
  },

  watch: {
    user(user) {
      if (user) {
        this.init()
      }
    },

    isOpen() {
      this.selected = this.isOpen ? 'Select service' : this.activeNurseryBranch.name
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    init() {
      if (this.activeNursery) {
        this.branches = this.activeNursery.branches.data.filter(
          branch => !!branch.branch_details_complete
        ).map( branch => {
          return {
            id: branch.id,
            name: branch.name,
            branch: branch,
          }
        })
        this.selected = this.activeNurseryBranch.name
      }
    },

    switchToBranch(selected) {
      if (selected) {
        this.$store.commit('auth/SET_ACTIVE_NURSERY_BRANCH', selected.branch)
        this.$store.commit('auth/SET_ENABLE_MARKETPLACE', selected.branch.marketplace_enabled)
        this.$emit('switch', selected.branch)
      }

      this.close()
    },

    toggle() {
      this.isOpen ? this.close() : this.open()
    },

    open() {
      this.isOpen = true
    },

    close() {
      this.isOpen = false
    }
  },
}
</script>

<style lang="scss" scoped>
.nbp__container {
  position: fixed !important;
  bottom: 0px;
  min-width: 140px;
  background: #333F48;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  .nbp__selected-container {
    min-height: 24px;

    .nbp__selected-text {
      color: $white;
      padding: 0 10px;
    }
  }

  .nbp__list-items {
    list-style: none;
    margin: 0;
    padding: 0;

    .nbp__list-item {
      padding: 3px 10px;

      &:hover, .is-active {
        background: #1A262F;
      }

      .nbp__list-item-icon {
        width: 18px;
      }
    }
  }
}
</style>
