<template>
  <b-alert
    v-model="alert.show"
    :fade="true"
    :class="`position-fixed m-0 rounded-0 fixed-${position}`"
    :style="`z-index: ${zIndex};`"
    :variant="alert.variant"
    :dismissible="dismissible">
    {{ alert.message }}
  </b-alert>
</template>

<script>
export default {
  name: 'app-alert',
  props: {
    dismissible: {
      type: Boolean,
      default: true
    },
    position: {
      type: String,
      default: 'top'
    },
    zIndex: {
      type: Number,
      default: 2000
    }
  },
  data () {
    return {
      alert: {
        show: false,
        message: '',
        variant: 'success'
      }
    }
  },
  methods: {
    success(message) {
      this.show('success', message)
    },
    error(message) {
      this.show('danger', message)
    },
    warning(message) {
      this.show('warning', message)
    },
    show(variant, message) {
      this.alert.variant = variant
      this.alert.message = message
      this.alert.show = 5
    }
  },
  created () {
    this.$bus.on('app::alert::success', this.success)
    this.$bus.on('app::alert::warning', this.warning)
    this.$bus.on('app::alert::error', this.error)
  }
}
</script>
