<template>
  <div>
    <template>
      <li class="settings-link d-flex align-items-center py-3">
        <router-link :to="{ name: 'schedule'}">
          Schedule
        </router-link>
      </li>
    </template>
    
    <div>
      <li class="settings-link d-flex align-items-center py-3">
        <router-link :to="{ name: 'service-provider-information'}">
          Business information
        </router-link>
      </li>
    

      <SearchFilterSection
        id="service-list"
        class="collapse-link py-3"
      >
        <template #title>
          <span class="sr-only"> Service Profile </span>
          <span class="heading-black">Service Profile</span>
        </template>
        <template #content>
          <b-col>
            <li class="settings-link d-flex align-items-center mt-2 ml-4">
              <router-link :to="{ name: serviceProfileRouteName, params: { serviceId: activeService.id } }">
                {{ activeService.name }}
              </router-link>
            </li>
    
            <li class="settings-link d-flex align-items-center mt-2 ml-4">
              <a
                href="#"
                @click.prevent="$bvModal.show('newServiceModal')"
              >Add new service +</a>
            </li>
          </b-col>
        </template>
      </SearchFilterSection>
  
      <li class="settings-link d-flex align-items-center py-3">
        <router-link :to="{ name: 'service-subscriptions'}">
          Subscriptions
        </router-link>
      </li>
    
      <!-- <li class="settings-link d-flex align-items-center">
        <CurrencyPoundIcon class="md-heroicon text mr-2"/>
        <router-link :to="{ name: 'service-monthly-usage-statements'}">
          Monthly usage statements
        </router-link>
      </li> -->
    
      <li class="settings-link d-flex align-items-center py-3">
        <router-link :to="{ name: 'service-payment-collection-details'}">
          Payment collection details
        </router-link>
      </li>
    
      <li class="settings-link d-flex align-items-center py-3">
        <router-link :to="{ name: 'service-reviews'}">
          Reviews
        </router-link>
      </li>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import {
  OfficeBuildingIcon,
  ViewGridAddIcon,
  RefreshIcon,
  CurrencyPoundIcon,
  CreditCardIcon,
  ThumbUpIcon,
} from '@vue-hero-icons/outline'


import SearchFilterSection from '@/modules/results/components/SearchFilterSection'

export default {
  components: {
    OfficeBuildingIcon,
    ViewGridAddIcon,
    RefreshIcon,
    CurrencyPoundIcon,
    CreditCardIcon,
    ThumbUpIcon,
    SearchFilterSection,
  },

  data() {
    return {
      serviceProfileRoutes: [
        'service-details',
        'service-payment-and-cancellation',
        'service-opening-hours',
        'service-photo-gallery',
      ],
    }
  },

  computed: {
    ...mapGetters('auth', ['activeNurseryBranch']),

    isCurrentServiceProfileRoute() {
      return this.serviceProfileRoutes.includes(this.$route.name)
    },

    serviceProfileRouteName() {
      if (this.isCurrentServiceProfileRoute) {
        return this.$route.name
      }
      return 'service-details'
    },

    activeService() {
      return this.activeNurseryBranch ? this.activeNurseryBranch : null
    },
  }
}
</script>

<style>

</style>