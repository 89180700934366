import { allows } from '@/core/router/utils'

export default {
  name: 'global-mixin',

  methods: {

    msgBoxConfirm(text = 'Are you sure?', params = {}) {

      const defaultParams = {
        title: 'Decline',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'primary',
        okTitle: 'Ok',
        cancelTitle: 'Cancel',
        cancelVariant: 'outline-primary',
        modalClass: 'delete-modal',
        hideHeaderClose: true,
        centered: true,
      }

      params = { ...defaultParams, ...params }

      return new Promise((resolve, reject) => {
        this.$bvModal
        .msgBoxConfirm(text, params)
        .then((value) => {
          if (value) {
            return resolve()
          }
          return reject()
        }).catch( () => {
          return reject()
        })
      })

    },

    contactSupport(error = null) {
      let errorMsg = `An unknown error occurred, please contact ${
        process.env.VUE_APP_SUPPORT_EMAIL_ADDRESS
      } if the problem persists.`

      if(error && error.response && error.response.status === 401) {
        errorMsg = 'Session has expired. Please log in again.'
      }

      if (error && error.response && error.response.status === 422) {

        errorMsg = error.response.data.message? error.response.data.message : error.response.data.error.message

        if(error.response.data.errors && error.response.data.errors.children) {
          errorMsg = error.response.data.errors.children[0]
        }
        
        if (error.response.data.errors && error.response.data.errors.email_address) {
          errorMsg = error.response.data.errors.email_address[0]
        } 

        if (error.response.data.errors && error.response.data.errors.email) {
          errorMsg = error.response.data.errors.email[0]
        }

        if (error.response.data.errors && error.response.data.errors.contact_phone) {
          errorMsg = error.response.data.errors.contact_phone[0]
        }

        if (error.response.data.errors && error.response.data.errors.cs_number) {
          errorMsg = error.response.data.errors.cs_number[0]
        } 
      }

      if (error && error.response && error.response.status === 500) {
        errorMsg = error.response.data.message
      }
      
      if (error && error.response && error.response.status === 403) {
        errorMsg = error.response.data.hasOwnProperty('message') ? error.response.data.message : error.response.data.error.message
      }

      if (error && error.data && error.data.error.status_code === 401) {
        error = error.data.error.message
      }

      if (error && error.response && error.response.status === 500) {
        errorMsg = error.response.data.message
      }

      if (error && error.response && error.response.status === 409) {
        errorMsg = error.response.data.error.message
      }

      if (error && error.response && error.response.data.code === 4040) {
        errorMsg = error.response.data.message
      }

      if (error && error.data) {
        errorMsg = error.data.error.message
      }

      if (error && typeof error === 'string') {
        errorMsg =  error
      }

      if (errorMsg) {
        this.$bus.emit('app::alert::error', errorMsg)
      }
    },

    allows: allows,
  },
}
