import http from '@/core/services/drivers/app-http-driver'

export default {

	modal() {
		return http({ url: '/questionnaire/modal', method: 'get' })
	},

	get(questionnaireId) {
		return http({ url: `/questionnaire/${questionnaireId}`, method: 'get' })
	},

	answer(questionnaireId, questionId, data) {
		return http({ url: `/questionnaire/${questionnaireId}/question/${questionId}/answer`, method: 'post', data: data })
	},

	skip(questionnaireId, questionId, data) {
		return http({ url: `/questionnaire/${questionnaireId}/question/${questionId}/skip`, method: 'post', data: data })
	},

}