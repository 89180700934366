<template>
  <validated-b-form-group
    :name="fieldName" 
    :label="label" 
    class="app-input"
    :class="{optional: isOptional}"
    :server-errors="serverErrors[fieldName]"
  >
    <b-form-row>
      <b-col cols="4">
        <img
          :src="imageUrl"
          :alt="label"
          class="rounded-circle"
        >
      </b-col>
      <b-col cols="8">
        <span class="input-group-btn">
          <b-form-file
            ref="logoImageUpload"
            v-model="form[fieldName]"
            style="display: none"
            @change="onFileChange"
          />
          <b-button
            variant="outline-primary"
            class="btn mt-4"
            :disabled="formLock"
            @click="$refs.logoImageUpload.$refs.input.click()"
          >
            {{ hasImage ? 'Update' : 'Add' }}
          </b-button>
          <b-button
            v-if="hasImage"
            variant="outline-primary"
            class="btn ml-2 mt-4"
            :disabled="formLock"
            @click="removeFile"
          >
            Remove
          </b-button>
        </span>
      </b-col>
    </b-form-row>
  </validated-b-form-group>
</template>

<script>
import FieldMixin from '@/core/mixins/field-mixin'
import FileValidationMixin from '@/core/mixins/file-validation-mixin'

const DEFAULT_LOGO = '/default-logo.svg'
const DEFAULT_AVATAR = '/default-avatar.svg'

export default {
	name: 'LogoField',

	mixins: [FieldMixin, FileValidationMixin],

	props: {
		fieldName: {
			required: true,
			type: String,
		},
		url: {
			required: false,
			type: String,
		},
		isAvatar: {
			default: false,
			type: Boolean,
		},
		isOptional: {
			default: true,
			type: Boolean,
		},
	},

	data() {
		return {
			imageUrl: this.url ? this.url : (this.isAvatar ? DEFAULT_AVATAR : DEFAULT_LOGO),
		}
	},

	computed: {
		hasImage() {
			return this.imageUrl != (this.isAvatar ? DEFAULT_AVATAR : DEFAULT_LOGO)
		},
		label() {
			return this.isAvatar ? 'Avatar' : 'Logo'
		},
	},

	methods: {
		onFileChange(e) {
			const file = e.target.files[0]

			if (!this.validateFile(file)) {
				e.preventDefault()
				return
			}

			this.imageUrl = URL.createObjectURL(file)
		},

		removeFile() {
			this.imageUrl = this.isAvatar ? DEFAULT_AVATAR : DEFAULT_LOGO
			this.form[this.fieldName] = null
		},
	},
}
</script>

<style lang="scss" scoped>
	.rounded-circle {
		width: 85px;
		height: 85px;
		object-fit: cover;
	}
</style>