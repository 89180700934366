import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from '@/modules/auth/store'
import core from '@/core/store/core'
import results from '@/modules/results/store'
import journal from '@/modules/journal/store'
import questionnaire from '@/modules/questionnaire/store'
import bookings from '@/modules/bookings/store'
import basket from '@/modules/basket/store'
import team from '@/modules/team/store'
import children from '@/modules/children/store'

const debug = process.env.NODE_ENV !== 'production'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    core,
    results,
    journal,
    questionnaire,
    bookings,
    basket,
    team,
    children,
  },
  plugins: [
    createPersistedState({
      key: process.env.VUE_APP_VUEX_PERSISTENCE_KEY,
      paths: [
        'auth.user',
        'auth.currentJWT',
        'auth.activeNursery',
        'auth.activeNurseryBranch',
        'basket.basket',
        'basket.basketUID',
        'basket.waitingListItem'
      ],
    })
  ]
})
