import http from '@/core/services/drivers/app-http-driver'

export default {
  update(nurseryId, data = {}, headers = {}) {
    return http({ url: `/nursery/${nurseryId}`, method: 'post', data: data, headers: { 'Content-Type': 'multipart/form-data' } })
  },
  updateLite(nurseryId, branchId, data = {}) {
    return http({ url: `/nursery/${nurseryId}/branch/${branchId}/update/lite`, method: 'post', data: data })
  },
  stripe: {
    create(nurseryId, branchId, data = {}) {
      return http({ url: `/nursery/${nurseryId}/connected-accounts/${branchId}/create`, method: 'post', data: data })
    },
    connect(nurseryId, branchId, data = {}) {
      return http({ url: `/nursery/${nurseryId}/connected-accounts/${branchId}/connect`, method: 'post', data: data })
    }
  },
  onboarding: {
    stepOne(data = {}) {
      return http({ url: '/nursery/onboarding/step-1', method: 'post', data: data, headers: { 'Content-Type': 'multipart/form-data' } })
    },
    stepTwo(data = {}) {
      return http({ url: '/nursery/onboarding/step-2', method: 'post', data: data })
    },
  },
  branch: {
    index(nurseryId, params = {}) {
      return http({ url: `/nursery/${nurseryId}/branch`, method: 'get', params: params })
    },
    create(nurseryId, data = {}) {
      return http({ url: `/nursery/${nurseryId}/branch`, method: 'post', data: data, headers: { 'Content-Type': 'multipart/form-data' } })
    },
    show(nurseryId, branchId, params = {}) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/show`, method: 'get', params: params })
    },
    update(nurseryId, branchId, data = {}) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/update`, method: 'post', data: data })
    },
    branchUpdate(nurseryId, branchId, data = {}) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/update`, method: 'post', data: data })
    },
    upload(nurseryId, branchId, data = {}) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/upload`, method: 'post', data: data, headers: { 'Content-Type': 'multipart/form-data' } })
    },
    removeUpload(nurseryId, branchId, data = {}) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/remove-upload`, method: 'post', data: data })
    },
    setAsCover(nurseryId, branchId, data = {}) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/set-as-cover`, method: 'post', data: data })
    },
    setDefault(nurseryId, branchId, data = {}) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/set-default`, method: 'post', data: data })
    },
    paymentAndCancellationUpdate(nurseryId, branchId, data = {}) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/payment-and-cancellation/update`, method: 'post', data: data })
    },
    getProfileFromBranch(nurseryId, branchId, params = {}) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/carer-profile-check`, method: 'get', params: params })
    },
    updateConnectionRequirement(nurseryId, branchId, data = {}) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/connection-requirement`, method: 'post', data: data })
    },
    closure: {
      index(nurseryId, branchId, params = {}) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/closing-days`, method: 'get', params: params })
      },
      create(nurseryId, branchId, data = {}) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/closing-days/create`, method: 'post', data: data })
      },
      confirm(nurseryId, branchId, closureId) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/closing-days/confirm`, method: 'post', data: { nursery_branch_working_year_id: closureId } })
      },
      remove(nurseryId, branchId, data = {}) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/closing-days/remove`, method: 'post', data: data })
      },
    },
    openingTimes: {
      index(nurseryId, branchId, params = {}) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/opening-times`, method: 'get', params: params })
      },
      show(nurseryId, branchId, day, params = {}) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/opening-times/${day}`, method: 'get', params: params })
      },
      update(nurseryId, branchId, data = {}) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/opening-times/update`, method: 'post', data: data })
      },
    },
    bookingPolicy: {
      show(nurseryId, branchId, params = {}) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/cancellation-policy/show`, method: 'get', params: params })
      },
      update(nurseryId, branchId, data = {}) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/cancellation-policy/update`, method: 'post', data: data })
      },
    }
  },
  keyworker: {
    update(data = {}) {
      return http({ url: `/account/carer/update`, method: 'put', data: data })
    },
    getChildren(params = {}) {
      return http({ url: `/account/carer/child`, method: 'get', params: params })
    },
    updateChild(childId, data = {}) {
      return http({ url: `/account/carer/child/${childId}`, method: 'put', data: data })
    },
    createChild(data = {}) {
      return http({ url: '/account/carer/child', method: 'post', data: data })
    },
    account(nurseryId) {
      return http({ url: `/account/marketplace/nursery/${nurseryId}/account`, method: 'get' })
    },
    connection(params = {}) {
      return http({ url: `/account/marketplace/carer-nursery-connection`, method: 'get', params: params })
    },
    patch(nurseryConnectionID, data = {}) {
      return http({ url: `/account/marketplace/carer-nursery-connection/${nurseryConnectionID}`, method: 'patch', data: data })
    },
    getNurseryBranches(){
      return http({url: 'carer/nurseryBranch', method: 'GET'})
    }
  },
  staffProfile: {
    index(nurseryId, branchId, params = {}) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/staff-profile`, method: 'get', params: params })
    },
    show(nurseryId, branchId, profileId, params = {}) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/staff-profile/${profileId}`, method: 'get', params: params })
    },
    update(nurseryId, branchId, profileId, data = {}) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/staff-profile/${profileId}`, method: 'post', data: data, headers: { 'Content-Type': 'multipart/form-data' } })
    },
    inTraining (nurseryId, branchId, profileId) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/staff-profile/${profileId}/in-training`, method: `post`})
    },
    getInTraining (nurseryId, branchId, profileId) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/staff-profile/${profileId}/in-training`, method: `get`})
    },
    betaConfirmation(nurseryId, branchId, profileId) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/staff-profile/${profileId}/beta-confirm`, method: `get`})
    },
    
    hours: {
      index(nurseryId, branchId, params = {}) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/staff-hours`, method: 'get', params: params })
      },
      export(nurseryId, branchId, params = {}) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/staff-hours/export`, method: 'get', params: params })
      },
    },
    absences: {
      index(nurseryId, branchId, params = {}) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/staff-absences`, method: 'get', params: params })
      },
      show(nurseryId, branchId, profileId, data = {}) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/staff-profile/${profileId}/absences`, method: 'get', data: data })
      },
      update(nurseryId, branchId, profileId, data = {}) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/staff-profile/${profileId}/absences`, method: 'post', data: data })
      },
      delete(nurseryId, branchId, profileId, absenceId){
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/staff-profile/${profileId}/absences/${absenceId}`, method: 'delete' })
      }
    },
    qualifications: {
      index(nurseryId, branchId, profileId) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/staff-profile/${profileId}/qualifications`, method: 'get' })
      },
      create(nurseryId, branchId, profileId, data = {}) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/staff-profile/${profileId}/qualifications`, method: 'post', data: data, headers: { 'Content-Type': 'multipart/form-data' } })
      },
      delete(nurseryId, branchId, profileId, qualification) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/staff-profile/${profileId}/qualifications/${qualification}`, method: 'delete', data: {} })
      },
      view(nurseryId, branchId, profileId, qualification) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/staff-profile/${profileId}/qualifications/${qualification}`, method: 'get', responseType: 'blob' })
      },
    },
    workSchedule: {
      show(nurseryId, branchId, profileId) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/staff-profile/${profileId}/available-work-schedule`, method: 'get' })
      },
      update(nurseryId, branchId, profileId, data = {}) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/staff-profile/${profileId}/available-work-schedule`, method: 'post', data: data })
      },
      current(nurseryId, branchId) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/current-user-work-schedule`, method: 'get' })
      },
    }
  },
  reports: {
    index(nurseryId, branchId, params = {}) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/report`, method: 'get', params: params })
    },
    getChildren(nurseryId, branchId, params = {}) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/children-basic-info`, method: 'get', params: params })
    },
    accident: {
      index(nurseryId, branchId, params = {}) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/accident-report`, method: 'get', params: params })
      },
      create(nurseryId, branchId, data = {}) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/accident-report`, method: 'post', data: data, headers: { 'Content-Type': 'multipart/form-data' } })
      },
      update(nurseryId, branchId, reportId, data = {}) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/accident-report/${reportId}`, method: 'post', data: data, headers: { 'Content-Type': 'multipart/form-data' } })
      },
      show(nurseryId, branchId, reportId, params = {}) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/accident-report/${reportId}`, method: 'get', params: params })
      },
      delete(nurseryId, branchId, reportId, params = {}) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/accident-report/${reportId}`, method: 'delete', params: params })
      },
      sign(nurseryId, branchId, reportId, params = {}) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/accident-report/${reportId}/sign`, method: 'post', params: params })
      },
      downloadFile(nurseryId, branchId, fileId, params = {}) {
        return http({ url: `/nursery/${nurseryId}/branch/${branchId}/accident-report/document/${fileId}`, method: 'get', params: params, responseType: 'blob' })
      },
    }
  },
  search:{
    index(nurseryId, branchId, term) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/search/entity?search=${term}`, method: 'get'})
    },
  },
  requests: {
    index( nurseryId, branchId, params = {}) {
      return http({ url: `/nursery/${nurseryId}/marketplace/nurseryBranch/${branchId}/enquiry`, method: 'get', params: params })
    },
    accept(nurseryId, branchId, enquiryId, data = {}) {
      return http({ url: `/nursery/${nurseryId}/marketplace/nurseryBranch/${branchId}/enquiry/${enquiryId}/accept`, method: 'post', data: data })
    },
    decline(nurseryId, branchId, enquiryId, data = {}) {
      return http({ url: `/nursery/${nurseryId}/marketplace/nurseryBranch/${branchId}/enquiry/${enquiryId}/decline`, method: 'post', data: data })
    },
  },
  getLocalAuthorities(params = {}) {
    return http({ url: '/nursery/local-authorities', method: 'get', params: params })
  },
  getAdditionalSupportServices(params = {}) {
    return http({ url: '/nursery/additional-support-services', method: 'get', params: params })
  },
  getStaffCategories(params = {}) {
    return http({ url: '/nursery/staff-categories', method: 'get', params: params })
  },
  getFundingModels(params = {}) {
    return http({ url: '/nursery/funding-models', method: 'get', params: params })
  },
  getStaffEmploymentTypes(params = {}) {
    return http.get('/nursery/staff-employment-types', params)
  },
  getRoles() {
    return http({ url: `/users/roles`, method: 'get' })
  },
  staffInvite(nurseryId, data = {}) {
    return http({ url: `/nursery/${nurseryId}/staff-invite`, method: 'post', data: data })
  },
  staffReInvite(nurseryId, branchId, profileId, data = {}) {
    return http({ url: `/nursery/${nurseryId}/branch/${branchId}/staff-profile/${profileId}/resend-invite`, method: 'post', data: data })
  },
  staffDelete(nurseryId, branchId, profileId) {
    return http({ url: `/nursery/${nurseryId}/branch/${branchId}/staff-profile/${profileId}`, method: 'delete' })
  },
  staffSuspend(nurseryId, branchId, profileId, data = {}) {
    return http({ url: `/nursery/${nurseryId}/branch/${branchId}/staff-profile/${profileId}/suspension-status`, method: 'post', data: data })
  },
  upgrade(nurseryId, data = {}) {
    return http({ url: `/nursery/${nurseryId}/upgrade`, method: 'patch', data: data })
  },
  getImportedServiceDetails(csNumber) {
    return http({ url: `nursery/imported-services`, method: 'get', params: { cs_number: csNumber } })
  },
}
