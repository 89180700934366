import authApiService from '@/modules/auth/services/auth.api'

const login = (context, options)  => {
  return new Promise((resolve, reject) => {
    authApiService.login({ email: options.email, password: options.password, client_id: options.client_id })
    .then(response => {

      if (response.data.nurseries.data.length == 1 && response.data.nurseries.data.find( nursery => nursery.is_suspended)) {
        reject('This provider has been suspended. Please contact your Administrator!')
        return
      }

      if (response.data.role.slug === 'carer') {
        context.dispatch('logout')
        reject('You are not allowed to access this portal. Please contact support.')
        return
      }
      context.commit('SET_USER', response.data)
      context.commit('SET_JWT', response.data.token)
      resolve(response)
    }).catch(error => {
      reject(error.response)
    })
  })
}


let isRefreshing = false
const refresh = (context, options) => {
  //get the ttl for the current token
  const [header, payload, signature] = context.getters.jwt.split('.')
  const data = JSON.parse(atob(payload))
  const expirationTimeInSeconds = data.exp
  const currentTimeInSeconds = Math.floor(Date.now() / 1000)
  const ttlInSeconds = expirationTimeInSeconds - currentTimeInSeconds

  //if we aren't refreshing already and the ttl is less than 10 minutes
  if(!isRefreshing && ttlInSeconds < 10 * 60) {
    isRefreshing = true
    
    return authApiService.refresh()
    .then((response) => {
      context.commit('SET_JWT', response.data.token)
    })
    .finally(() => {
      isRefreshing = false
    })
  }
}

const autoLogin = (context, user)  => {
  localStorage.removeItem('activeBranch');
  context.commit('SET_USER', user)
  context.commit('SET_JWT', user.token)
}

const logout = (context, options)  => {
  return new Promise((resolve, reject) => {
    authApiService.logout().then(response => {
      context.commit('SET_JWT', '')
      context.commit('SET_USER', null)
      context.commit('SET_ACTIVE_NURSERY', null)
      context.commit('SET_ACTIVE_NURSERY_BRANCH', null)
      context.commit('SET_USER_HAS_COMPLETED_MIGRATION', false)
      context.commit('SET_ROLE', null)
      context.commit('SET_JUMP', false)
      context.commit('SET_ENABLE_MARKETPLACE', null)
      resolve(response)
    }).catch(error => {
      context.commit('SET_JWT', '')
      context.commit('SET_USER', null)
      context.commit('SET_ACTIVE_NURSERY', null)
      context.commit('SET_ACTIVE_NURSERY_BRANCH', null)
      context.commit('SET_USER_HAS_COMPLETED_MIGRATION', false)
      context.commit('SET_ROLE', null)
      context.commit('SET_JUMP', false)
      context.commit('SET_ENABLE_MARKETPLACE', null)
      resolve(error)
    })
  })
}

const autoLogout = (context, user)  => {
  context.commit('SET_JWT', '')
  context.commit('SET_USER', null)
  context.commit('SET_ACTIVE_NURSERY', null)
  context.commit('SET_ACTIVE_NURSERY_BRANCH', null)
  context.commit('SET_ROLE', null)
}

const user = (context, options)  => {
  return new Promise((resolve, reject) => {
    authApiService.user()
    .then(response => {
      context.commit('SET_USER', response.data)
      context.commit('SET_JWT', response.data.token)
      // context.commit('SET_OPENING_TIMES', response.data.nurseries.data[0]? response.data.nurseries.data[0].branches? response.data.nurseries.data[0].branches.data[0].opening_times.data  : null : null )
      resolve(response)
    }).catch(error => {
      reject(error.response)
    })
  })
}

const authCheck = (context, options)  => {
  return new Promise((resolve, reject) => {
    if (context.getters.userIsLoggedIn) {
      authApiService.user({ client_id: 3 })
        .then(response => {
          context.commit('SET_USER', response.data)
          context.commit('SET_JWT', response.data.token)
          context.commit('SET_AUTH_IS_READY', true)
          // context.commit('SET_OPENING_TIMES', response.data.nurseries.data[0]? response.data.nurseries.data[0].branches? response.data.nurseries.data[0].branches.data[0].opening_times.data  : null : null )
          resolve(response.data)
        }).catch(error => {
          context.commit('SET_AUTH_IS_READY', true)
          reject(error)
        })
    } else {
      context.commit('SET_AUTH_IS_READY', true)
      resolve()
    }
  })
}

const updateProfile = (context, options)  => {
  return new Promise((resolve, reject) => {
    authApiService.updateProfile(options)
    .then(response => {
      context.dispatch('user')
      resolve(response)
    }).catch(error => {
      reject(error.response)
    })
  })
}

const switchToCarerAccount = (context)  => {
  return new Promise((resolve, reject) => {
    authApiService.toggleCarerAccount()
    .then(response => {
      context.commit('SET_USER', response.data)
      context.commit('SET_JWT', response.data.token)
      resolve(response)
    }).catch(error => {
      reject(error.response)
    })
  })
}

const switchToServiceAccount = (context)  => {
  return new Promise((resolve, reject) => {
    authApiService.toggleServiceAccount()
    .then(response => {
      context.commit('SET_USER', response.data)
      context.commit('SET_JWT', response.data.token)
      resolve(response)
    }).catch(error => {
      reject(error.response)
    })
  })
}

const subscriptionToEmailUpdate = (context)  => {
  return new Promise((resolve, reject) => {
    authApiService.toggleUserSubscriptionToServiceEmailUpdates()
    .then(response => {
      context.dispatch('user')
      resolve(response)
    }).catch(error => {
      reject(error.response)
    })
  })
}

export default {
  login,
  refresh,
  autoLogin,
  logout,
  autoLogout,
  user,
  authCheck,
  updateProfile,
  switchToCarerAccount,
  switchToServiceAccount,
  subscriptionToEmailUpdate,
}
