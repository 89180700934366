<template>
  <div class="d-block">
    <SearchFilterSection
      id="children"
      class="collapse-link py-3"
    >
      <template #title>
        <span class="sr-only"> Children </span>
        <span class="heading-black">Children</span>
      </template>
      <template #content>
        <b-col>
          <li
            v-for="child in userChildren" 
            :key="child.id"
            class="settings-link d-flex align-items-center mt-2 ml-4 py-2"
          >
            <router-link :to="{ name: childProfileRouteName, params: { childId: child.id } }">
              {{ child.full_name }}
            </router-link>
          </li>
    
          <li class="settings-link d-flex align-items-center mt-2 ml-4 py-2">                    
            <a
              href="#"
              @click.prevent="$bvModal.show(newChildModalId)"
            >Add child +</a>
          </li>
    
          <li class="settings-link d-flex align-items-center py-2">
            <UserAddIcon class="md-heroicon text mr-2" />
            <router-link :to="{ name: 'keyworker-connections' }">
              Connections
            </router-link>
          </li>
        </b-col>
      </template>
    </SearchFilterSection>
  </div>
</template>

<script>
import { 
  CreditCardIcon, 
  CurrencyPoundIcon,
  UserAddIcon, 
  UserGroupIcon 
} from '@vue-hero-icons/outline'
import { mapGetters } from 'vuex'
import SharedLinks from '@/modules/settings/components/user-links/SharedLinks'
import SearchFilterSection from '@/modules/results/components/SearchFilterSection'

export default {
  components: { 
    UserGroupIcon, 
    UserAddIcon, 
    CreditCardIcon, 
    CurrencyPoundIcon,

   SearchFilterSection,
  },

  data() {
    return {
      childProfileRoutes: [
        'keyworker-child-profile',
        'keyworker-child-services',
        'keyworker-child-development-diary',
      ],
      newChildModalId: 'NewChildModal',
    };
  },

  computed: {
    ...mapGetters('auth', ['activeNursery', 'userChildren']),

    isCurrentChildProfileRoute() {
      return this.childProfileRoutes.includes(this.$route.name);
    },
    
    childProfileRouteName() {
      if (this.isCurrentChildProfileRoute) {
        return this.$route.name;
      }
      return 'keyworker-child-profile';
    },
  },

}
</script>
