import http from '@/core/services/drivers/app-http-driver'

export default {
  index(nurseryId, branchId, month) {
    return http.get(`/nursery/${nurseryId}/branch/${branchId}/rota/month/${month}`)
  },
  mySchedule(nurseryId, branchId, month) {
    return http.get(`/nursery/${nurseryId}/branch/${branchId}/rota/my-schedule/${month}`)
  },
  show(nurseryId, branchId, rotaId) {
    return http.get(`/nursery/${nurseryId}/branch/${branchId}/rota/${rotaId}`)
  },
  create(nurseryId, branchId, params) {
    return http.post(`/nursery/${nurseryId}/branch/${branchId}/rota`, params)
  },
  getSingle(nurseryId, branchId, day) {
    return http.get(`/nursery/${nurseryId}/branch/${branchId}/rota/day/${day}`)
  },
  getWeek(nurseryId, branchId, year, week) {
    return http.get(`/nursery/${nurseryId}/branch/${branchId}/rota/year/${year}/week/${week}`)
  },
  getPreview(nurseryId, branchId, rotaId) {
    return http.get(`/nursery/${nurseryId}/branch/${branchId}/rota/${rotaId}/preview`)
  },
  loadStaff(nurseryId, branchId, rotaId) {
    return http.get(`/nursery/${nurseryId}/branch/${branchId}/rota/${rotaId}/staff`)
  },
  loadAvailableSessions(nurseryId, branchId, rotaId, params = {}) {
    return http.get(`/nursery/${nurseryId}/branch/${branchId}/rota/${rotaId}/staff-reservations`, { params: params })
  },
  saveStaffReservations(nurseryId, branchId, rotaId, data, params = {}) {
    return http.post(`/nursery/${nurseryId}/branch/${branchId}/rota/${rotaId}/staff-reservations`, data, { params: params })
  },
  loadOutwithSessions(nurseryId, branchId, rotaId) {
    return http.get(`/nursery/${nurseryId}/branch/${branchId}/rota/${rotaId}/staff-outwith-reservations`)
  },
  requestAvailability(nurseryId, branchId, data) {
    return http.post(`/nursery/${nurseryId}/branch/${branchId}/staff-availabilities/request`, data)
  },
  getAvailability(nurseryId, branchId, date, params = {}) {
    return http.get(`/nursery/${nurseryId}/branch/${branchId}/staff-availabilities/${date}`, { params: params })
  },
  getCurUserAvailability(nurseryId, branchId, params = {}) {
    return http.get(`/nursery/${nurseryId}/branch/${branchId}/current-user-staff-availabilities`, { params: params })
  },
  confirmAvailability(nurseryId, branchId, data) {
    return http.post(`/nursery/${nurseryId}/branch/${branchId}/current-user-staff-availabilities`, data)
  },
  getStaffCategories(params = {}) {
    return http.get('/nursery/staff-categories', params)
  },
  getStaffEmploymentTypes(params = {}) {
    return http.get('/nursery/staff-employment-types', params)
  },
  publish(nurseryId, branchId, rotaId, data) {
    return http.patch(`/nursery/${nurseryId}/branch/${branchId}/rota/${rotaId}/publish`, data)
  },
  update(nurseryId, branchId, rotaId, data) {
    return http.post(`/nursery/${nurseryId}/branch/${branchId}/rota/${rotaId}/update`, data)
  },
  sendConfirmationReminder(nurseryId, branchId, rotaId, data) {
    return http.patch(`/nursery/${nurseryId}/branch/${branchId}/rota/${rotaId}/confirmation-reminder`, data)
  },
  confirm(nurseryId, branchId, rotaId, rotaStaffId, data) {
    return http.patch(`/nursery/${nurseryId}/branch/${branchId}/rota/${rotaId}/staff/${rotaStaffId}/confirm`, data)
  },
  createFromTemplate(nurseryId, branchId, data) {
    return http.post(`/nursery/${nurseryId}/branch/${branchId}/current-user-staff-availabilities`, data)
  },
  deleteRotaTemplate(nurseryId, branchId, id) {
    return http.delete(`nursery/${nurseryId}/branch/${branchId}/rota/template/${id}`)
  },
  getTemplates(nurseryId, branchId) {
    return http.get(`nursery/${nurseryId}/branch/${branchId}/rota/template`)
  },
  showTemplate(nurseryId, branchId, id) {
    return http.get(`/nursery/${nurseryId}/branch/${branchId}/rota/template/${id}`)
  },
  importTemplate(nurseryId, branchId, id, start_date, end_date) {
    return http.get(`/nursery/${nurseryId}/branch/${branchId}/rota/${id}/import/${start_date}/${end_date}`)
  },
  saveTemplateImport(nurseryId, branchId, data) {
    return http.post(`/nursery/${nurseryId}/branch/${branchId}/rota`, data)
  },
  sessionRegistryForRoomAndDay (nurseryId, branchId, params = {}) {
    return http({ url: `/nursery/${nurseryId}/branch/${branchId}/session-registry`, method: 'get', params: params })
  },
}
