<template>
	<validated-b-form-group 
    :label="question.question" 
    class="app-input" 
  >
    <b-form-textarea
      v-model.trim.lazy="currentQuestionAnswer.answer_text"
      placeholder="Enter free text..."
    />
  </validated-b-form-group>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
	name: 'FreeText',

	props: {
		question: {
			required: true,
			type: Object,
		},
	},

	computed: {
		...mapGetters('questionnaire', [
		  'currentQuestionAnswer',
		]),
	},
}
</script>