import http from '@/core/services/drivers/app-http-driver'
export default {
  learningJournal:{
    create(data = {}) {
      return http({url: '/learning-journal/learning-journal', method: 'POST', data: data})
    },
    previous(params = {}) {
      return http({url: '/learning-journal/learning-journal/previous', method: 'GET', params: params})
    },
    show(learningJournalId) {
      return http({url: `/learning-journal/learning-journal/${learningJournalId}`, method: 'GET'})
    },
    update (data,learningJournalId) {
      return http({ url: `/learning-journal/learning-journal/${learningJournalId}`, method: 'POST', data: data })
    },
    delete (learningJournalId) {
      return http({ url: `/learning-journal/learning-journal/${learningJournalId}`, method: 'DELETE' })
    },
    publish(learningJournalId) {
      return http({url: `/learning-journal/learning-journal/${learningJournalId}/publish`, method: 'POST'})
    },
    drafts(params) {
      return http({url: `/learning-journal/learning-journal/drafts`, method: 'GET', params:params})
    },
    learningOutcomes(learningJournalId) {
      return http({url: `/learning-journal/learning-journal/${learningJournalId}/learning-outcomes`, method: 'GET'})
    },
    addLearningOutcome(learningJournalId, params) {
      return http({url: `/learning-journal/learning-journal/${learningJournalId}/learning-outcomes`, method: 'POST', data: params})
    },
    removeLearningOutcome(learningJouralId, learningOutcomeId) {
      return http({url: `/learning-journal/learning-journal/${learningJouralId}/learning-outcomes`, method: 'DELETE', data: learningOutcomeId})
    },
    progressionPathways(learningJournalId) {
      return http({url: `/learning-journal/learning-journal/${learningJournalId}/progression-pathways`, method: 'GET'})
    },
    addProgressionPathway(learningJournalId, params) {
      return http({url: `/learning-journal/learning-journal/${learningJournalId}/progression-pathways`, method: 'POST', data: params})
    },
    removeProgressionPathway(learningJournalId, progressionPathwayId) {
      return http({url: `/learning-journal/learning-journal/${learningJournalId}/progression-pathways`, method: 'DELETE', data: progressionPathwayId})
    },
    exports(params) {
      return http({url: `/learning-journal/learning-journal/exports`, method: 'GET', params:params})
    },
  },
  feed:{ 
    index(params = {}) {
      return http({url: '/learning-journal/feed', method: 'GET', params: params})
    },
    mute(params = {}) {
      return http({url: '/learning-journal/feed/mute', method: 'POST', params: params})
    },
    unmute(params = {}) {
      return http({url: '/learning-journal/feed/unmute', method: 'POST', params: params})
    },
    
  },
  flag:{
    create (data = {}) {
      return http({ url: `/learning-journal/flag`, method: 'POST', data: data })
    },
    delete (data = {}) {
      return http({ url: `/learning-journal/flag`, method: 'DELETE', data: data })
    },
  },
  diary:{
    show (diaryId) {
      return http({ url: `/learning-journal/diary/${diaryId}`, method: 'GET' })
    },
    create (data = {}) {
      return http({ url: '/learning-journal/diary', method: 'POST', data: data })
    },
    update (diaryId,data) {
      return http({ url: `/learning-journal/diary/${diaryId}`, method: 'POST', data: data })
    },
    delete (diaryId) {
      return http({ url: `/learning-journal/diary/${diaryId}`, method: 'DELETE' })
    },
  },
  milestone:{
    show (milestoneId) {
      return http({ url: `/learning-journal/milestone/${milestoneId}`, method: 'GET' })
    },
    create (data = {}) {
      return http({ url: '/learning-journal/milestone', method: 'POST', data: data })
    },
    update (milestoneId,data) {
      return http({ url: `/learning-journal/milestone/${milestoneId}`, method: 'POST', data: data })
    },
    delete (milestoneId) {
      return http({ url: `/learning-journal/milestone/${milestoneId}`, method: 'DELETE' })
    },
  },
  groupObservation:{
    show (groupObservationId) {
      return http({ url: `/learning-journal/group-observation/${groupObservationId}`, method: 'GET' })
    },
    create (data = {}) {
      return http({ url: '/learning-journal/group-observation', method: 'POST', data: data })
    },
    update (groupObservationId,data) {
      return http({ url: `/learning-journal/group-observation/${groupObservationId}`, method: 'POST', data: data })
    },
    delete (groupObservationId) {
      return http({ url: `/learning-journal/group-observation/${groupObservationId}`, method: 'DELETE' })
    },
  },
  homeObservation:{
    show (homeObservationId) {
      return http({ url: `/learning-journal/home-observation/${homeObservationId}`, method: 'GET' })
    },
    create (data = {}) {
      return http({ url: '/learning-journal/home-observation', method: 'POST', data: data })
    },
    update (homeObservationId,data) {
      return http({ url: `/learning-journal/home-observation/${homeObservationId}`, method: 'POST', data: data })
    },
    delete (homeObservationId) {
      return http({ url: `/learning-journal/home-observation/${homeObservationId}`, method: 'DELETE' })
    },
  },
  nextSteps:{
    index(params) {
      return http({ url: `/learning-journal/learning-journal/next-steps`, method: 'GET', params:params })
    },
    create(data) {
      return http({ url: `/learning-journal/learning-journal/next-steps`, method: 'POST', data})
    },
    update(stepId, data) {
      return http({ url: `/learning-journal/learning-journal/next-steps/${stepId}`, method: 'POST', data})
    }
  },
  likeable:{
    like (data = {}) {
      return http({ url: `/likeable/like`, method: 'POST', data: data })
    },
    unlike (data = {}) {
      return http({ url: `/likeable/unlike`, method: 'POST', data: data })
    },
  },
  commentable:{
    index(data = {}) {
      return http({ url: `/commentable/comment`, method: 'GET', params: data })
    },
    create(data = {}) {
      return http({ url: `/commentable/comment`, method: 'POST', data: data })
    }
  }
}