import coreApiService from '@/core/services/core.api'

const getConfig = (context, options)  => {
  return new Promise((resolve, reject) => {
    coreApiService.config.index({ include_sessional: true })
      .then(response => {
        context.commit('SET_CONFIG', response.data)
        resolve()
      }).catch(error => {
        reject()
      })
  })
}

export default {
  getConfig
}
