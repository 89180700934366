<template>
	<span class="add-photo-icon">
		<PhotographIcon />
		<PlusIcon />
	</span>
</template>
<script>
import { PhotographIcon, PlusIcon } from '@vue-hero-icons/outline'
export default {
	components: {
		PhotographIcon,
		PlusIcon,
	}
}
</script>
<style lang="scss" scoped>
	.add-photo-icon {
		display: inline-block;
		position: relative;
		svg {
			color: $grey-very-dark;

			&:first-child {
				width: 48px;
				height: 48px;
			}
			&:last-child {
				background: white;
				position: absolute;
		    top: -2px;
		    right: -2px;
		    width: 20px;
		    height: 20px;
			}
		}
	}
</style>