import apiService from '@/modules/journal/services/journal.api'
import Vue from 'vue'

const retrieveFeedItems = (context, params)  => {
  return new Promise((resolve, reject) => {
    apiService.feed.index(params)
    .then(response => {
      context.commit('ADD_FEED_ITEMS', response.data.data)
      context.commit('SET_METADATA', response.data.meta)
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}

const createFeedEntry = (context, {type, params})  => {
  return new Promise((resolve, reject) => {
    apiService[type].create(params)
    .then(response => {
      context.commit('ADD_NEW_FEED_ITEM', response.data)
      Vue.bus.emit('app::alert::success', `A new ${changeDiaryAndLearningJournal(response.data.type)} entry has been successfully created.`)
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}

const updateFeedEntry = (context, {type, id, params})  => {
  return new Promise((resolve, reject) => {
    apiService[type].update(id, params)
    .then(response => {
      context.commit('UPDATE_FEED_ITEM', response.data)
      Vue.bus.emit('app::alert::success', `A ${changeDiaryAndLearningJournal(response.data.type)} entry has been successfully updated.`)
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}

// added as a work around due to Diary(Daily Update) and Learning Journal(Development Diary) name changed by client 
function changeDiaryAndLearningJournal (response)
{
  switch(response) {

    case 'Diary':
      return 'Daily Update'

    case 'LearningJournal':
      return 'Development Diary'
      
    default :
      return response
  }
}

export default {
  retrieveFeedItems,
  createFeedEntry,
  updateFeedEntry,
}
