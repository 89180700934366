const SET_CHILDREN_IDS = (state, ids) => {
    localStorage.setItem('childrenIds', ids);
    state.childrenIds = ids
}

const SET_NURSERY_SEARCH_ID = (state, id) => {
    state.nursery_search_id = id;
}

export default {
    SET_CHILDREN_IDS,
    SET_NURSERY_SEARCH_ID
}
