import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  feedItems: [],
  meta: null,
  currentlyEditedEntry: null,
  availableFilters: [
    {
      key: 'LearningJournal',
      label: 'Development Diaries',
    },
    {
      key: 'Diary',
      label: 'Daily Diary',
    },
    {
      key: 'GroupObservation',
      label: 'Group Observation',
    },
    {
      key: 'HomeObservation',
      label: 'Home observations',
    },
    {
      key: 'Milestone',
      label: 'Milestones',
    },
  ],
  search: {
    filters: [],
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
