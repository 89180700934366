import http from '@/core/services/drivers/app-http-driver'

export default {
  carer: {
    index (params = {}) {
      return http({ url: `/account/carer`, method: 'get', params: params })
    },
    show (carerId, params = {}) {
      return http({ url: `/account/carer/${carerId}/show`, method: 'get', params: params })
    },
    update (carerId, data = {}) {
      return http({ url: `/account/carer/${carerId}/update`, method: 'post', data: data })
    },
    create (data = {}) {
      return http({ url: `/account/carer/create`, method: 'post', data: data })
    },
    delete(carerId) {
      return http({ url: `/account/carer/${carerId}`, method: 'delete' })
    },
    inviteToApp(carerId, data = {}) {
      return http({ url: `/account/carer/${carerId}/invite-to-app`, method: 'post', data: data })
    },
    invite(carerId, data = {}) {
      return http({ url: `/account/carer/${carerId}/invite`, method: 'post', data: data })
    },
    matchRelations ( params = {}) {
      return http({ url: `/account/carer/fpt-relations`, method: 'get', params: params })
    },
    importChildren (data = {}) {
      return http({ url: `/account/carer/child/import`, method: 'post', data: data })
    },
    updateChildcareReferenceNumber(childId, data = {}) {
      return http({ url: `/account/carer/child/${childId}/childcare-voucher`, method: 'post', data: data })
    },
    createSubjects(params = {}) {
      return http({ url: `/account/carer/create-subjects`, method: `post`, data: params})
    },
    merge(params = {}) {
      return http({ url: `/account/carer/merge`, method: `post`, data: params})
    },
    createChild (data = {}) {
      return http({ url: `/account/carer/child`, method: 'post', data: data })
    },
    updateChild (data = {}) {
      return http({ url: `/account/carer/child`, method: 'post', data: data })
    },
    search(params = {}) {
      return http({url: `/account/marketplace/keyworker`, method: 'get', params: params})
    },
    addToBranch(carerId, data = {}) {
      return http({ url: `account/carer/${carerId}/add-to-branch`, method: 'post', data: data })
    },
    bookingsForMonth(params = {}) {
      return http({ url: `/account/carer/bookings`, method: 'get', params: params })
    },
    retrieveSetupIntentClientSecret() {
      return http({ url: '/account/carer/payment-methods/initialize', method: 'post' })
    },
    storePaymentMethod(data = {}) {
      return http({ url: '/account/carer/payment-methods', method: 'post', data: data })
    },
    retrieveLatestStoredData(data = {}) {
      return http({ url: '/account/carer/latest-data', method: 'get', data: data })
    }
  },
  child: {
    index (params = {}) {
      return http({ url: `/account/child`, method: 'get', params: params })
    },
    show (childId, params = {}) {
      return http({ url: `/account/child/${childId}/show`, method: 'get', params: params })
    },
    update (childId, data = {}) {
      return http({ url: `/account/child/${childId}/update`, method: 'post', data: data })
    },
    delete (childId) {
      return http({ url: `/account/child/${childId}`, method: 'delete' })
    },
    create (data = {}) {
      return http({ url: `/account/child/create`, method: 'post', data: data })
    },
    exclude (childId) {
      return http({ url: `/account/child/${childId}/exclude`, method: `post`})
    },
    booking (childId, params = {}) {
      return http({ url: `/account/child/${childId}/booking`, method: 'get', params: params })
    },
    sessionRegistry (childId, params = {}) {
      return http({ url: `/account/child/${childId}/session-registry`, method: 'get', params: params })
    },
    sessionRegistryNotes (childId, params = {}) {
      return http({ url: `/account/child/${childId}/session-registry/notes`, method: 'get', params: params })
    },
    saveFundingStatus(childId, profileId, branchId, data = {}) {
      return http({ url: `/account/child/${childId}/profile/${profileId}/nurseryBranch/${branchId}`, method: 'patch', data: data })
    },
    getFundedHours (childId, profileId, params = {}) {
      return http({ url: `/account/child/${childId}/profile/${profileId}/fundingWeeks`, method: 'get', params: params })
    },
    getFundedHoursSummary (childId, profileId, params = {}) {
      return http({ url: `/account/child/${childId}/profile/${profileId}/fundingHoursSummary`, method: 'get', params: params })
    },
    getBookings(childId, params = {}) {
      return http({ url: `/account/child/${childId}/dates-booked`, method: 'get', params: params })
    },
    getSessions(childId, params = {}) {
      return http({ url: `/account/child/${childId}/session`, method: 'get', params: params })
    },
    countSessions(childId, params = {}) {
      return http({ url: `/account/child/${childId}/booking/count`, method: 'get', params: params })
    },
    bookingsForMonth(childId, params = {}) {
      return http({ url: `/account/child/${childId}/booking/month`, method: 'get', params: params })
    },
  },
  pickup: {
    index (childId, params = {}) {
      return http({ url: `/account/child/${childId}/pickup`, method: 'get', params: params })
    },
    show (pickupId, params = {}) {
      return http({ url: `/account/pickup/${pickupId}`, method: 'get', params: params })
    },
    create (data = {}) {
      return http({ url: `/account/pickup`, method: 'post', data: data, headers: { 'Content-Type': 'multipart/form-data' } })
    },
    update (pickupId, data = {}) {
      return http({ url: `/account/pickup/${pickupId}`, method: 'post', data: data, headers: { 'Content-Type': 'multipart/form-data' } })
    },
    delete (pickupId, params = {}) {
      return http({ url: `/account/pickup/${pickupId}`, method: 'delete', params: params })
    },
  },
  getAgeRanges (params = {}) {
    return http({ url: '/nursery/age-ranges', method: 'get', params: params })
  },
  getAccountCounts (params = {}) {
    return http({ url: '/account/count', method: 'get', params: params })
  },
}
