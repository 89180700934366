import app from '@/main'
import axios from 'axios'
import store from '@/core/store'
import headers from './app-http-headers'
import authApiService from '@/modules/auth/services/auth.api'

let instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  timeout: 30000,
})

instance.interceptors.request.use(config => {
  config.headers = headers()
  app.$Progress.start()
  return config
})

instance.interceptors.response.use(response => {
  app.$Progress.finish()
  
  if(store.getters['auth/user']) {
    //refresh the user's auth token
    store.dispatch('auth/refresh');
  }

  return response
}, error => {
  app.$Progress.finish()

  if (error && error.response) {
    if (error.response.status === 400) { // ApiKey Error
      app.$store.dispatch('auth/autoLogout')
    } else if (error.response.status === 401) { // Auth Error
      if (app.$store.getters['auth/userIsLoggedIn']) {
        app.$store.dispatch('auth/logout')
        .then(() => {
          if (app.$route.name != 'login') {
            app.$router.push({ name: 'login' })
          }
        })
      }
    } else if (error.response.status === 403 && (error.response.data.message === 'This provider has been suspended. Please contact your Administrator!' || error.response.data.message === 'You got suspended from the given provider')) { //Suspended Error)
      authApiService.user()
      .then(response => {
        if (response.data.nurseries.data.find( nursery => !nursery.is_suspended)) {
          localStorage.removeItem('activeBranch')
          app.$store.commit('auth/SET_USER', response.data)
          app.$store.commit('auth/SET_USER_HAS_SUSPENDED_NURSERY', response.data.nurseries.data.find( nursery => nursery.is_suspended || nursery.staffProfile.suspended_at)? true : false)
          app.$router.push({ name: 'choose-branch' })
        } else {
          app.$store.dispatch('auth/logout')
          .then(() => {
            if (app.$route.name != 'login') {
              app.$router.push({ name: 'login' })
            }
          })
        }
      })
    } else if (error.response.status === 403 && error.response.data.message === 'The Service has been scheduled for deletion, please get in touch with an administration member') {
      app.$store.dispatch('auth/logout')
      .then(() => {
        if (app.$route.name != 'login') {
          app.$router.push({ name: 'login' })
        }
      })
    } else if (error.response.status === 403 && error.response.data.message === '') {
      app.$router.push({path: '/'}).catch(() => {})
    }
  }

  return Promise.reject(error)
})

export default instance
