import lazyLoading from '@/core/router/lazy-loading'
import GlobalLayout from '@/core/layouts/global-layout/GlobalLayout'

export const settingsRoutes = [
{
    path: '/settings',
    component: GlobalLayout,
    children: [
      {
        path: '',
        component: lazyLoading('settings', 'SettingsLayout'),
        children: [
          /* Shared */
          {
            name: 'staff-profile',
            path: 'staff-profile',
            component: lazyLoading('staff', 'profile/EmployeeDetails'),
          },
          {
            name: 'user-profile',
            path: 'profile',
            component: lazyLoading('settings', 'UserProfile'),
          },
          {
            name: 'holidays-absences',
            path: 'holidays-absences',
            component: lazyLoading('settings', 'ComingSoon'),
          },
          {
            name: 'qualifications-skills',
            path: 'qualifications-skills',
            component: lazyLoading('settings', 'ComingSoon'),
          },
          {
            name: 'schedule',
            path: 'schedule',
            component: lazyLoading('settings', 'ComingSoon'),
          },
        	{
        	  name: 'account-settings',
        	  path: 'settings',
        	  component: lazyLoading('settings', 'Settings'),
        	},

        	/* Service Profile */
        	{
        	  name: 'service-provider-information',
        	  path: 'service-provider',
        	  component: lazyLoading('settings', 'service/ProviderInformation'),
        	},
        	{
            path: 'services/:serviceId',
            component: lazyLoading('settings', 'service/ServiceProfile'),
            redirect: to => ({
              name: 'service-details',
              params: { serviceId: to.params.serviceId },
            }),
            children: [
              {
                name: 'service-details',
                path: 'details',
                component: lazyLoading('settings', 'service/ServiceDetails'),
              },
              {
                name: 'service-payment-and-cancellation',
                path: 'payment-and-cancellation',
                component: lazyLoading('settings', 'service/PaymentAndCancellation'),
              },
              {
                name: 'service-opening-hours',
                path: 'opening-hours',
                component: lazyLoading('settings', 'service/OpeningHours'),
              },
              {
                name: 'service-photo-gallery',
                path: 'photo-gallery',
                component: lazyLoading('settings', 'service/PhotoGallery'),
              },
            ]
          },
        	{
        	  name: 'service-subscriptions',
        	  path: 'subscriptions',
        	  component: lazyLoading('settings', 'ComingSoon'),
        	},
        	{
        	  name: 'service-monthly-usage-statements',
        	  path: 'usage-statements',
        	  component: lazyLoading('settings', 'ComingSoon'),
        	},
        	{
        	  name: 'service-payment-collection-details',
        	  path: 'payment-collection',
        	  component: lazyLoading('settings', 'service/PaymentCollectionDetails'),
        	},
        	{
        	  name: 'service-reviews',
        	  path: 'reviews',
        	  component: lazyLoading('settings', 'ComingSoon'),
        	},

          // Staff Profile
          {
            name: 'staff-settings-profile-employee-details',
            path: 'employment-details',
            component: lazyLoading('staff', 'profile/EmployeeDetails'),
            props: true,
            meta: {
              title: 'Employment Details'
            }
          },
          {
            name: 'staff-settings-profile-qualifications',
            path: 'qualifications',
            component: lazyLoading('staff', 'profile/Qualifications'),
            props: true,
            meta: {
              title: 'Your Qualifications'
            }
          },
          {
            name: 'staff-settings-profile-holidays',
            path: 'holidays',
            component: lazyLoading('staff', 'profile/Holidays'),
            props: true,
            meta: {
              title: 'Your Holidays and Absences'
            }
          },

        	/* Keyworker Profile*/
          {
            path: 'children/:childId',
            name: 'keyworker-child',
            component: lazyLoading('settings', 'keyworker/ChildProfile'),
            redirect: { name: 'keyworker-child-profile' },
            props: true,
            children: [
              {
                name: 'keyworker-child-profile',
                path: 'profile',
                component: lazyLoading('children', 'KeyworkerChildren'),
              },
              {
                name: 'keyworker-child-services',
                path: 'services',
                component: lazyLoading('children', 'KeyworkerConnectedProviders'),
              },
              {
                name: 'keyworker-child-development-diary',
                path: 'development-diary',
                component: lazyLoading('journal', 'Feed'),
              },
            ]
          },
          {
            name: 'keyworker-connections',
            path: 'connections',
            component: lazyLoading('settings', 'keyworker/Connections'),
          },
          {
            name: 'keyworker-invoices',
            path: 'invoices',
            component: lazyLoading('settings', 'ComingSoon'),
          },
          {
            name: 'keyworker-payment-methods',
            path: 'payment-methods',
            component: lazyLoading('settings', 'ComingSoon'),
          },
        ]
      }
    ]
  }
]