<template>
  <b-nav-item
    aria-label="navigate to notifcations"
    class="nav-item-icon position-relative"
    :to="{ name: 'notifications' }"
  >
    <span class="sr-only">Notifications</span>
    <span
      v-if="notifications.unread.length"
      class="notification-badge"
    />

    <component
      :is="icon"
      class="md-heroicon text-primary my-auto"
    />
  </b-nav-item>
</template>

<script>
import { BellIcon } from '@vue-hero-icons/outline'
import { BellIcon as BellSolidIcon } from '@vue-hero-icons/solid'
import { mapGetters } from 'vuex'
import notificationsApiService from '@/modules/notifications/services/notifications.api'

export default {
  name: 'NotificatioNavItem',

  components: {
    BellIcon,
    BellSolidIcon
  },

  data: () => ({
    notifications: {
      read: [],
      unread: []
    }
  }),

  computed: {
    ...mapGetters('auth', [
      'userIsLoggedIn',
      'checkNotifications'
    ]),

    icon() {
      return `${this.$route.name == 'notifications' ? 'bell-solid' : 'bell'}-icon`
    }
  },

  watch: {
    checkNotifications (val) {
      if (this.userIsLoggedIn && val) {
        this.fetchNotifications()
        this.$store.commit('auth/SET_NOTIFICATIONS', false)
      }
    }
  },

  created() {
    if (this.userIsLoggedIn) {
      this.fetchNotifications()
    }
  },

  methods: {
    fetchNotifications() {
      notificationsApiService.index().then(response => {
        this.notifications.read = response.data.data.filter(n => n.is_read);
        this.notifications.unread = response.data.data.filter(n => !n.is_read);
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .notification-badge {
    position: absolute;
    top: 17px;
    right: 0;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background: $secondary;
    border: 2px solid $white;
  }
</style>
