<template>

  <div class="basket-item mt-2 border-dashed-bottom">
  	<b-row v-if="item">
  		
  		<b-col cols="1">
  			<app-avatar
  			  :src="item.service_logo"
  			  size="20px"
  			  class="my-auto"
  			/>
  		</b-col>

  		<b-col cols="7">
  			
  			<div class="item-details">
  				<span><strong>{{ item.session_name }}</strong></span>
  				<span>{{ item.type }}</span>
  				<span>{{ item.location_name }}</span>
  				<span>{{ item.date }}</span>
  				<span>{{ item.start_time }} - {{ item.end_time }}</span>
  			</div>

  		</b-col>

  		<b-col cols="4">
  			<div class="float-right">
  				
  				<span>
  					<strong>{{ item.hours }} {{ 'hr' | plural(item.hours) }}
							<span v-if="item.minutes"> {{ item.minutes }} {{ 'min' | plural(item.minutes) }}</span>
						</strong>
					</span>

  			</div>
  		</b-col>
  	</b-row>

		<div class="action-link">
			<span class="float-right" @click="removeFromBasket" aria-label="remove item from basket">Remove</span>
		</div>
  </div>

</template>

<script>
import basketApiService from '@/modules/basket/services/basket.api.js'

export default {

  name: 'BasketItem',

  props: {
  	item: Object,
  },

  data() {

    return {
    	instance: null,
    	loading: false,
    }
  },

  methods: {

  	removeFromBasket() {
  		this.$store.dispatch('basket/removeFromBasket', this.item)
  		.then( (response) => this.$bus.emit('app::checkout::summary')) 
  	},
  },

}
</script>

<style lang="scss" scoped>
	.basket-item {
		position: relative;
	  .item-details {
	  	span {
	  		display: block;
	  	}
	  }
	  .action-link {
	  	position: absolute;
	  	bottom: 5px;
	  	right: 0;
	  	cursor: pointer;
	  	font-size: 12px;

	  	span {
	  		color: $primary;
	  		text-decoration: underline !important;
	  	}
	  }
	}
</style>