<template>
  <b-popover
    :target="target"
    triggers="click blur"
    :show.sync="show"
    placement="auto"
    custom-class="basket-popover"
    @hidden="$emit('close')"
  >
    <template #title>
      Your basket <span v-if="basketCount">({{ basketCount }})</span>
    </template>
    <Basket class="mb-55" />
    <!-- Footer -->
    <div class="popover-footer fixed-bottom p-3">
      <b-button
        :disabled="isBasketEmpty"
        variant="outline-primary"
        class="w-100"
        @click="goToCheckout"
      >
        Go to checkout
      </b-button>
    </div>
  </b-popover>
</template>

<script>
import { mapGetters } from 'vuex'
import Basket from '@/modules/basket/components/Basket'


export default {

  name: 'BasketPopover',

  components: {
  	Basket,
  },

  props: {
  	target: String,
  	show: Boolean,
  },

  computed: {
  	...mapGetters('basket', ['isBasketEmpty', 'basketCount']),
  },

	methods: {
		goToCheckout() {
			this.$emit('close')
			this.$router.push({name: 'checkout'})
		}
	}
}
</script>

<style lang="scss">
  .basket-popover {
  	width: 410px;
  	// min-height: 200px;

	 @media (max-width: 576px) {
      width: 100vw;
	  height: 80vh;
	  position: absolute !important; 
	  left: 25px !important;
    }

  	.popover-header {
  		height: 40px;
  		border: none;
  		background: white;
  		font-size: 26px;
  		font-weight: 600;
  	}

  	.popover-body {
  		// min-height: 160px;
  		position: relative;

  		.popover-footer {
  			width: 100%;
  		}
  	}
  	.mb-55 {
  		margin-bottom: 55px;
  	}
  }
</style>