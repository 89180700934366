<template>
	<b-modal
	  :id="id"
	  header-class="hidden"
    body-class="p-3"
    content-class="p-0 modal-content"
    footer-class="hidden"
    @hidden="closeModal"
	>
  	<a href="#" @click.prevent="onSkip" class="float-right">Skip</a>
  	
  	<h4>{{ currentQuestionnaire.title }} ({{ currentQuestionNumber }}\{{ currentQuestionnaire.total_questions }})</h4>

  	<p>{{ currentQuestionnaire.message }}</p>

  	<component v-bind:is="currentQuestion.render_type" :question="currentQuestion" />

		<div class="d-flex justify-content-center mb-2">
			<b-button class="w-50 ml-3" variant="outline-primary" href="https://www.caerus.scot/" target="_blank" rel="noopener noreferer">Find out more</b-button>
			<b-button class="w-50 ml-3 mr-3" variant="primary" @click="onSubmit">{{ isLastQuestion ? 'Finish' : 'Next' }}</b-button>
		</div>  		
	</b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import FormMixin from '@/core/mixins/form-mixin.js'

import FreeText from '@/modules/questionnaire/components/questions/FreeText'
import SingleOption from '@/modules/questionnaire/components/questions/SingleOption'
import MultipleOption from '@/modules/questionnaire/components/questions/MultipleOption'

export default {
  name: 'ModalQuestionnaire',

  mixins: [FormMixin],

  props: {
  	id: {
  		default: 'modalQuestionnaire',
  		type: String,
  	},
  },

  components: {
  	'free-text': FreeText,
  	'single-option': SingleOption,
  	'multiple-option': MultipleOption,
  },

  data() {
  	return {
  		hasError: false,
  	}
  },

  computed: {
  	...mapGetters('questionnaire', [
  		'hasCurrentQuestionnaire',
  	  'currentQuestionnaire',
  	  'currentQuestionAnswer',
  	]),

  	currentQuestion() {
  		return this.currentQuestionnaire.respondent.currentQuestion
  	},

  	currentQuestionNumber() {
  		return this.currentQuestion.index + 1
  	},

  	isLastQuestion() {
  		return this.currentQuestionNumber == this.currentQuestionnaire.total_questions
  	},

  	answer() {
			return this.currentQuestion.has_options
				? this.currentQuestion.render_type == 'single-option'
				? { answer_option_ids: [this.currentQuestionAnswer.answer_option_ids ]}
				: { answer_option_ids: this.currentQuestionAnswer.answer_option_ids }
				: { answer_text: this.currentQuestionAnswer.answer_text }
		},
  },

  methods: {

  	onSubmit() {
  		this.hasError = false

  		this.$store.dispatch('questionnaire/answer', {
  			questionnaireId: this.currentQuestionnaire.id,
  			questionId: this.currentQuestion.id,
  			data: {
  				...this.answer,
  				respondent_id: this.currentQuestionnaire.respondent.id,
  			},
  		})
  		.then( () => {
	  		this.handleQuestionnaireFinished()
  		})
  		.catch( error => {
  			this.$bus.emit('app::alert::error', 'Please provide answer.')
  		})
  	},

  	handleQuestionnaireFinished() {
  		if (!this.hasCurrentQuestionnaire) {
  			this.$bus.emit('app::modal::success', {title: 'Thank you', message: 'Your answer will help the government improve the quality and availability of childcare all over Scotland.'})
  		}
  	},

  	onSkip() {

  		this.msgBoxConfirm('Are you sure you want to skip this question? You will not be able to answer it again.', {
  		  title: 'Skip answering question',
  		  okTitle: 'Skip',
  		  cancelTitle: 'No',
  		}).then( () => {
	  		this.$store.dispatch('questionnaire/skip', {
	  			questionnaireId: this.currentQuestionnaire.id,
	  			questionId: this.currentQuestion.id,
	  			data: {
	  				respondent_id: this.currentQuestionnaire.respondent.id,
	  			},
	  		})
	  		.then( () => {
		  		this.handleQuestionnaireFinished()
	  		})
	  		.catch( error => {
	  			this.contactSupport(error)
	  		})	
  		})

  	},

  	closeModal() {
			this.$store.commit('questionnaire/SET_CURRENT_QUESTIONNAIRE', null)
  	},
  },

  mounted() {
  	this.$bvModal.show(this.id)
  },
}
</script>

<style lang="scss" scoped>
	.justify-content-center {
		border-top: solid 1px $border-gray;
		padding-top: 20px;
	}
	h4 {
		font-weight: 700;
		font-size: 20px;
		line-height: 22px;
	}
	p {
		font-weight: 300;
		font-size: 16px;
		line-height: 22px;
	}
</style>