import http from '@/core/services/drivers/app-http-driver'

export default {
  room: {
    index (nurseryId, branchId, params = {}) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/room`, method: 'get', params: params })
    },
    show (nurseryId, branchId, roomId, params = {}) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/room/${roomId}/show`, method: 'get', params: params })
    },
    create (nurseryId, branchId, data = {}) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/room/create`, method: 'post', data: data })
    },
    update (nurseryId, branchId, roomId, data = {}) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/room/${roomId}/update`, method: 'post', data: data })
    },
    delete (nurseryId, branchId, roomId) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/room/${roomId}/delete`, method: 'delete' })
    },
  },
  session: {
    index (nurseryId, branchId, params = {}) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/session`, method: 'get', params: params })
    },
    archive (nurseryId, branchId, params = {}) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/session/archive`, method: 'get', params: params })
    },
    show (nurseryId, branchId, sessionId, params = {}) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/session/${sessionId}`, method: 'get', params: params })
    },
    create (nurseryId, branchId, data = {}) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/session/create`, method: 'post', data: data })
    },
    update (nurseryId, branchId, sessionId, data = {}) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/session/${sessionId}/update`, method: 'post', data: data })
    },
    delete (nurseryId, branchId, sessionId) {
      return http({ url: `/nursery/${nurseryId}/branch/${branchId}/session/${sessionId}/delete`, method: 'delete' })
    },
    review (nurseryId, branchId, params = {}) {
      return http ({ url: `/nursery/${nurseryId}/branch/${branchId}/session/expiring`, method: 'get', params: params })
    },
    calendar( nurseryId, branchId, params = {} ) {
      return http ({ url: `/nursery/${nurseryId}/branch/${branchId}/session/calendar`, method: 'get', params: params })
    },
  },
  sessionInstance: {
    deleteOneOrMany (nurseryId, branchId, sessionId, params = {}) {
      return http ({ 
        url: `/nursery/${nurseryId}/branch/${branchId}/session/${sessionId}/instance`, 
        method: 'delete', 
        params: params 
      })
    }
  },
  marketplace: {
    status(nurseryId, branchId, data = {}) {
      return http({url: `/nursery/${nurseryId}/branch/${branchId}/marketplace-status`, method: 'post', data: data})
    }
  },
  getAgeRanges (params = {}) {
    return http({ url: '/nursery/age-ranges', method: 'get', params: params })
  },
}
