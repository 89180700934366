import lazyLoading from '@/core/router/lazy-loading'
import GlobalLayout from '@/core/layouts/global-layout/GlobalLayout'

export const childrenRoutes = [
  {
    path: '/children',
    component: GlobalLayout,
    children: [
      {
        path: '',
        name: 'children',
        component: lazyLoading('children', 'layouts/ChildrenLayout'),
        redirect: { name: 'children-active' },
        children: [
          {
            name: 'children-active',
            path: 'active',
            component: lazyLoading('children', 'children/Children'),
            meta: {
              title: 'Active Children'
            },
          },
          {
            name: 'children-reports',
            path: 'reports',
            component: lazyLoading('children', 'children/Reports'),
            meta: {
              title: 'Accident Reports'
            },
          },
          {
            name: 'children-requests',
            path: 'requests',
            component: lazyLoading('children', 'children/Requests'),
            meta: {
              title: 'Child Requests'
            },
          },
          {
            name: 'children-carers',
            path: 'carers',
            component: lazyLoading('children', 'children/Carers'),
            meta: {
              title: 'Carers'
            },
          },
        ],
      },
      
      {
        path: 'profile/:id',
        name: 'children-profile',
        component: lazyLoading('children', 'layouts/ChildLayout'),
        redirect: { name: 'children-overview' },
        children: [
          {
            props: true,
            name: 'children-overview',
            path: 'overview',
            component: lazyLoading('children', 'child/Overview'),
            meta: {
              title: 'Overview'
            }
          },
          {
            props: true,
            name: 'children-details',
            path: 'information',
            component: lazyLoading('children', 'child/Information'),
            meta: {
              title: 'Child Information'
            },
          },
          {
            props: true,
            name: 'children-pickups',
            path: 'pickups',
            component: lazyLoading('children', 'child/CarersAndPickups'),
            meta: {
              title: 'Carers and Pick-ups'
            },
          },
          {
            props: true,
            name: 'children-funding-status',
            path: 'funding-status',
            component: lazyLoading('children', 'child/FundingStatus'),
            meta: {
              title: 'Funding Status'
            },
          },
          {
            props: true,
            name: 'children-booking-calendar',
            path: 'booking-calendar',
            component: lazyLoading('children', 'child/BookingCalendar'),
            meta: {
              title: 'Booking Calendar'
            },
          },
          {
            props: true,
            name: 'children-development-diary',
            path: 'development-diary',
            component: lazyLoading('children', 'layouts/DevelopmentDiaryLayout'),
            redirect: { name: 'children-diary-feed' },
            children: [
              {
                props: true,
                name: 'children-diary-feed',
                path: 'feed',
                component: lazyLoading('journal', 'Feed'),
                meta: {
                  title: 'Development Diary'
                },
              },
              {
                props: true,
                name: 'children-next-steps',
                path: 'next-steps',
                component: lazyLoading('journal', 'NextSteps'),
                meta: {
                  title: 'Next Steps'
                },
              },
            ]
          },
        ]
      },

      {
        props: true,
        name: 'children-journal',
        path: ':id/journal/:journalId?',
        component: lazyLoading('journal', 'LearningJournal'),
        redirect: { name: 'journal-step-1' },
        
        children: [
          {
            props: true,
            name: 'journal-step-1',
            path: 'step-1',
            component: lazyLoading('journal', 'learningJournal/Step1'),
            meta: {
              title: 'Step 1'
            },
          },
          {
            props: true,
            name: 'journal-step-2',
            path: 'step-2',
            component: lazyLoading('journal', 'learningJournal/Step2'),
            meta: {
              title: 'Step 2'
            },
          },
          {
            props: true,
            name: 'journal-step-3',
            path: 'step-3',
            component: lazyLoading('journal', 'learningJournal/Step3'),
            meta: {
              title: 'Step 3'
            },
          },
          {
            props: true,
            name: 'journal-step-4',
            path: 'step-4',
            component: lazyLoading('journal', 'learningJournal/Step4'),
            meta: {
              title: 'Step 4'
            },
          },
          {
            props: true,
            name: 'journal-step-5',
            path: 'step-5',
            component: lazyLoading('journal', 'learningJournal/Step5'),
            meta: {
              title: 'Step 5'
            },
          }
        ]
      },
      {
        props: true,
        name: 'children-create',
        path: 'create',
        component: lazyLoading('children', 'child/create/ChildCreate'),
      },
    ]
  }
]