<template>
	<b-modal
	  id="successModal"
	  header-class="hidden"
    body-class="p-3 text-center"
    content-class="p-4"
    footer-class="hidden"
	  @hidden="$emit('close')"
	>
  	
  	<CheckCircleIcon />
  	
  	<h3 class="mt-3 mb-3">{{ title }}</h3>

  	<p v-if="message" class="mb-3">{{ message }}</p>

  	<b-button class="w-50" variant="primary" @click="$bvModal.hide('successModal')">{{ buttonText }}</b-button>

	</b-modal>
</template>
<script>
import { CheckCircleIcon } from '@vue-hero-icons/outline'

const DEFAULT_DATA = {
	title: 'Success',
	message: '',
	buttonText: 'Close',
}

export default {
	name: 'SuccessModal',

	data() {
		return DEFAULT_DATA
	},

	components: {
		CheckCircleIcon,
	},

	methods: {

		handleEvent(data) {
			this.reset()
			
			this.title = data.title ? data.title : DEFAULT_DATA.title
			this.message = data.message ? data.message : DEFAULT_DATA.message
			this.buttonText = data.buttonText ? data.buttonText : DEFAULT_DATA.buttonText

			this.$bvModal.show('successModal')
		},

		reset() {
			this.title = DEFAULT_DATA.title
			this.message = DEFAULT_DATA.message
			this.buttonText = DEFAULT_DATA.buttonText
		}
	},

	created() {
		this.$bus.on('app::modal::success', this.handleEvent)
	},
}
</script>
<style lang="scss" scoped>
	svg {
		width: 81px;
    height: 81px;
    color: $secondary-lite;
	}
</style>