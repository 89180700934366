<template>

	<b-modal
	  :id="id"
	  header-class="hidden"
    body-class="p-3"
    content-class="p-0 modal-content"
    footer-class="hidden"
	>
		<p class="title">Add a new child</p>

  	<ChildBasicForm :form="form" :serverErrors="serverErrors" :formLock="formLock" />

		<div class="d-flex justify-content-center mb-2">
			<b-button class="w-50 ml-3" variant="outline-primary" @click="closeModal">Cancel</b-button>
			<b-button class="w-50 ml-3 mr-3" variant="primary" @click="onSubmit">Add child</b-button>
		</div>  		
	</b-modal>

</template>

<script>
import ChildBasicForm from '@/modules/children/components/ChildBasicForm'
import FormMixin from '@/core/mixins/form-mixin'
import accountApiService from '@/modules/children/services/children.api'

export default {

  name: 'AddChildModal',

  mixins: [ FormMixin ],

  props: {
  	id: { type: String, default: 'AddChildModal' },
  },

  components: {
  	ChildBasicForm,
  },

  data() {

    return {
    	form: {},
    }
  },

  computed: {

  },

  methods: {
		closeModal() {
  		this.$bvModal.hide(this.id)
  	},

  	onSubmit() {
  		accountApiService.carer
  		  .createChild(this.form)
  		  .then((response) => { 
  		    this.serverErrors = {}
  		    this.form = {}
  		    this.$store.dispatch('auth/user')
  		    this.closeModal()
  		  })
  		  .catch((error) => {
  		    this.formState = 'error'
  		    this.serverErrors = error.response.data.errors
  		    this.contactSupport(error)
  		  })
  	},
  },
}
</script>

<style lang="scss" scoped>
  
</style>