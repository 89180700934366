<template>
  <!-- <SharedNavigation> -->
  <div class="d-block py-0">
    <li class="settings-link d-flex align-items-center collapse-link py-3">
      <router-link :to="{ name: 'keyworker-bookings' }">
        Bookings
      </router-link>
    </li>
   


    <li class="settings-link d-flex align-items-center collapse-link py-3">
      <a @click="openAdviceLink">
        Advice
      </a>
    </li>
  </div>
  <!-- </SharedNavigation>-->
</template>
<script>
// import SharedNavigation from '@/core/layouts/global-layout/components/user-navigation/SharedNavigation'
import { isBookingsRoute } from '@/core/router/utils'


export default {
  components: {
    // SharedNavigation,

  },

  methods: {
    /**
     * Open advice link in new tab
     */
    openAdviceLink() {
      window.open('https://support.caerus.scot/portal/en-gb/home', '_blank');
    },

    searchByFavourites() {
      this.$router.push({
  			name: 'search-results',
  			query: { favourites: true }
  		})
    },

    isBookingsRoute() {
      return isBookingsRoute(this.$route.name)
    },
    
  }
}
</script>