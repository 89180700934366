<template>
	<div class="dropzone-content">
	  <div class="dropzone-title">
	    <AddPhotoIcon class="mt-5 mb-2" />
	    <p><span class="primary">Upload a file</span> or drag and drop</p>
		  <p class="small">PNG, JPG, GIF up to 10MB</p>
	  </div>
	</div>
</template>
<script>
import AddPhotoIcon from '@/core/components/AddPhotoIcon'
export default {
	components: {
		AddPhotoIcon,
	}
}
</script>
<style lang="scss" scoped>
	.dropzone-content {
		width: 100%;
		height: 100%;
		border: dashed 2px $border-gray;
		border-radius: 5px;
		cursor: pointer;
		
		.dropzone-title {
			text-align: center;

			.primary {
				color: $primary;
			}
		}
	}
</style>