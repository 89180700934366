<template>
  <b-form
    novalidate
    @submit.prevent="search"
  >
    <TextSearch
      :params="params"
      placeholder="Search for a service or session"
      class="nav-search"
    >
      <button
        @click="search"
        class="btn btn-primary"
      >
        <SearchIcon/>
      </button>
    </TextSearch>
  </b-form>
</template>

<script>
import TextSearch from '@/modules/results/components/Search/TextSearch'
import { SearchIcon } from "@vue-hero-icons/outline"

export default {
  components: {
    TextSearch,
    SearchIcon,
  },

  data() {
    return {
      params: {
    		search: this.$route.query.search,
    	},
    }
  },

  methods: {
    search() {
      this.$emit('search', this.params)
    },
  }
}
</script>

<style>
  .nav-search {
    width: 322px !important;
    position: relative;
    top: 20%;
  }
</style>