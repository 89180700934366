<template>

	<div class="basket-content">
		
		<!-- Zero Data -->
		<div v-if="isBasketEmpty" class="text-center zero-data">
			<p>You have no items in your basket</p>
		</div>
		
		<!-- Basket Items -->
		<div v-else class="">
			
			<BasketItem v-for="item, index in basketItems" :key="index" :item="item" />

			<div class="mt-2 pt-1 pb-2 total-to-pay">
				<strong>Total to pay</strong>
				<span class="float-right"><strong>{{ totalToPay | priceUK }}</strong></span>
			</div>

		</div>
	
	</div>

</template>

<script>
import { mapGetters } from 'vuex'
import BasketItem from '@/modules/basket/components/BasketItem'

export default {

  name: 'Basket',

  components: {
  	BasketItem,
  },

  computed: {
  	...mapGetters('basket', ['isBasketEmpty', 'basketItems', 'totalToPay']),
  },
}
</script>

<style lang="scss" scoped>
	.basket-content {

		.total-to-pay {
			border-bottom: 1px dashed $border-gray;
		}
	}

	.zero-data {
		padding: 10px;
		height: 40px;
		position: relative;
	}
</style>