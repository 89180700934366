<template>
  <b-col>
    <b-input-group>
      <b-form-input 
        v-model="params.search"
        class="grow"
        :placeholder="placeholder"
      />
      <b-input-group-append>
        <slot></slot>
      </b-input-group-append>
    </b-input-group>
  </b-col>
</template>
<script>
  export default {
    name: 'TextSearch',
    
    props: {
      params: {
        type: Object,
        default: () => {}
      },
      placeholder: {
        type: String,
        default: 'Search name, location or keywords'
      }
    }
  }
</script>
<style>
  .grow {
    animation-duration: 0.7s;
    animation-name: grow;
    position: absolute;
    right: 0;
  }

  @keyframes grow {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }
</style>