<template>
  <b-nav-item-dropdown
    class="guest-dropdown"
    id="dropdown-guest"
    aria-label="dropdown-guest"
    dropbottom
    right
    no-caret
  >
    <template #button-content>
     <span class="sr-only">login or register dropdown menu </span>
      <div 
      class="btn-guest"
      role="button"
      tabindex="0"
      aria-label="login or register dropdown"
      id="login-register-dropdown"
      >
        <menu-alt-2-icon
          class="md-heroicon mr-1"
        />
        <user-circle-icon
          aria-label="user icon"
          class="md-heroicon"
        />
      </div>
    </template>
    <b-dropdown-item
      aria-describedby="login-register-dropdown"
      :to="{ name: 'login' }"
    >
      <span class="sr-only">Register Page</span>
      Register
    </b-dropdown-item>

    <b-dropdown-item
      aria-describedby="login-register-dropdown"
      :to="{ name: 'login' }"
    >
      <span class="sr-only">Login Page</span>
      Login
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { MenuAlt2Icon, UserCircleIcon } from '@vue-hero-icons/outline'

export default {
  name: 'GuestNavItem',

  components: {
    MenuAlt2Icon,
    UserCircleIcon
  }
}
</script>
