export default {
	data() {
		return {
			formState: 'default',
			serverErrors: {},
			isLoading: false,
		}
	},
	computed: {
		formLock() {
		  return this.formState == 'preload' || this.formState == 'loading' || this.isLoading
		},
	},
	methods: {
		hasServerError(field) {
      return !(this.serverErrors[field] && this.serverErrors[field].length)
    },
	},
}