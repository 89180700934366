export default {
    SET_MONTH(state, month) {
        state.month = month
    },
    SET_DAY(state, day) {
        state.day = day
    },
    SET_WEEK(state, week) {
        state.week = week
    },
    SET_MY_SCHEDULE(state, mySchedule) {
        state.mySchedule = mySchedule
    },
    SET_CURRENT_ROTA(state, rota) {
        state.currentRota = rota
    },
    SET_STAFF_EMPLOYMENT_TYPES(state, staffEmploymentTypes) {
        state.staffEmploymentTypes = staffEmploymentTypes
    },
    SET_CURRENT_DATE(state, currentDate) {
        state.currentDate = currentDate
    },
}
