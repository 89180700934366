export default {
  data () {
    return {
      addresses: [],
      selectedAddressIndex: null,
      noAddressesFound: false,
      disablePostcodeLookup: false,
      showAddressForm: false,
    }
  },
  computed: {
    hasAddresses () {
      if (!Array.isArray(this.addresses) ) {
        return null
      }
      return this.addresses.length > 0
    },
    addressOptions () {
      if (!Array.isArray(this.addresses) ) {
        return
      }

      return this.addresses.map((address, index) => ({
        value: index, text: this.getAddressString(address)
      }))
    }
  },
  methods: {
    getAddressString(address) {
      let addressString = ''

      Object.keys(address).forEach((key, index) => {
        if (address[key].length > 0 && (key == "line_1" || key == "line_2" || key == "line_3")) {
          if (addressString.length > 0) {
            addressString += ', '
          }
          addressString += address[key]
        }
      })

      return addressString
    },
    formatAddress(address) {
      let post_town = address.post_town.charAt(0).toUpperCase() + address.post_town.slice(1).toLowerCase()

      address.post_town = post_town

      return address
    }
  },
}
