import 'vue-svgicon/dist/polyfill'

import Vue from 'vue'
import VueBus from 'vue-bus'
import VueAxios from 'vue-axios'
import Vuelidate from 'vuelidate'
import PortalVue from 'portal-vue'
import VCalendar from 'v-calendar'
import VueSelect from 'vue-select'
import VueRouter from 'vue-router'
import VueDebounce from 'vue-debounce'
import VueBootstrap from 'bootstrap-vue'
import VueProgressBar from 'vue-progressbar'
import VueMultiSelect from 'vue-multiselect'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import VuelidateErrorExtractor, { templates } from 'vuelidate-error-extractor'

import CoreHttpDriver from '@/core/services/drivers/app-http-driver.js'

import AppAvatar from '@/core/components/AppAvatar'
import AppSingleMultiSelect from '@/core/components/AppSingleMultiSelect'
import BProgressButton from '@/core/components/BProgressButton'
import SupportFooter from '@/core/components/SupportFooter'
import ValidatedBFormGroup from '@/core/components/ValidatedBFormGroup'

import ClickOutside from '@/core/directives/click-outside'

import store from '@/core/store'
import router from '@/core/router'

import * as VueSVGIcon from 'vue-svgicon'
import App from './App.vue'
import VueGtag from "vue-gtag"

import './scss/main.scss'

import moment from 'moment'
import ReadMore from 'vue-read-more';
Vue.prototype.moment = moment

// Global Mixin
import GlobalMixin from '@/core/mixins/global-mixin'
Vue.mixin(GlobalMixin)

// stripe
import { StripePlugin } from '@vue-stripe/vue-stripe'

const stripeOptions = {
  pk: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
}

Vue.use(StripePlugin, stripeOptions)

Vue.use(VueBus)
Vue.use(VCalendar)
Vue.use(VueBootstrap)
Vue.use(VuelidateErrorExtractor, {
  template: templates.singleErrorExtractor.bootstrap4,
  messages: {
    required: "The {attribute} field is required",
    required_if_no_branches: "At least one branch must be selected",
    required_if_no_weekdays: "Please select at least one day",
    email: "The {attribute} field must be valid",
    minLength: "The {attribute} field must be {min} characters or more",
    maxLength: "The {attribute} field must be {max} characters or less",
    minValue: "The {attribute} field must be equal or greater than {min}",
    maxValue: "The {attribute} field must be equal or less than {max}",
    integer: "The {attribute} field must be a non-decimal number",
    sameAsPassword: "The passwords do not match",
    contactPhoneRegex: "The {attribute} field must be a valid phone number",
    csNumber: "The {attribute} field starts with CS followed by 10 digits",
  },
  attributes: {
    email: "email address",
    email_address: "email address",
    password_confirmation: "confirm password",
    first_name: "first name",
    last_name: "last name",
    nursery_name: "service name",
    cs_number: "CI registration number ",
    provider_type: "provider type",
    service_types: "service types",
    branch_name: "branch name",
    contact_phone: "contact number",
    contact_email: "contact email",
    address_line_1: "address line 1",
    dob: "date of birth",
    mobile_number: "mobile number",
    telephone_number: "telephone number",
    home_number: "home number",
    local_authority_id: "local authority",
    childcare_provider_id: "childcare provider",
    age_range_id: "age range",
    child_password: "account password",
    dietary_requirements: "dietary requirements",
    medication_requirements: 'medication',
    other_requirements: "other requirements",
    school: "school",
    is_fundable: "funding status",
    is_confirmed: "confirmed funding",
    weekly_hours: "weekly hours at service",
    has_alt_usage: "funding used elsewhere",
    alt_usage: "alternative service",
    is_invoiceable: "paying by invoice",
    from: "start date",
    to: "end date",
    start_date: "start date",
    end_date: "end date",
    capacity: "room capacity",
    role: "account type",
    employment_type: "employment type",
    default_wage: "default wage/salary",
    holiday_days: "annual holiday allowance",
    'branches.staff_category_id': "category",
    staff_category_id: "category",
    age_range: "age range",
    nursery_branch_room_id: "room",
    max_children: "max children",
    flexibility_reserve: "flexibility reserve",
    nursery_branch_working_year_id: "Year",
    phone_number: "home number",
    start_month: "start of year",
    elc_funded: "elc funded",
    provisions: "provisions",
    weekdays_required_care: "days of the week",
    payment_types: 'payment method',
    hourly_rate: 'hourly rate, prices from',
    payroll: "payroll"

  }
})
Vue.use(VueDebounce, {
  listenTo: ['input', 'keyup']
})

Vue.use(Vuelidate)
Vue.use(VueRouter)
Vue.use(VueSVGIcon, {
  classPrefix: 'fcs-svg',
  isOriginalDefault: true,
  tagName: 'svg-icon'
})

Vue.use(VueAxios, CoreHttpDriver)


Vue.use(PortalVue)

Vue.use(VueProgressBar, {
  color: '#78B637',
  failedColor: '#EE6666',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
})
Vue.use(ReadMore);
import OpenIndicator from '@/core/components/VueSelectOpenIndicator'
VueSelect.props.components.default = () => ({ OpenIndicator })

Vue.component('VSelect', VueSelect)
Vue.component('VueMultiSelect', VueMultiSelect)
Vue.component('VueTimepicker', VueTimepicker)

Vue.component('AppAvatar', AppAvatar)
Vue.component('AppSingleMultiSelect', AppSingleMultiSelect)

Vue.component('BProgressButton', BProgressButton)
Vue.component('SupportFooter', SupportFooter)
Vue.component('ValidatedBFormWrapper', templates.FormWrapper)
Vue.component('ValidatedBFormGroup', ValidatedBFormGroup)

Vue.directive('ClickOutside', ClickOutside)

Vue.config.productionTip = false

if (process.env.VUE_APP_ENABLE_ANALYTICS == 'true') {
  Vue.use(VueGtag, {
    config: {
      id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
      appName: process.env.VUE_APP_APP_NAME,
      pageTrackerScreenviewEnabled: true,
    },
  }, router)
}

Vue.filter('concat', function (value, delimiter = ', ') {
  if (!value.length) return ''
  return value.join(delimiter)
})

Vue.filter('plural', function (value, count, plural = null) {
  if (count == 1) return value
  return plural ? plural : value + 's'
})

Vue.filter('priceUK', function (value) {
  return new Intl.NumberFormat(
    'en-GB', // BCP 47 language tag 
    {
      style: 'currency', // we want a currency
      currency: 'GBP' // ISO 4217 currency code
    }
  ).format(value)
})

Vue.filter('snakeToHuman', function (value) {
  let string = value.split('_').join(' ')
  return string[0].toUpperCase() + string.slice(1).toLowerCase()
})

Vue.filter('shorter', function (value, length = 3) {
  return value ? value.substring(0, length) : ''
})

Vue.filter('date', date => date.value ? moment(date.value).format('DD/MM/YYYY') : 'N/A' )

export default new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#fcs-app')
