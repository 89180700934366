<template>
  <!-- Update route name to favourites once it exists  -->
  <b-nav-item
    aria-label="navigate to favourites"
    class="nav-item-icon"
    @click="$emit('clicked')"
  >
    <div class="my-auto" v-if="$route.name == ''">
      <span class="sr-only"> favourites navigation item active icon filled heart </span>
      <heart-icon-solid
        class="md-heroicon text-primary my-auto"
      />
    </div>
    <div class="my-auto" v-else >
      <span class="sr-only"> favourites navigation item </span>
      <heart-icon
        class="md-heroicon text-primary my-auto"
      />
    </div>
  </b-nav-item>
</template>

<script>
import { HeartIcon } from '@vue-hero-icons/outline'
import { HeartIcon as HeartIconSolid } from '@vue-hero-icons/solid'

export default {
  components: {
    HeartIcon,
    HeartIconSolid
  },
}
</script>
