import lazyLoading from '@/core/router/lazy-loading'
import GlobalLayout from '@/core/layouts/global-layout/GlobalLayout'

export const resultsRoutes = [
  {
    path: '/',
    component: GlobalLayout,
    children: [
      {
        name: 'home',
        path: '',
        component: lazyLoading('results', 'Home'),
        meta: {
          title: 'Home'
        },
      },
      {
        name: 'search-results',
        path: 'search',
        component: lazyLoading('results', 'SearchResults'),
        meta: {
          title: 'Search results'
        },
      },
      {
        name: 'home-registered-provider-details',
        path: 'registered-provider-details/:id',
        component: lazyLoading('results', 'ServiceDetails'),
        meta: {
          title: 'Provider details'
        }
      },
      {
        name: 'home-unregistered-provider-details',
        path: 'unregistered-provider-details/:id',
        component: lazyLoading('results', 'ServiceDetails'),
        meta: {
          title: 'Provider details'
        }
      },
      {
        name: 'home-view-marketplace-session',
        path: 'registered-provider-details/:id/sessions/:sessionId',
        component: lazyLoading('results', 'ServiceSession'),
        meta: {
          title: 'Session details'
        }
      }
    ]
  }
]
