import Vue from 'vue'

const ADD_TO_BASKET = (state, basketItem) => {
    state.basket.push(basketItem)
    /* set the current basket uid*/
    if (!state.basketUID) {
        state.basketUID = basketItem.basket_uid
    }
}

const REMOVE_FROM_BASKET = (state, basketItemId) => {
    const index = state.basket.findIndex( i => i.id == basketItemId)
    state.basket.splice(index, 1)
}

const EMPTY_BASKET = (state) => {
    state.basket = []
    state.basketUID = null
}

const UPDATE_BASKET_ITEM = (state, basketItem) => {
    const index = state.basket.findIndex( i => i.id == basketItem.id)
    Vue.set(state.basket, index, basketItem)
}

const UPDATE_CHILD_FOR_BASKET_ITEMS = (state, child) => {
    state.basket
        .forEach( (i, index) => {
            if (i.child.id == child.id) {
                i.child.childcare_voucher_reference_number = child.childcare_voucher_reference_number
                Vue.set(state.basket, index, i)
            }
        })
}

const ADD_WAITING_LIST_ITEM = (state, waitingListItem) => {
    Vue.set(state, 'waitingListItem', waitingListItem)
}

const ASSIGN_CHILD_FOR_WAITING_LIST_ITEM = (state, child) => {
    Vue.set(state.waitingListItem, 'child_name', child.full_name)
    Vue.set(state.waitingListItem, 'child_id', child.id)
}

const RESET_WAITING_LIST_ITEM = state => {
    Vue.set(state, 'waitingListItem', null)
}

const HANDLE_BASKET_ITEM_ERRORS = (state, errors) => {
    Object.keys(errors).forEach( basketItemId => {
        const index = state.basket.findIndex( i => i.id == basketItemId )
        let updatedBasketItem = state.basket[index]
        updatedBasketItem.error = errors[basketItemId][0]
        Vue.set(state.basket, index, updatedBasketItem)
    })
}

export default {
    ADD_TO_BASKET,
    REMOVE_FROM_BASKET,
    EMPTY_BASKET,
    UPDATE_BASKET_ITEM,
    UPDATE_CHILD_FOR_BASKET_ITEMS,
    ADD_WAITING_LIST_ITEM,
    ASSIGN_CHILD_FOR_WAITING_LIST_ITEM,
    RESET_WAITING_LIST_ITEM,
    HANDLE_BASKET_ITEM_ERRORS,
}
