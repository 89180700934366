<template>
  <b-navbar
    :toggleable="false"
    class="global-navbar sticky-top"
  >
    <b-container class="nav-container">
      <b-navbar-brand
        :to="{ name: 'home' }"
      >
        <svg-icon
          aria-label="caerus logo, click to go to home page"
          name="caerus-logo-navbar-light"
          width="122"
          role="button"
          class=" d-none d-sm-flex"
        />
        <span class="sr-only">Navigate to home</span>
      </b-navbar-brand>
      <div class="d-flex ">
        <div class="mobile-nav-burger">
          <mobile-navbar />
        </div>
      </div>
      <b-navbar-nav class="navbar-right flex-gap-3">
        <slot />

        <b-nav-item
          class="d-none d-lg-flex"
          @click="openSupportLink"
        >
          <span>Support</span>
        </b-nav-item>
      
        <b-nav-item
          v-if="!userIsLoggedIn"
          class="d-none d-lg-flex"
          @click="openListServiceLink"
        >
          <span class="sr-only"> Opens new page to list your service</span>
          <span>List your service</span>
        </b-nav-item>

        <slot name="icon-items" />
      
        <!-- Messages -->
        <message-nav-item v-if="allows('messages') && !isServiceOnboarding" />
      
        <!-- Notifications -->
        <notification-nav-item v-if="allows('notifications') && !isServiceOnboarding" />
      
        <!-- Guest/Profile nav-items -->
        <guest-nav-item v-if="!userIsLoggedIn" />
      
        <profile-nav-item v-else-if="!isServiceOnboarding" />
      
        <b-nav-item
          v-else
          @click.prevent="logout"
        >
          <span>Sign out</span>
        </b-nav-item>
      </b-navbar-nav>
    </b-container>
  </b-navbar>
</template>

<script>
import '@/core/icons/caerus-logo-navbar-light'
import { mapGetters } from 'vuex'
import FavouriteNavItem from '@/core/layouts/global-layout/components/nav-items/FavouriteNavItem'
import MessageNavItem from '@/core/layouts/global-layout/components/nav-items/MessageNavItem'
import MobileNavbar from '@/core/layouts/global-layout/components/MobileNavbar'
import NotificationNavItem from '@/core/layouts/global-layout/components/nav-items/NotificationNavItem'
import GuestNavItem from '@/core/layouts/global-layout/components/nav-items/GuestNavItem'
import ProfileNavItem from '@/core/layouts/global-layout/components/nav-items/ProfileNavItem'
import BasketNavItem from '@/modules/basket/components/BasketNavItem'

export default {

  name: 'SharedNavigation',

  components: {
    FavouriteNavItem,
    GuestNavItem,
    MessageNavItem,
    MobileNavbar,
    NotificationNavItem,
    ProfileNavItem,
    BasketNavItem
  },

  computed: {
    ...mapGetters('auth', ['userIsLoggedIn', 'isServiceOnboarding']),
  },

  methods: {

    /**
     * Open List Service link in new tab
     */
    openListServiceLink() {
      window.open('https://www.caerus.scot/list-your-service', "_blank");
    },

    /**
     * Open support link in new tab
     */
    openSupportLink() {
      window.open('https://support.caerus.scot/portal/en-gb/home', "_blank");
    },

    logout () {
      this.$store.dispatch('auth/logout')
      .then(() => {
        this.$router.push({ name: 'home' })
      })
    },
  },
}
</script>
<style>

</style>