<template>
  <div class="d-block">
    <!-- Sidebar -->
    <div>
      <component :is="getUserLinks" />
    </div>


    <AddChildModal id="NewChildModal" />
    <NewServiceModal id="newServiceModal" />
  </div>
</template>

<script>
import {
  OfficeBuildingIcon,
  LogoutIcon,
  ViewGridAddIcon,
  CogIcon,
  RefreshIcon,
  CurrencyPoundIcon,
  CreditCardIcon,
  ThumbUpIcon,
  SwitchHorizontalIcon,
  UserCircleIcon,
  UserGroupIcon,
  UserAddIcon,
} from '@vue-hero-icons/outline'
import { mapGetters } from 'vuex'
import AddChildModal from '@/modules/children/components/AddChildModal'
import NewServiceModal from '@/modules/nursery/components/NewServiceModal'
import NurseryAdmin from '@/modules/settings/components/user-links/NurseryAdminMobile'
import Carer from '@/modules/settings/components/user-links/CarerMobile'
import NurseryStaff from '@/modules/settings/components/user-links/NurseryStaffMobile'

export default {
  name: 'SettingsLayout',
  components: {
    OfficeBuildingIcon,
    ViewGridAddIcon,
    CogIcon,
    LogoutIcon,
    RefreshIcon,
    CurrencyPoundIcon,
    CreditCardIcon,
    ThumbUpIcon,
    SwitchHorizontalIcon,
    UserCircleIcon,
    UserGroupIcon,
    UserAddIcon,
    NewServiceModal,
    AddChildModal,
  },
  

  computed: {
    ...mapGetters('auth', ['role']),

    getUserLinks() {
      const roles = {
        'nursery-super-admin': NurseryAdmin,
        'nursery-admin': NurseryAdmin,
        'nursery-staff': NurseryStaff,
        'keyworker': Carer,
      }

      return roles[this.role]
    }
  },
}
</script>

<style lang="scss">
  .collapse-link.active {
    span {
      color: $primary;
      font-weight: 600;
    }
  }
</style>