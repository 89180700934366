const isBasketEmpty = (state, getters) => getters.basketCount == 0

const basketItems = state => state.basket

const basketCount = state => state.basket.length

const totalToPay = state => state.basket.reduce((carry, i) => carry + parseFloat(i.price) , 0)

const spacesInBasketForInstance = state => instance => {
  if (instance.type == 'block_session') {
    return state.basket
      .filter( i => i.session_id == instance.session_id && i.booking_option_id == instance.booking_option_id )
      .length
  }
  
  return state.basket
    .filter( i => i.instance_id == instance.instance_id && i.booking_option_id == instance.booking_option_id )
    .length
}

const hasRemainingSpacesForInstance = ( state, getters ) => instance => {
  const remainingSpaces = instance.spaces - getters.spacesInBasketForInstance(instance)
  return remainingSpaces > 0 ? true : false
}

const basketHasPaymentMethod = state => state.basket.filter(i => i.payment_type_id != null).length ? true : false

const basketUID = state => state.basketUID

const allBasketItemsHaveChildrenAssigned = state => state.basket.reduce( (carry, i) => {
    if (!i.child_id) {
      carry = false
    }
    return carry
  }, true)

const allBasketItemsHavePaymentAssigned = state => state.basket.reduce( (carry, i) => {
    if (!i.payment_type_id) {
      carry = false
    }
    return carry
  }, true)
  
const waitingListItem = state => state.waitingListItem 

export default {
  isBasketEmpty,
  basketItems,
  basketCount,
  totalToPay,
  spacesInBasketForInstance,
  hasRemainingSpacesForInstance,
  basketHasPaymentMethod,
  basketUID,
  allBasketItemsHaveChildrenAssigned,
  allBasketItemsHavePaymentAssigned,
  waitingListItem,
}
