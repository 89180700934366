<template>

  <div>
    
    <validated-b-form-group 
      name="first_name" 
      label="First name" 
      aria-label="first name"
      class="app-input" 
      :server-errors="serverErrors.first_name"
    >
      <b-form-input
        v-model.trim.lazy="form.first_name"
        :disabled="formLock"
      />
    </validated-b-form-group>

    <validated-b-form-group 
      name="last_name" 
      label="Last name"
      aria-label="last name" 
      class="app-input" 
      :server-errors="serverErrors.last_name"
    >
      <b-form-input
        v-model.trim.lazy="form.last_name"
        :disabled="formLock"
      />
    </validated-b-form-group>

    <validated-b-form-group
      name="dob" 
      aria-label="date of birth"
      label="Date of birth" 
      class="app-input mb-0" 
      :server-errors="serverErrors.dob"
    >
    <label
        for="date of birth"
        class="sr-only"
        label="Date of birth" 
        aria-label="date of birth" >
      date of birth selector
    </label>

      <v-date-picker
        v-model="form.dob"
        popover-visibility="click"
        :max-date="moment.now()"
        format="DD/MM/YYYY"
        class="mr-3"
        :input-props="{
          class: 'form-control datepicker',
          placeholder: 'Choose',
          readonly: true,
          disabled: formLock,
          label:'date of birth',
        }"
      />
    </validated-b-form-group>

  </div>

</template>

<script>
import FieldMixin from '@/core/mixins/field-mixin.js'
export default {

  name: 'ChildBasicForm',

  mixins: [ FieldMixin ],

  components: {

  },

  data() {

    return {}
  },

  computed: {

  },

  methods: {

  },
}
</script>

<style lang="scss" scoped>
  
</style>