<template>
	<b-form novalidate>
	  <validated-b-form-group 
	    name="service_name" 
	    label="Service Name" 
		label-for="service name"
	    class="app-input required" 
	    :server-errors="serverErrors.service_name"
	  >
	    <b-form-input
	      v-model.trim.lazy="form.service_name"
	      :disabled="formLock"
	      required 
		  aria-label="service name"
	    />
	  </validated-b-form-group>

	  <LogoField v-if="isServiceOnboarding" :form="form" :formLock="formLock" :serverErrors="serverErrors" fieldName="service_logo" aria-label="service logo input"/>

	  <validated-b-form-group
	  	v-if="isServiceOnboarding"
	    name="website"
		label-for="website " 
	    label="Website address" 
	    class="app-input optional" 
	    :server-errors="serverErrors.website"
	  >
	    <b-input-group prepend="https://">
	      <b-form-input
	        v-model.trim.lazy="form.website"
	        placeholder="www.example.com"
			aria-label="website"
	        :disabled="formLock"
	        required 
	      />
	    </b-input-group>
	  </validated-b-form-group>

	  <validated-b-form-group
	  	v-if="isServiceOnboarding"
	    name="provider_type" 
	    label="Provider type" 
		label-for="provider type"
	    class="app-input required" 
	    :server-errors="serverErrors.provider_type"
	  >
	    <b-form-select
	      v-model="form.provider_type"
	      value-field="id"
	      text-field="name"
		  aria-label="provider type"
	      :options="providerTypes"
	      :disabled="formLock"
	    >
	    </b-form-select>
	  </validated-b-form-group>

	  <validated-b-form-group 
	    label="Are you registered with Care Inspectorate?" 
	    class="app-input" 
	  >
	    <b-form-radio v-model="form.is_ci_registered" :value="true">Yes</b-form-radio>
	    <b-form-radio v-model="form.is_ci_registered" :value="false">No</b-form-radio>
	  </validated-b-form-group>

	  <validated-b-form-group
	    v-if="form.is_ci_registered"
	    name="cs_number" 
		label-for="Care Inspectorate Registration Number"
	    label="Care Inspectorate Registration Number" 
	    class="app-input required" 
	    :server-errors="serverErrors.cs_number"
	  >
	    <b-form-input
	      v-model.trim.lazy="form.cs_number"
		  aria-label="Care Inspectorate Registration Number"
	      :disabled="formLock"
	      required 
	    />
	  </validated-b-form-group>

	  <ServiceCoverField v-if="isServiceOnboarding" :form="form" :formLock="formLock" :serverErrors="serverErrors" />
	  
	  <AddressLookup :form="form" :formLock="formLock" :serverErrors="serverErrors" />
	  
	  <validated-b-form-group name="contact_phone" label="Contact Number" label-for="contact number" class="app-input required" :server-errors="serverErrors.contact_phone">
	    <b-form-input
	      v-model.trim.lazy="form.contact_phone"
		  aria-label="contact number"
	      :disabled="formLock"
	      required />
	  </validated-b-form-group>
	  <validated-b-form-group name="contact_email" label="Your Service’s Email Address" label-for="Your Services Email Address" class="app-input required" :server-errors="serverErrors.contact_email">
	    <b-form-input
	      type="email"
	      v-model.trim.lazy="form.contact_email"
		  aria-label="Your Services Email Address"
	      :disabled="formLock"
	      required />
	  </validated-b-form-group>
	</b-form>
</template>

<script>
import { mapGetters } from 'vuex'
import FieldMixin from '@/core/mixins/field-mixin.js'
import AddressLookup from '@/core/components/AddressLookup'
import LogoField from '@/core/components/LogoField'
import ServiceCoverField from '@/core/components/ServiceCoverField'

export default {
  name: 'ServiceFormStepOne',

  mixins: [FieldMixin],

  components: {
    AddressLookup,
    LogoField,
    ServiceCoverField,
  },

  computed: {
    
    ...mapGetters('core', [
      'providerTypes',
    ]),

    ...mapGetters('auth', [
      'isServiceOnboarding',
    ]),
  },
}
</script>