import lazyLoading from '@/core/router/lazy-loading'
import GlobalLayout from '@/core/layouts/global-layout/GlobalLayout'

export const basketRoutes = [
  {
    path: '/basket',
    component: GlobalLayout,
    children: [
      {
        name: 'checkout',
        path: 'checkout',
        component: lazyLoading('basket', 'Checkout'),
      },
      {
        name: 'checkout-complete',
        path: 'checkout-complete',
        component: lazyLoading('basket', 'CheckoutComplete'),
      },
      {
        name: 'checkout-waiting-list',
        path: 'waiting-list',
        component: lazyLoading('basket', 'WaitingListCheckout'),
      },
      {
        name: 'checkout-waiting-list-complete',
        path: 'waiting-list-confirm',
        component: lazyLoading('basket', 'WaitingListComplete'),
      },

    ],
  },
]
