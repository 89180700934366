import lazyLoading from '@/core/router/lazy-loading'
import GlobalLayout from '@/core/layouts/global-layout/GlobalLayout'

export const staffRoutes = [
  {
    path: '/staff',
    component: GlobalLayout,
    children: [
      {
        path: '',
        redirect: '/staff/list',
        component: lazyLoading('staff', 'Index'),
        children: [
          {
            name: 'staff-list',
            path: 'list',
            component: lazyLoading('staff', 'tables/StaffListTable'),
            meta: {
              title: 'Staff List'
            }
          },
          {
            name: 'staff-hours',
            path: 'hours',
            component: lazyLoading('staff', 'tables/StaffHoursTable'),
            meta: {
              title: 'Staff Hours'
            }
          },
          {
            name: 'staff-holidays',
            path: 'holidays',
            component: lazyLoading('staff', 'tables/StaffHolidaysTable'),
            meta: {
              title: 'Staff Holidays'
            }
          },
          {
            name: 'staff-absences',
            path: 'absences',
            component: lazyLoading('staff', 'tables/StaffAbsencesTable'),
            meta: {
              title: 'Staff Absences'
            }
          },
        ]
      },
      {
        name: 'staff-invite',
        path: 'staff/invite',
        component: lazyLoading('staff', 'Invite'),
      },
      {
        name: 'staff-profile',
        path: ':profileId',
        redirect: to => ({
          name: 'staff-profile-employee-details',
          params: { profileId: to.params.profileId },
        }),
        component: lazyLoading('staff', 'Profile'),
        meta: {
          title: 'Staff Profile'
        },
        children: [
          {
            name: 'staff-profile-employee-details',
            path: 'employee-details',
            component: lazyLoading('staff', 'profile/EmployeeDetails'),
            props: true,
            meta: {
              title: 'Staff Employee Details'
            }
          },
          {
            name: 'staff-profile-personal-information',
            path: 'personal-information',
            component: lazyLoading('staff', 'profile/PersonalInformation'),
            props: true,
            meta: {
              title: 'Staff Personal Information'
            }
          },
          {
            name: 'staff-profile-work-schedule',
            path: 'work-schedule',
            component: lazyLoading('staff', 'profile/WorkSchedule'),
            props: true,
            meta: {
              title: 'Staff Work Schedule'
            }
          },
          {
            name: 'staff-profile-holidays',
            path: 'holidays',
            component: lazyLoading('staff', 'profile/Holidays'),
            props: true,
            meta: {
              title: 'Staff Holidays & Absences'
            }
          },
          {
            name: 'staff-profile-qualifications',
            path: 'qualifications',
            component: lazyLoading('staff', 'profile/Qualifications'),
            props: true,
            meta: {
              title: 'Staff Qualifications'
            }
          },
          {
            name: 'staff-profile-branches',
            path: 'branches',
            component: lazyLoading('staff', 'profile/Branches'),
            meta: {
              title: 'Staff Branches'
            }
          },
        ]
      },
      {
        name: 'staff-availability',
        path: 'availability',
        component: lazyLoading('staff', 'Availability')
      }
    ]
  }
]
