import teamApiService from '@/modules/team/services/team.api'

export default {
  loadMonth({ commit }, { nurseryId, branchId, month }) {
    return new Promise((resolve, reject) => {
      teamApiService.index(nurseryId, branchId, month)
        .then(({ data }) => {
          commit('SET_MONTH', data)
          resolve()
        })
        .catch( error => {
          reject(error)
        })
    })
  },
  loadDay({ commit }, { nurseryId, branchId, day }) {
    return new Promise((resolve, reject) => {
      teamApiService.getSingle(nurseryId, branchId, day)
        .then(({ data }) => {
          commit('SET_DAY', data),
          resolve()
        })
        .catch( error => {
          reject(error)
        })
    })
  },
  loadWeek({ commit }, { nurseryId, branchId, year, week }) {
    return new Promise((resolve, reject) => {
      teamApiService.getWeek(nurseryId, branchId, year, week)
        .then(({ data }) => {
          commit('SET_WEEK', data)
          resolve(data)
        })
        .catch( error  => {
          reject(error)
        })
    })
  },
  loadTemplatePreview({ commit }, { nurseryId, branchId, rotaId }) {
    return new Promise((resolve, reject) => {
      teamApiService.getPreview(nurseryId, branchId, rotaId)
        .then(({ data }) => {
          commit('SET_WEEK', data)
          resolve(data)
        })
        .catch( error  => {
          reject(error)
        })
    })
  },
  loadStaffEmploymentTypes({ commit }) {
    return new Promise((resolve, reject) => {
      teamApiService.getStaffEmploymentTypes().then(response => {
        commit('SET_STAFF_EMPLOYMENT_TYPES', response.data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  setCurrentRota({ commit }, rota) {
    commit('SET_CURRENT_ROTA', rota)
  },
  setCurrentDate({ commit }, currentDate) {
    commit('SET_CURRENT_DATE', currentDate)
  },
}
