<template>
  <SharedNavigation>
    <b-nav-item
      :to="{ name: 'keyworker-bookings' }"
      class="d-none d-lg-flex"
      :active="isBookingsRoute()"
    >
      <span>Bookings</span>
    </b-nav-item>

    <b-nav-item
      class="d-none d-lg-flex"
      @click="openAdviceLink"
    >
      <span>Advice</span>
    </b-nav-item>

    <template #icon-items>

      <HeaderSearch
        @search="search($event)"
      />

      <!-- Basket -->
      <BasketNavItem />
    
      <!-- Favourites -->
      <FavouriteNavItem @clicked="searchByFavourites" />

    </template>
  </SharedNavigation>
</template>
<script>
import SharedNavigation from '@/core/layouts/global-layout/components/user-navigation/SharedNavigation'
import { isBookingsRoute } from '@/core/router/utils'
import BasketNavItem from '@/modules/basket/components/BasketNavItem'
import FavouriteNavItem from '@/core/layouts/global-layout/components/nav-items/FavouriteNavItem'
import HeaderSearch from '@/core/layouts/global-layout/components//nav-items/HeaderSearch'

export default {
  name: 'CarerNavigation',

  components: {
    SharedNavigation,
    BasketNavItem,
    FavouriteNavItem,
    HeaderSearch,
  },

  methods: {
    /**
     * Open advice link in new tab
     */
    openAdviceLink() {
      window.open('https://support.caerus.scot/portal/en-gb/home', '_blank');
    },

    searchByFavourites() {
      this.$router.push({
  			name: 'search-results',
  			query: { favourites: true }
  		})
    },

    search(event) {
      this.$emit('search', event)
    },

    isBookingsRoute() {
      return isBookingsRoute(this.$route.name)
    },
    
  }
}
</script>