<template>
  <ul class="items fw-300">

    <li class="settings-link d-flex align-items-center collapse-link">
      <UserCircleIcon class="md-heroicon text mr-2"/>
      <router-link :to="{ name: 'user-profile' }">
        Your Profile
      </router-link>
    </li>

    <!-- User role specific links go here -->
    <slot name="main-extra"></slot>

    <li class="settings-link d-flex align-items-center">
      <CogIcon class="md-heroicon text mr-2"/>
      <router-link :to="{ name: 'account-settings' }">
        Settings
      </router-link>
    </li>

    <li class="settings-link d-flex align-items-center" v-if="showSwitchAccount">
      <SwitchHorizontalIcon class="md-heroicon text mr-2"/>
      <a href="#" @click.prevent="switchAccounts">
        Switch to {{ userIsKeyWorker ? 'service' : 'parent/carer' }} account
      </a>
    </li>

    <li class="settings-link d-flex align-items-center">
      <LogoutIcon class="md-heroicon text mr-2"/>
      <a href="#" @click.prevent="logout">
        Logout
      </a>
    </li>

  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  LogoutIcon,
  CogIcon,
  SwitchHorizontalIcon,
  UserCircleIcon
} from '@vue-hero-icons/outline'

export default {
  name: 'SharedLinks',
  
  components: {
    CogIcon,
    LogoutIcon,
    SwitchHorizontalIcon,
    UserCircleIcon,
  },

  computed: {
    ...mapGetters(
      'auth', [
        'userIsKeyWorker',
        'userIsNurseryAdmin',
        'userHasNurseryRole',
        'userHasKeyworkerRole',
        'userIsNurseryStaff',
      ]
    ),

    showSwitchAccount() {
      return (this.userIsKeyWorker && this.userHasNurseryRole)
        || ( (this.userIsNurseryAdmin || this.userIsNurseryStaff) && this.userHasKeyworkerRole)
    },
  },

  methods: {
    switchAccounts() {
      if (this.userIsKeyWorker) {
        this.$store.dispatch('auth/switchToServiceAccount')
        .then( () => this.$bus.emit('app::alert::success', 'Successfully switched to Service account.') )
      } else {
        this.$store.dispatch('auth/switchToCarerAccount')
        .then( () => this.$bus.emit('app::alert::success', 'Successfully switched to Parent/Carer account.') )
      }
    },

    logout () {
      this.$store.dispatch('auth/logout')
      .then(() => {
        this.$router.push({ name: 'home' })
      })
    },
  }
}
</script>

<style>

</style>