<template>
  <div class="d-flex d-lg-none">  
    <Slide
      left
      role="button"
      :close-on-navigation="false"
      class="mobile-navbar-slider d-flex align-items-center mx-3"
    >
      <b-navbar-brand
        :to="{ name: 'home' }"
      >
        <svg-icon 
          aria-label="caerus logo, click to go to home page"
          name="caerus-logo-navbar-light"
          width="122"
          role="button"
          class="pl-2 navbar-brand"
        />
        <span class="sr-only">Navigate to home</span>
      </b-navbar-brand>

      <a
        href="#"
        role="button"
        aria-label="open support link"
        @click="openSupportLink()"
      >
        <span class="sr-only"> Opens new page to support</span>
        Support
      </a>

      <a
        href="#"
        role="button"
        aria-label="sign out"
        @click="logout()"
      >
        <span class="sr-only"> sign out button</span>
        Sign Out
      </a>
      <div class="border-top my-1" />
      <SharedLinksMobile />
      <div class="border-top my-1 d-block" />
      <MobileNavLinks />
      <SettingsLayoutMobile />
    </Slide>
  </div> 
</template>

<script>
import { Slide } from 'vue-burger-menu'
import { mapGetters } from 'vuex'
import MobileNavLinks from '@/core/layouts/global-layout/components/MobileNavLinks'
import SharedLinksMobile from '@/modules/settings/components/user-links/SharedLinksMobile'
import { isBookingsRoute } from '@/core/router/utils'
import SettingsLayoutMobile from '@/modules/settings/pages/SettingsLayoutMobile'



export default {
  name: 'MobileNavBar',
  
  components: {
    Slide,
    MobileNavLinks,
    SharedLinksMobile,
    SettingsLayoutMobile
  },

  data() {
    return {
      notifications: {
        read: [],
        unread: []
      },
    }
  },

  computed: {
    ...mapGetters('auth', [
      'user',
      'userIsLoggedIn'
    ])
  },

  methods: {

    /**
     * Open support link in new tab
     */
    openSupportLink() {
      window.open('https://support.caerus.scot/portal/en-gb/home', "_tab");
    },

    /**
     * Logout user
     */
    logout() {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push({ name: 'home' })
      })
    },
     isBookingsRoute() {
      return isBookingsRoute(this.$route.name)
    },
  }
}
</script>

<style lang="scss">

 @media (max-width: 786px) {
  .navbar-brand{
      margin-left: 100px
  }
    }
.mobile-navbar-slider {

.navbar-brand{
      margin-left: 0px
  }
  .bm-menu{
    background-color:#FFFFFF;
  }
  .bm-burger-button {
    height: 20px;
    width: 25px;
    top: 26px;

    .bm-burger-bars {
      background: $primary;
      border-radius: 5px;

      &.line-style {
        height: 14%;
      }
    }
  }
}
</style>
