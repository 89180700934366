<template>

  <component 
    :is="getNavigationComponent"
    @search="handleSearch($event)"
  />

</template>

<script>
import { mapGetters } from 'vuex'
import GuestNavigation from '@/core/layouts/global-layout/components/user-navigation/GuestNavigation'
import AdminNavigation from '@/core/layouts/global-layout/components/user-navigation/AdminNavigation'
import CarerNavigation from '@/core/layouts/global-layout/components/user-navigation/CarerNavigation'
import StaffNavigation from '@/core/layouts/global-layout/components/user-navigation/StaffNavigation'

export default {

  name: 'Navbar',

  computed: {
    ...mapGetters('auth', ['role']),
    
    getNavigationComponent() {
      if(this.role === null) {
        return GuestNavigation
      }

      const roles = {
        'nursery-super-admin': AdminNavigation,
        'nursery-admin': AdminNavigation,
        'nursery-staff': StaffNavigation,
        'keyworker': CarerNavigation,
        'carer': CarerNavigation,
      }

      return roles[this.role]
    },
  },

  methods: {
    /**
  	 * Redirect to the search results page 
  	 */
  	handleSearch(params) {
      this.$router.push({
        name: 'search-results',
        query: params
      })

    },
  }
}
</script>
