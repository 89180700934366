<template>
	<validated-b-form-group
	  name="service_cover" 
	  label="Cover photo" 
	  class="app-input optional"
		description="Recommended dimensions 1080 x 500px"
    :server-errors="serverErrors['service_cover']"
	>
		<b-form-file
		  v-model="form.service_cover"
		  ref="serviceCoverUpload"
		  style="display: none"
		  @change="onFileChange"
		/>
		<div class="cover-image-container" @click="$refs.serviceCoverUpload.$refs.input.click()" @drop.prevent="onFileDropped" @dragover.prevent>
			<div class="cover-image" v-if="coverUrl">
				<XCircleIcon @click="removeFile"/>
				<img :src="coverUrl"/>
			</div>
			<DragAndDropPlaceholder v-else/>
		</div>
	</validated-b-form-group>
</template>
<script>
import FieldMixin from '@/core/mixins/field-mixin'
import FileValidationMixin from '@/core/mixins/file-validation-mixin'
import DragAndDropPlaceholder from '@/core/components/DragAndDropPlaceholder'
import { XCircleIcon } from '@vue-hero-icons/outline'

export default {
	name: 'ServiceCoverField',

	mixins: [FieldMixin, FileValidationMixin],

	components: {
		DragAndDropPlaceholder,
		XCircleIcon,
	},

	data() {
		return {
      coverUrl: '',
		}
	},

	methods: {
		onFileDropped(e) {
			let droppedFiles = e.dataTransfer.files
      
      if(!droppedFiles) return

      const file = ([...droppedFiles]).pop()

			if (!this.validateFile(file)) {
			  e.preventDefault()
			  return
			}

			this.form.service_cover = file
			this.coverUrl = URL.createObjectURL(file)    	
		},

		onFileChange(e) {
			const file = e.target.files[0]

			if (!this.validateFile(file)) {
			  e.preventDefault()
			  return
			}
			this.coverUrl = URL.createObjectURL(file)
		},

		removeFile(e) {
			e.stopPropagation()
			this.coverUrl = ''
			this.form.service_cover = null
		},
	},
}
</script>

<style lang="scss" scoped>
	.cover-image-container {
		height: 200px;
		.cover-image {
			position: relative;
			img {
				object-fit: cover;
				width: 100%;
				height: 200px;
			}
			svg {
				position: absolute;
				top: 5px;
				right: 5px;
				cursor: pointer;
				color: $error;
			}
		}
	}
	::v-deep .form-text {	
			text-align: right;
	}
	

</style>