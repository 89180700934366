import lazyLoading from '@/core/router/lazy-loading'
import GlobalLayout from '@/core/layouts/global-layout/GlobalLayout'

export const performanceRoutes = [
  {
    path: '/performance',
    component: GlobalLayout,
    children: [
      {
        path: '',
        name: 'performance',
        component: lazyLoading('performance', 'layouts/PerformanceLayout'),
        redirect: { name: 'service-insights-customers' },
        children: [
          {
            name: 'service-performance',
            path: 'service-performance',
            component: lazyLoading('performance', 'layouts/ServicePerformanceLayout'),
            redirect: { name: 'service-performance-service' },

            children: [
              {
                name: 'service-performance-service',
                path: 'service',
                component: lazyLoading('performance', 'service-performance/Service'),
                meta: {
                  title: 'Service Performance'
                },
              },
              {
                name: 'service-insights-customers',
                path: 'service-insights-customers',
                component: lazyLoading('performance', 'service-performance/Customer'),
                meta: {
                  title: 'Customer Insights'
                },
              },
            ]
          },
          {
            name: 'development-diary',
            path: 'development-diary',
            component: lazyLoading('performance', 'layouts/DevelopmentDiaryLayout'),
            redirect: { name: 'development-diary-workers' },

            children: [
              {
                name: 'development-diary-workers',
                path: 'development-diary-workers',
                component: lazyLoading('performance', 'development-diary/Workers'),
                meta: {
                  title: 'Worker Development Diaries'
                },
              },
              {
                name: 'development-diary-parents',
                path: 'development-diary-parents',
                component: lazyLoading('performance', 'development-diary/Parents'),
                meta: {
                  title: 'Parent Development Diaries'
                },
              },
              {
                name: 'development-diary-children',
                path: 'development-diary-children',
                component: lazyLoading('performance', 'development-diary/Children'),
                meta: {
                  title: 'Children Development Diaries'
                },
              }
            ]
          },
        ],
      },
    ]
  }
]