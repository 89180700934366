import lazyLoading from '@/core/router/lazy-loading'
import GlobalLayout from '@/core/layouts/global-layout/GlobalLayout'

export const dashboardRoutes = [
  {
    path: '/dashboard',
    component: GlobalLayout,
    children: [
      {
        name: 'dashboard',
        path: '',
        component: lazyLoading('dashboard', 'Dashboard'),
        meta: {
          title: 'Dashboard'
        },
      },
    ]
  }
]