<template>
  <b-nav-item
    class="nav-item-icon"
    :to="{ name: 'user-profile' }"
    :active="isActive"
  >
    <app-avatar
      :src="user.avatar_url"
      size="40px"
      :class="['my-auto', isActive ? 'active' : '']"
    />
  </b-nav-item>
</template>

<script>
import { mapGetters } from 'vuex'
import { isProfileRoute } from '@/core/router/utils'

export default {
  name: 'ProfileNavItem',

  computed: {
    ...mapGetters('auth', [
      'activeNursery',
      'role',
      'user'
    ]),

    isActive() {
      return isProfileRoute(this.$route.name) ? true : false
    },
  },
}
</script>

<style lang="scss" scoped>
img.active {
  border: 2px solid $primary;
}
</style>
