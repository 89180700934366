import Vue from 'vue'

const SET_CURRENTLY_EDITED_ENTRY = (state, entry = null) => {
  state.currentlyEditedEntry = entry
}
const ADD_FEED_ITEMS = (state, feedItems = []) => {
  feedItems.forEach( item => {
    state.feedItems.push(item)
  })
}
const RESET_FEED = state => {
  state.feedItems = []
  state.meta = null
}

const UPDATE_FEED_ITEM = (state, entry) => {
  const index = state.feedItems.findIndex(item => item.id == entry.id && item.type == entry.type)
  Vue.set(state.feedItems, index, entry)
}

const ADD_NEW_FEED_ITEM = (state, entry) => {
  // check if feed has been initiated
  if (state.meta) {
    state.feedItems.unshift(entry)
    if (state.feedItems.length > state.meta.pagination.per_page) {
      state.feedItems.pop()
    }
  }
}

const REMOVE_FEED_ITEM = (state, entry) => {
  const index = state.feedItems.findIndex(item => item.id == entry.id && item.type == entry.type)
  state.feedItems.splice(index, 1)
}

const SET_METADATA = (state, meta) => {
  state.meta = meta
}
export default {
  SET_CURRENTLY_EDITED_ENTRY,
  ADD_FEED_ITEMS,
  RESET_FEED,
  UPDATE_FEED_ITEM,
  SET_METADATA,
  ADD_NEW_FEED_ITEM,
  REMOVE_FEED_ITEM,
}
