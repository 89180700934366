import lazyLoading from '@/core/router/lazy-loading'
import GlobalLayout from '@/core/layouts/global-layout/GlobalLayout'

export const authRoutes = [
  /* auth */
  {
    name: 'auth',
    path: '/auth',
    component: GlobalLayout,
    redirect: '/auth/login',
    children: [
      {
        name: 'login',
        path: 'login',
        component: lazyLoading('auth', 'Login'),
        meta: {
          title: 'Login'
        },
        props: true
      },
      {
        name: 'confirm-email',
        path: 'confirm-email',
        component: lazyLoading('auth', 'ConfirmEmail'),
        meta: {
          title: 'Confirm email'
        },
        props: true
      },
      {
        name: 'complete-account',
        path: 'complete-account',
        component: lazyLoading('auth', 'CompleteAccount'),
        meta: {
          title: 'Complete account'
        },
        props: true
      },
      {
        name: 'forgot-password',
        path: 'forgot-password',
        component: lazyLoading('auth', 'ForgotPassword'),
        meta: {
          title: 'Forgot Password'
        }
      },
      {
        name: 'reset-password',
        path: 'reset-password',
        component: lazyLoading('auth', 'ResetPassword'),
        meta: {
          title: 'Reset Password'
        }
      },
      {
        name: 'download-app',
        path: 'download-app',
        component: lazyLoading('auth', 'DownloadApp'),
        meta: {
          title: 'Download App'
        }
      },
      {
        name: 'complete-profile',
        path: 'complete-profile',
        component: lazyLoading('auth', 'CompleteProfile'),
        meta: {
          title: 'Complete Profile'
        }
      },
      {
        name: 'confirm-children',
        path: 'confirm-children',
        component: lazyLoading('auth', 'ConfirmChildren'),
        meta: {
          title: 'Confirm Children'
        }
      },
      {
        name: 'service-onboarding-step-one',
        path: 'service-onboarding/step-one',
        component: lazyLoading('auth', 'service-onboarding/StepOne'),
        meta: {
          title: 'Service details',
          onboarding: true,
        },
        props: true,
      },
      {
        name: 'service-onboarding-step-two',
        path: 'service-onboarding/step-two',
        component: lazyLoading('auth', 'service-onboarding/StepTwo'),
        meta: {
          title: 'Additional information',
          onboarding: true,
        },
        props: true,
      },
    ]
  },
  /* legal */
  {
    name: 'legal',
    path: '/legal',
    component: GlobalLayout,
    redirect: '/legal/terms',
    children: [
      {
        name: 'terms',
        path: 'terms',
        component: lazyLoading('auth', 'legal/Terms'),
        meta: {
          title: 'Terms & Conditions'
        }
      },
      {
        name: 'cookie',
        path: 'cookie',
        component: lazyLoading('auth', 'legal/Cookie'),
        meta: {
          title: 'Cookies'
        }
      },
      {
        name: 'privacy',
        path: 'privacy',
        component: lazyLoading('auth', 'legal/Privacy'),
        meta: {
          title: 'Privacy Policy'
        }
      },
    ]
  },
]
