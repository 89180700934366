<template>
  <!-- <SharedNavigation> -->
  <div class="d-block">
    <div class="">
      <li class="settings-link d-flex align-items-center collapse-link py-3 ">
        <a
          href="https://www.caerus.scot/list-your-service"
          target="_blank"
          aria-label="list your service"
        >
          <span class="sr-only"> Opens new page to list your service</span>
          List your service
        </a>
      </li>
      
      <!-- <router-link
        v-if="!userIsLoggedIn"
        :to="{ name: 'login', query: { list_service: true } }"
      >
        List your service
      </router-link> -->

      <li class="settings-link d-flex align-items-center collapse-link py-3 ">
        <a
          href="#"
          role="button"
          aria-label="open advice link"
          @click="openAdviceLink()"
        >
          <span class="sr-only"> Opens new page to advice</span>
          Advice
        </a>
      </li>
      

      <li class="settings-link d-flex align-items-center collapse-link py-3 ">
        <router-link :to="{ name: 'dashboard' }">
          Dashboard
        </router-link>
      </li>

      <li class="settings-link d-flex align-items-center collapse-link py-3">
        <router-link :to="{ name: 'planning' }">
          Planning
        </router-link>
      </li>

      <div class="">
        <SearchFilterSection
          id="profiles"
          class="collapse-link py-3"
        >
          <template #title>
            <span class="sr-only"> Profiles </span>
            <span class="heading-black">Profiles</span>
          </template>
          <template #content>
            <b-col>
              <li class="settings-link d-flex  collapse-link ">
                <router-link :to="{ name: 'children' }">
                  Children
                </router-link>
              </li>

              <li class="settings-link d-flex  collapse-link py-3">
                <router-link :to="{ name: 'children-requests' }">
                  Requests
                </router-link>
              </li>
            </b-col>
          </template>
        </SearchFilterSection>

        <SearchFilterSection
          id="performance"
          class="collapse-link py-3"
        >
          <template #title>
            <span class="sr-only"> Performance </span>
            <span class="heading-black">Performance</span>
          </template>
          <template #content>
            <b-col>
              <li class="settings-link d-flex align-items-center collapse-link py-3">
                <router-link :to="{ name: 'service-insights-customers' }">
                  Insights
                </router-link>
              </li>

              <li class="settings-link d-flex align-items-center collapse-link   py-3">
                <router-link :to="{ name: 'service-performance-service' }">
                  Service Performance
                </router-link>
              </li>
              <li class="settings-link d-flex align-items-center collapse-link  py-3">
                <router-link :to="{ name: 'development-diary' }">
                  Development Diary
                </router-link>
              </li>
            </b-col>
          </template>
        </SearchFilterSection>

        <SearchFilterSection
          id="team"
          class="collapse-link py-3"
        >
          <template #title>
            <span class="sr-only"> Team </span>
            <span class="heading-black">Team</span>
          </template>
          <template #content>
            <b-col>
              <li class="settings-link d-flex align-items-center collapse-link  py-3">
                <router-link :to="{ name: 'staff-list' }">
                  Staff
                </router-link>
              </li>
     
              <li class="settings-link d-flex align-items-center collapse-link  py-3">
                <router-link :to="{ name: 'team' }">
                  Team
                </router-link>
              </li>

              <li class="settings-link d-flex align-items-center collapse-link  py-3">
                <router-link :to="{ name: 'templates' }">
                  Rota Templates
                </router-link>
              </li>
            </b-col>
          </template>
        </SearchFilterSection>
      </div>
    <!--</SharedNavigation> -->
    </div>
  </div>
</template>
<script>
// import SharedNavigation from '@/core/layouts/global-layout/components/user-navigation/SharedNavigation'
import { isBookingsRoute } from '@/core/router/utils'
import SearchFilterSection from '@/modules/results/components/SearchFilterSection'

export default {
  components: {
    // SharedNavigation
    SearchFilterSection,
  },

  methods: {
    /**
     * Open advice link in new tab
     */
    openAdviceLink() {
      window.open('https://support.caerus.scot/portal/en-gb/home', "_tab");
    },

    isBookingsRoute() {
      return isBookingsRoute(this.$route.name)
    },
  }
}

</script>
<style>
.heading-black{
  color: black;
  font-size: 20px;
  font-weight: normal;
}

</style>